/*
  NewPassword.js - Customer Email Verification Page

  Takes a unique link parameter, verifies the validity of it, and produced
  a form for filling out if all looks good.

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { useEffect, useState } from 'react';
import { FormCheck, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// OUR COMPONENTS
import LoaderButton from '../../components/LoaderButton';
import CustomTooltip from '../../components/CustomTooltip';

// NETWORKING
import RecoverPasswordRequest from './RecoverPasswordRequest';
import LoginRequest from '../Login/LoginRequest';

// STYLES
import './Customer.css';

// I18N Strings
import FormattedMessages from './FormattedMessages';

import { validatePassword } from '../../utils/validate';

const loginRequest = new LoginRequest();
const passwordRecoveryRequest = new RecoverPasswordRequest();

const NewPassword = (props) => {
  const [isLoading, setLoading] = useState(true);
  // const [isSubmitting, setSubmitting] = useState(false);
  const [isCompleted, setCompleted] = useState(false);
  const [hasError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [validUrl, setValidUrl] = useState(false);
  const [email, setEmail] = useState('');
  const [validPassword, setValidPassword] = useState(true);
  const [validConfirmPassword, setValidConfirmPassword] = useState(true);

  const [state, setState] = useState({
    newPassword: '',
    password: '',
    showPassword: false,
  });

  let roxImgUrl = '/static/logo.svg';

  const { uniqueUrl } = useParams();

  useEffect(() => {
    async function getData() {
      try {
        if (!uniqueUrl || typeof uniqueUrl === 'undefined') {
          setLoading(false);
          setError(true);
          setErrorMessage('No Unique Link Found in URL');
        } else {
          let validatedUrlResult = await passwordRecoveryRequest.verifyURL(
            uniqueUrl,
            props.errorHandler
          );

          // Validate unique url
          if (validatedUrlResult.valid) {
            setLoading(false);
            setValidUrl(true);
            setEmail(validatedUrlResult.email);
          }
          // Invalid Unique Link provided
          else {
            setLoading(false);
            setError(true);
            setErrorMessage(validatedUrlResult.error || 'Invalid Unique Link');
          }
        }
      } catch (e) {
        // Network issues
        setLoading(false);
        setError(true);
        setErrorMessage('Network Connectivity Issue');
      }
    }

    getData();
  }, [props.errorHandler, uniqueUrl]);

  const login = async (emailLogin, password) => {
    try {
      // Make network Req for Login
      let result = await loginRequest.login(
        emailLogin,
        password,
        props.errorHandler
      );

      // Handle login failure
      if (!result || typeof result === 'undefined') {
        setLoading(false);
        setError(true);
        setErrorMessage('Incorrect Login Details');
        setCompleted(false);
        return;
      }

      await localStorage.setItem('rox_user_token', 'exists');
      await localStorage.setItem('rox_user_email', emailLogin);
      // send authenticated state back to App.js
      props.userHasAuthenticated(true, result);

      // change page
      props.navigate('/home');
    } catch (e) {
      setLoading(false);
      setError(true);
      setErrorMessage('Network Error');
      setCompleted(false);
    }
  };

  /*
      Ensure we have some values before sending form
    */
  const validateForm = () => {
    console.log('state', state);
    if (state.password !== '' && state.newPassword !== '') return true;
    else return false;
  };

  /*
        Handles changes in the form
    */
  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setState({
      ...state,
      [name]: value,
    });
  };
  // useEffect hook for updating validPassword and validConfirmPassword.
  useEffect(() => {
    if (!state.password || state.password === '') {
      setValidPassword(true);
    } else {
      if (validatePassword(state.password)) {
        setValidPassword(true);
      } else {
        setValidPassword(false);
      }
    }
    if (state.newPassword && state.newPassword !== state.password) {
      setValidConfirmPassword(false);
    } else {
      setValidConfirmPassword(true);
    }
  }, [setValidPassword, setValidConfirmPassword, state]);
  /*
      Handles form submission
    */
  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    setError(false);

    try {
      if (state.password && state.password !== '') {
        if (!validatePassword(state.password)) {
          setError(true);
          setLoading(false);
          setErrorMessage(FormattedMessages.errCheckPasswordStrong);
          return;
        } else if (state.password !== state.newPassword) {
          setError(true);
          setLoading(false);
          setErrorMessage(FormattedMessages.errCheckPasswordMatch);
          return;
        }
      }

      let { error } = await passwordRecoveryRequest.setNewPassword(
        email,
        state.password,
        state.newPassword,
        props.errorHandler
      );
      // If no error, go to next state
      if (!error) {
        setError(false);
        setLoading(false);
        setCompleted(true);
        return;
      } else {
        setError(true);
        setLoading(false);
        setErrorMessage(error.error);
        return;
      }
    } catch (e) {
      console.log('Network error:', e);
      setError(true);
      setLoading(false);
      setErrorMessage('Network Error');
    }
  };

  const generateFormError = () => {
    if (!hasError) return null;
    return <div className="text-danger">{errorMessage}</div>;
  };

  /**
   * Goes back to the login page.
   */
  const backToLogin = () => {
    props.navigate('/');
  };

  const generatePage = () => {
    // If the application is currently loading, show Loader
    if (isLoading) {
      return <h5>Loading...</h5>;
    }
    // Registration state is completed
    else if (isCompleted) {
      //todo add page navigation + login
      login(email, state.password);
      return <h5>{FormattedMessages.successfulResetLogin}</h5>;
    }
    // if valid url and no longer loading, show form
    else if (validUrl && !isLoading) {
      return (
        <Form onSubmit={handleSubmit}>
          <div className="roxImgHolder">
            <img className="roximg" src={roxImgUrl} alt="Red Oxygen Logo" />
          </div>
          <br />
          <Form.Group controlId="password" bssize="large">
            <Form.Label>
              <b>{FormattedMessages.newPassword} </b>
              <CustomTooltip
                tooltipText="The passwords must be 8 or more characters long 
              and contains at least one uppercase and one lowercase letter, 
              a number and a symbol."
                margin="5px"
              />
            </Form.Label>
            <OverlayTrigger
              placement="right"
              show={state.password !== '' && !validPassword}
              overlay={
                <Tooltip id="password-tooltip">
                  <p style={{ textAlign: 'left' }}>
                    Password is not strong enough.
                  </p>
                </Tooltip>
              }
            >
              <Form.Control
                type={state.showPassword ? 'text' : 'password'}
                name="password"
                autoComplete="new-password"
                isInvalid={state.password !== '' && !validPassword}
                onChange={handleChange}
              />
            </OverlayTrigger>
          </Form.Group>
          <Form.Group controlId="newPassword" bssize="large">
            <Form.Label>
              <b>{FormattedMessages.confirmNewPassword}</b>{' '}
            </Form.Label>
            <OverlayTrigger
              placement="right"
              show={state.password !== '' && !validConfirmPassword}
              overlay={
                <Tooltip id="password-tooltip">
                  <p style={{ textAlign: 'left' }}>
                    {"Passwords don't match."}
                  </p>
                </Tooltip>
              }
            >
              <Form.Control
                type={state.showPassword ? 'text' : 'password'}
                name="newPassword"
                autoComplete="new-password"
                isInvalid={state.password !== '' && !validConfirmPassword}
                onChange={handleChange}
              />
            </OverlayTrigger>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <FormCheck
                name="showPassword"
                checked={state.showPassword}
                onChange={handleChange}
              />
              <b>{FormattedMessages.showPassword} </b>
            </Form.Label>
          </Form.Group>
          {generateFormError()}
          <br />
          <LoaderButton
            block
            bssize="large"
            disabled={!validateForm()}
            type="submit"
            isLoading={isLoading}
            text={'Set new password'}
            loadingText={'Loading'}
            onClick={(event) => handleSubmit(event)}
          />
        </Form>
      );
    } else if (!validUrl && hasError) {
      return (
        <form>
          <div className="text-danger">{errorMessage}</div>
        </form>
      );
    }
  };

  // render return
  return (
    <div className="Login">
      <div className="customerVerify customerVerify-no-padding">
        <p onClick={backToLogin} className="backLink backLinkForgotPassword">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{FormattedMessages.backToLogin}</span>
        </p>
        <div className="customerVerify-padding">{generatePage()}</div>
      </div>
      <br />
    </div>
  );
};

export default NewPassword;
