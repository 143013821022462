import React, { useEffect, useState } from 'react';
import { Form, Spinner, Table } from 'react-bootstrap';
import AdminRequest from '../../AdminRequest';
import Paginator from '../../../../components/Paginator';

const adminRequest = new AdminRequest();

const Messages = (props) => {
  const { batch, onDetails, date } = props;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [to, setTo] = useState('');
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const refresh = async () => {
    setLoading(true);
    const data = await adminRequest.getTraceMessages(batch.customer_user_id, date, to, page, props.errorHandler);
    setData(data.items);
    setTotal(data.total);
    setLoading(false);
  }

  const messageDetails = (email, message) => {
    const item = {...email, messages: [message]};
    onDetails(item);
  }

  const pageChange = (page) => {
    setPage(page);
  }
  
  let timer;
  const filterUpdate = (value) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      setTo(value);
      setPage(1);
    }, 350);
  }

  useEffect(() => {
    refresh();
  }, [batch, to, page]);

  return (
    <>
      <div className="flex">
        <div>
          <strong>Sent By:</strong> {batch.customer_user_name} of {batch.customer_name}
          <br />
          <strong>Last Updated:</strong> {new Intl.DateTimeFormat('en-us', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
          }).format(new Date(batch.last_updated))}
        </div>
        <div className="flex flexRight">
          <Form.Control 
            type="text" 
            placeholder="To"
            onChange={(e) => { filterUpdate(e.target.value); }} 
          />
        </div>
      </div>

      <Table hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>IP</th>
            <th>From</th>
            <th>To</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="4">
                <div className="loading-spinner">
                  <Spinner animation="border" variant="primary" size="sm"/>
                  &nbsp;Loading...
                </div>
              </td>
            </tr>
          )}
          {data.map((message, k) => 
            <tr key={k} onClick={() => messageDetails(message)} className="clickable">
              <td>
                {new Intl.DateTimeFormat('en-us', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric'
                }).format(new Date(message.last_updated))}
              </td>
              <td>{message.ipAddress}</td>
              <td>{message.origin}</td>
              <td>{message.destination}</td>
              <td>{message.error ? <span className="text-danger">Error</span> : <span className="text-success">Success</span>}</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Paginator
        itemCount={total}
        changeEvent={(page) => { pageChange(page + 1); }}
      />
    </>
  );
}

export default Messages;