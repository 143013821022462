/*
  FormattedMessages.js - FormattedMessages for Device component

  Author: Elle Dunbar (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  loading: <FormattedMessage id="Global.Loading" defaultMessage="Loading..." />,
  back: <FormattedMessage id="Global.Back" defaultMessage="Back" />,
  addNewText: <FormattedMessage id="Global.AddNew" defaultMessage="Add New" />,
  deviceId: (
    <FormattedMessage id="Global.DeviceId" defaultMessage="Device ID" />
  ),
  deviceName: (
    <FormattedMessage id="Global.DeviceName" defaultMessage="Device Name" />
  ),
  priority: <FormattedMessage id="Global.Priority" defaultMessage="Priority" />,
  type: <FormattedMessage id="Global.Type" defaultMessage="Type" />,
  status: <FormattedMessage id="Global.Status" defaultMessage="Status" />,
  replyTn: <FormattedMessage id="Global.ReplyTn" defaultMessage="Reply TN" />,
  errTextDeviceName: (
    <FormattedMessage
      id="Global.ErrTextDeviceName"
      defaultMessage="Requires Device Name."
    />
  ),
  errTextDeviceType: (
    <FormattedMessage
      id="Global.ErrTextDeviceType"
      defaultMessage="Select Device Type."
    />
  ),
  errTextDeviceClass: (
    <FormattedMessage
      id="Global.ErrTextDeviceClass"
      defaultMessage="Select Device Class."
    />
  ),
  errTextDeviceStatus: (
    <FormattedMessage
      id="Global.ErrTextDeviceStatus"
      defaultMessage="Select Device Status."
    />
  ),
  errTextPriority: (
    <FormattedMessage
      id="Global.ErrTextPriority"
      defaultMessage="Set Priority."
    />
  ),
  errTextPriorityNumber: (
    <FormattedMessage
      id="Global.ErrTextPriorityNumber"
      defaultMessage="Priority must be a number."
    />
  ),
  errTextPrimaryDeviceId: (
    <FormattedMessage
      id="Global.ErrTextPrimaryDeviceId"
      defaultMessage="Requires Primary Device Id."
    />
  ),
  errTextCheckReplyTn: (
    <FormattedMessage
      id="Global.ErrTextCheckReplyTn"
      defaultMessage="Incorrect user mobile format, should only contain numbers and '+', eg: +0123456789/0123456789."
    />
  ),
  errTextVirtualDeviceId: (
    <FormattedMessage
      id="Global.ErrTextVirtualDeviceId"
      defaultMessage="Requires Virtual Device Id."
    />
  ),
  clear: (
    <FormattedMessage id="Global.ClearFilters" defaultMessage="Clear Filters" />
  ),
  search: (
    <FormattedMessage id="Global.SearchFilters" defaultMessage="Search" />
  ),
};

export default FormattedMessages;
