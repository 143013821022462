// NPM
import React, { Component, Fragment, createRef } from 'react';
import { Form } from 'react-bootstrap';

// COMPONENTS
import ListComponent from './ListComponent';
import Single from './Single';
import Paginator from '../../../components/Paginator';
import WebSMSTabs from '../index';
import DateRangeSelector from '../../../components/DateRangeSelector';
import InboxRequest from './InboxRequest';

import formattedMessages from './FormattedMessages';

// CONFIG
import config from '../../../Config';
import { getZoneOffsettedDate } from '../../../utils/dates';

const inboxRequest = new InboxRequest();

export default class Inbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 1,
      isSearching: true,
      error: null,
      received: [],
      list: null,
      messageSelected: false,
      message: null,
      fromDate: new Date(),
      toDate: new Date(),
      timeRange: null,
      fromFilter: null,
      nameFilter: null,
      messageFilter: null,
      type: 'TXT',
      pageLength: config.defaultPL,
      currentlySearching: {},
      open: false,
      selectorKeyRefresh: false,
    };
    this.tableRef = createRef();
  }

  toggleMenu = () => this.setState(({ open }) => ({ open: !open }));

  /*
    Convenience function to handle the updating of a form element
  */
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    console.log('handleInputChange event name:', name);
    console.log('handleInputChange event value:', value);

    this.setState({
      [name]: value,
    });
  }

  /*
    Generates the Search Tools for querying data
  */
  generateSearchTools = () => {
    // Have Sent SMS selected, hide search
    if (this.state.messageSelected) return null;

    const { open, currentlySearching, selectorKeyRefresh } = this.state;

    // Destructures which filters the table is currently displaying fields on.
    let { fromFilter, nameFilter, messageFilter } = currentlySearching || {};

    let filterText = [];
    let values = ['From:', 'Name:', 'Message:'];

    // Pushes spans to an array for rendering. Iterates to avoid code duplication.
    [fromFilter, nameFilter, messageFilter].map((filter, i) => {
      if (filter)
        filterText.push(
          <span>
            {filterText.length === 0 ? '' : '; '} <b>{values[i]}</b> {filter}
          </span>
        );
    });

    return (
      <Fragment>
        <DateRangeSelector
          key={selectorKeyRefresh ? 1 : 0} // Changing the key remounts it
          handleDateSearch={(fromDate, toDate, timeRange) =>
            this.handleUserDataSearch(fromDate, toDate, timeRange)
          }
        ></DateRangeSelector>

        <div className="Home">
          <div className="lander">
            <br />
            <span className="showHideLink-left">
              <span className="advanced-filters" onClick={this.toggleMenu}>
                {open ? (
                  <i className="material-icons">keyboard_arrow_down</i>
                ) : (
                  <i className="material-icons">keyboard_arrow_right</i>
                )}
                Advanced Filters
              </span>

              <div className="clear-filters" onClick={this.clearFilters}>
                {formattedMessages.clear}
              </div>
            </span>

            {open && (
              <div>
                <Form id="filter-form">
                  <table className="no-table-row-background">
                    <tbody className="filter-table">
                      <div className="filter">
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              From:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="fromFilter"
                                id="fromFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Number"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              Name:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="nameFilter"
                                id="nameFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Name"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              Message:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="messageFilter"
                                id="messageFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Message"
                              />
                            </div>
                          </td>
                        </div>
                      </div>
                    </tbody>
                  </table>
                </Form>

                <br></br>
              </div>
            )}
            <div className="flex-between">
              {filterText.length > 0 && <div>{filterText}</div>}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  /* redos call to API backend to get refreshed results */
  reloadPage = () => {
    if (this.props.customer && this.props.customer !== null) {
      let { fromDate, toDate, timeRange } = this.state;
      this.handleUserDataSearch(fromDate, toDate, timeRange);
    }
  };

  /*
   Handles searching User Data between two dates
 */
  handleUserDataSearch = async (fromDate, toDate, timeRange) => {
    this.setState({ isSearching: true });
    let searchParams = {};
    searchParams.fromDate = fromDate;
    searchParams.toDate = toDate;
    searchParams.timeRangeStart = fromDate;
    searchParams.timeRangeEnd = toDate;
    searchParams.fromFilter = this.state.fromFilter;
    searchParams.nameFilter = this.state.nameFilter;
    searchParams.messageFilter = this.state.messageFilter;
    searchParams.type = 'TXT';
    searchParams.timeRange = timeRange;

    let inboxData = await inboxRequest.searchInboxItems(
      searchParams,
      this.props.errorHandler
    );

    return this.setState(
      {
        received: inboxData,
        fromDate: searchParams.fromDate,
        toDate: searchParams.toDate,
        timeRange: timeRange,
        currentlySearching: searchParams,
        type: 'TXT',
        isSearching: false,
        pageNumber: 1,
      },
      () => this.changeFunction(this.state.pageNumber - 1)
    );
  };

  changeFunction(page = 0) {
    this.setState({
      activePage: page,
    });
  }

  toggleMessageAction(message) {
    return this.setState({
      messageSelected: true,
      message: message,
    });
  }

  renderInboxTable() {
    let { pageNumber, pageLength, received } = this.state;
    if (
      received &&
      received.length > 0 &&
      typeof received.error === 'undefined'
    ) {
      let displayList = received.slice(
        (pageNumber - 1) * pageLength,
        pageNumber * pageLength - 1 + 1
      );
      let list = displayList.map((messages, index) => {
        let smsText;
        try {
          smsText = decodeURIComponent(messages.reply_text);
        } catch (err) {
          smsText = 'ERROR: MALFORMED TEXT';
        }

        const adjustedDate = getZoneOffsettedDate(messages.created_date);
        return (
          <Fragment key={index}>
            <tr
              key={index}
              id={'inbox_table_' + index}
              className="medium textLeft"
            >
              <td>{adjustedDate}</td>
              <td>{messages.dest_tn}</td>
              <td>{messages.dest_name || messages.dest_tn || ''}</td>
              <td className="medium textLeft" name="MessageText">
                <span
                  className="blue"
                  onClick={() => this.toggleMessageAction(messages)}
                >
                  {smsText}
                </span>
              </td>
            </tr>
          </Fragment>
        );
      });

      return list;
    }

    // catch case return
    return <Fragment>No Results Found.</Fragment>;
  }

  backButtonClick() {
    return this.setState({
      messageSelected: false,
      message: null,
    });
  }

  /*
    Handles the change event when clicking a pagination number
  */
  changeEvent = async (i) => {
    this.setState({
      pageNumber: i + 1,
    });
  };

  /* 
   Changes the amount of entries per pagination page.
  */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState({ pageLength: value, pageNumber: 1 });
    }
  };

  /**
   *  Resets the page's info when the tab is clicked.
   */
  handlePageReset = () => {
    if (this.tableRef && this.tableRef.current) {
      this.tableRef.current.initialiseDates();
    }
    this.setState({
      messageSelected: false,
      list: this.state.list,
      received: this.state.received,
    });
  };

  /* generates the list component for the inbox page */
  generateList = () => {
    let { isSearching, received, pageNumber } = this.state;
    let { dateFormats } = this.props;
    let listLen = received ? received.length : 0;
    console.log('listLen:', listLen);
    if (isSearching) {
      return <div>{formattedMessages.searchingText}...</div>;
    }
    if (listLen === 0) return <div>{this.renderInboxTable()}</div>;
    return (
      <div>
        <ListComponent
          pageNumber={pageNumber}
          dateFormats={dateFormats}
          data={received}
          list={this.renderInboxTable()}
          selectMessage={this.toggleMessageAction.bind(this)}
        />
        <Paginator
          itemCount={listLen}
          changeEvent={this.changeEvent}
          pageLength={this.state.pageLength}
          editFunction={this.changePageLength}
          showTotal={'Total Inbox Messages Found'}
          toolTip={'Maximum of 4,000 records shown'}
          changePageFunction={(ref) => (this.changeFunction = ref)}
        />
      </div>
    );
  };

  /**
   * Handles reply from inbox by redirecting to compose page with state change.
   * @param {Object} parentSms Object depicting the original SMS for the reply
   */
  clickedReply = (parentSms) => {
    const destTn = parentSms.dest_tn;
    const name = parentSms.dest_name || destTn;

    this.props.navigate('/websms/compose', {
      state: {
        selectedOption: [
          {
            value: destTn,
            label: name,
          },
        ],
      },
    });
  };

  clearFilters = () => {
    this.setState({
      fromFilter: null,
      toFilter: null,
      nameFilter: null,
      messageFilter: null,
      statusFilter: null,
    });
    document.getElementById('filter-form').reset();
  };

  render() {
    console.log('Inbox items:', this.state.received);

    const { error, messageSelected, message } = this.state;
    const { mobileMenuOpen, history, toggleMobileMenu } = this.props;

    let InboxTitle = formattedMessages.inboxTitle;

    if (error) {
      return (
        <WebSMSTabs
          tab="inbox"
          mobileMenuOpen={mobileMenuOpen}
          history={history}
          toggleMobileMenu={toggleMobileMenu}
          handlePageReset={this.handlePageReset}
        >
          <div>Error: {error.message}</div>
        </WebSMSTabs>
      );
    } else if (messageSelected) {
      return (
        <WebSMSTabs
          tab="inbox"
          mobileMenuOpen={mobileMenuOpen}
          history={history}
          toggleMobileMenu={toggleMobileMenu}
          handlePageReset={this.handlePageReset}
        >
          <Single
            message={message}
            clickedReply={this.clickedReply}
            errorHandler={this.props.errorHandler}
            backButtonClick={() => {
              this.backButtonClick();
            }}
          />
        </WebSMSTabs>
      );
    }
    return (
      <WebSMSTabs
        tab="inbox"
        mobileMenuOpen={mobileMenuOpen}
        history={history}
        toggleMobileMenu={toggleMobileMenu}
        handlePageReset={this.handlePageReset}
      >
        <div className="Home">
          <div className="lander">
            <h3>{InboxTitle}</h3>
            <div className="inboxWrapper">
              {this.generateSearchTools()}
              {this.generateList()}
            </div>
          </div>
        </div>
      </WebSMSTabs>
    );
  }
}
