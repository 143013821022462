/*
  All.js - Admin ALL Device List

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Table, Button, Form } from 'react-bootstrap';
import Paginator from '../../../../components/Paginator';
import config from '../../../../Config';

// NETWORK
import DeviceRequest from './DeviceRequest';

// COMPONENTS
import formattedMessages from './FormattedMessages';

// INIT
const deviceRequest = new DeviceRequest();

export default class DeviceAllList extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - errorHandler: A function to pass errors to
        - addNewDevice: 
        - showSingleDevice: 
    */

    this.state = {
      devices: [],
      loading: true,
      isCurrentlyDedicated: false,
      isCurrentlyPooled: false,
      paginatedDevices: [],
      pageNumber: 1,
      pageLength: config.defaultPL,
      searchParams: null,
      deviceIdFilter: null,
      deviceNameFilter: null,
      typeFilter: null,
      statusFilter: null,
      replyTnFilter: null,
      currentlySearching: {},
      open: false,
    };
  }

  async componentDidMount() {
    let searchParams = {};

    const devices = await deviceRequest.getAllDevices(
      searchParams,
      this.props.errorHandler
    );
    console.log('Got ALL Devices:', devices);
    return this.setState(
      {
        devices: devices,
        loading: false,
        pageNumber: 1,
      },
      this.setPaginatedResults
    );
  }

  handleCheckbox = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value,
      },
      this.getDevices
    );
  };

  getDevices = async () => {
    let { isCurrentlyDedicated, isCurrentlyPooled } = this.state;
    this.setState({ loading: true });
    let results = await deviceRequest.getAllDevices(
      { isCurrentlyDedicated, isCurrentlyPooled },
      this.props.errorHandler
    );
    console.log('filtered device', results.length, results);
    return this.setState(
      {
        devices: results,
        loading: false,
        pageNumber: 1,
      },
      this.setPaginatedResults
    );
  };

  generateCheckbox = () => {
    return (
      <div className="flex">
        <Form>
          <Form.Check
            inline
            type="checkbox"
            name="isCurrentlyDedicated"
            id="a"
            label="Currently Dedicated"
            onChange={(e) => this.handleCheckbox(e)}
          />
          <Form.Check
            inline
            type="checkbox"
            id="b"
            name="isCurrentlyPooled"
            label="Currently Pooled"
            onChange={(e) => this.handleCheckbox(e)}
          />
        </Form>
        <Button className="searchRange" onClick={this.handleDeviceSearch}>
          {formattedMessages.search}
        </Button>
      </div>
    );
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="DeviceAll" className="lander">
        <p onClick={() => this.props.backButtonClick()} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.back}</span>
        </p>
        <div className="flex">
          <h3>All Devices</h3>
          <Button
            className="flexRight maxHeight btn btn-primary"
            onClick={this.props.addNewDevice}
          >
            {formattedMessages.addNewText}
          </Button>
        </div>
      </div>
    );
  };

  /* Changes the amount of entries per pagination page. */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState(
        { pageLength: value, pageNumber: 1 },
        this.setPaginatedResults
      );
    }
  };

  setPaginatedResults = async () => {
    let { devices, pageNumber, pageLength } = this.state;
    const newList = devices.slice(
      (pageNumber - 1) * pageLength,
      pageNumber * pageLength
    );
    this.setState(
      {
        paginatedDevices: newList,
      },
      () => this.changeFunction(this.state.pageNumber - 1)
    );
  };

  changeFunction(page) {
    this.setState({
      activePage: page,
      pageNumber: page,
    });
  }

  /**
   * Handles the change event when clicking a pagination number
   */
  changeEvent = async (i) => {
    this.setState(
      {
        pageNumber: i + 1,
      },
      this.setPaginatedResults
    );
  };

  generateTable() {
    let htmlRows = [];
    let { paginatedDevices } = this.state;

    if (this.state.loading)
      return (
        <div key="DeviceAllList" className="table">
          {formattedMessages.loading}
        </div>
      );

    if (paginatedDevices.length === 0) return <div>No Devices Retrieved.</div>;

    for (let i = 0; i < paginatedDevices.length; i++) {
      const {
        device_id,
        device_name,
        priority,
        device_type,
        device_status,
        reply_tn,
        device_status_meaning,
        device_type_meaning,
      } = paginatedDevices[i];

      const deviceId = device_id || 'missing';
      htmlRows.push(
        <tr key={`list${device_id}`} id={device_id}>
          <td className="textLeft" width="10%">
            <span className="small">{deviceId}</span>
          </td>
          <td className="textLeft" width="50%">
            <span
              className="blue"
              onClick={() =>
                this.props.showSingleDevice(paginatedDevices[i], true)
              }
            >
              {device_name}
            </span>
          </td>
          <td className="textLeft" width="10%">
            <span>{priority}</span>
          </td>
          <td className="textLeft" width="10%">
            <span>{device_type_meaning || device_type}</span>
          </td>
          <td className="textLeft" width="10%">
            <span>{device_status_meaning || device_status}</span>
          </td>
          <td className="textLeft" width="10%">
            <span>{reply_tn || 'N/A'}</span>
          </td>
        </tr>
      );
    }

    return (
      <div>
        <Table className="table-new">
          <thead>
            <tr>
              <td className="medium tableTop textLeft">
                {formattedMessages.deviceId}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.deviceName}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.priority}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.type}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.status}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.replyTn}
              </td>
            </tr>
          </thead>
          <tbody>{htmlRows}</tbody>
        </Table>
        <Paginator
          itemCount={this.state.devices.length}
          changeEvent={this.changeEvent}
          pageLength={this.state.pageLength}
          editFunction={this.changePageLength}
          changePageFunction={(ref) => (this.changeFunction = ref)}
        />
      </div>
    );
  }

  /*
    Handles search form submission
  */

  handleDeviceSearch = async () => {
    try {
      this.setState(
        {
          devices: [],
          loading: true,
        },
        async () => {
          let searchParams = {
            deviceIdFilter: this.state.deviceIdFilter,
            deviceNameFilter: this.state.deviceNameFilter,
            typeFilter: this.state.typeFilter,
            statusFilter: this.state.statusFilter,
            replyTnFilter: this.state.replyTnFilter,
          };
          let devices = await deviceRequest.getAllDevices(
            searchParams,
            this.props.errorHandler
          );
          console.log('Got POST result:', devices);
          this.setState(
            {
              devices: devices,
              currentlySearching: searchParams,
              loading: false,
              pageNumber: 1,
            },
            this.setPaginatedResults
          );
        }
      );
    } catch (e) {
      console.error(e);
      return this.setState({
        devices: e.message,
        loading: false,
      });
    }
  };

  /*
    Convenience function to handle the updating of a form element
  */
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    console.log('handleInputChange event name:', name);
    console.log('handleInputChange event value:', value);

    this.setState({
      [name]: value,
    });
  }

  toggleMenu = () => this.setState(({ open }) => ({ open: !open }));

  typeDropdown = () => {
    let list = [];
    let deviceType = [
      'Inbound',
      'Outbound',
      'Both',
      'Unavailable',
      'Virtual',
      'Dedicated',
      'Customer Pool',
    ];

    if (!this.state.typeFilter)
      list.push(
        <option disabled selected hidden key={null} value>
          Select Type
        </option>
      );
    for (let i = 0; i < deviceType.length; i++) {
      list.push(
        <option key={deviceType[i]} value={deviceType[i]}>
          {deviceType[i]}
        </option>
      );
    }
    return (
      <Form>
        <Form.Control
          as="select"
          name="typeFilter"
          className="filter-control"
          onChange={(e) => this.handleInputChange(e)}
        >
          {list}
        </Form.Control>
      </Form>
    );
  };

  statusDropdown = () => {
    let list = [];
    let deviceStatus = ['Active', 'Unavailable', 'Expired'];
    if (!this.state.statusFilter)
      list.push(
        <option disabled selected hidden key={null} value>
          Select Status
        </option>
      );
    for (let i = 0; i < deviceStatus.length; i++) {
      list.push(
        <option key={deviceStatus[i]} value={deviceStatus[i]}>
          {deviceStatus[i]}
        </option>
      );
    }

    return (
      <Form>
        <Form.Control
          as="select"
          name="statusFilter"
          className="filter-control"
          onChange={(e) => this.handleInputChange(e)}
        >
          {list}
        </Form.Control>
      </Form>
    );
  };

  clearFilters = () => {
    this.setState({
      deviceIdFilter: null,
      deviceNameFilter: null,
      typeFilter: null,
      statusFilter: null,
      replyTnFilter: null,
    });
    document.getElementById('filter-form').reset();
  };

  /* Generates the Search Tools for querying data */
  generateSearchTools = () => {
    if (this.state.selected) return null;

    const { open, currentlySearching } = this.state;

    // Destructures which filters the table is currently displaying fields on.
    let {
      deviceIdFilter,
      deviceNameFilter,
      typeFilter,
      statusFilter,
      replyTnFilter,
    } = currentlySearching || {};

    let filterText = [];
    let values = [
      'Device Id:',
      'Device Name:',
      'Type:',
      'Status:',
      'Reply Tn:',
    ];

    // Pushes spans to an array for rendering. Iterates to avoid code duplication.
    [
      deviceIdFilter,
      deviceNameFilter,
      typeFilter,
      statusFilter,
      replyTnFilter,
    ].map((filter, i) => {
      if (filter)
        filterText.push(
          <span>
            {filterText.length === 0 ? '' : '; '} <b>{values[i]}</b> {filter}
          </span>
        );
    });

    return (
      <Fragment>
        <div className="Home">
          <div className="lander">
            <br />
            <span className="showHideLink-left">
              <span className="advanced-filters" onClick={this.toggleMenu}>
                {open ? (
                  <i className="material-icons">keyboard_arrow_down</i>
                ) : (
                  <i className="material-icons">keyboard_arrow_right</i>
                )}
                Advanced Filters
              </span>

              <div className="clear-filters" onClick={this.clearFilters}>
                {formattedMessages.clear}
              </div>
            </span>

            {open && (
              <div>
                <Form id="filter-form">
                  <table className="no-table-row-background">
                    <tbody className="filter-table">
                      <thead>
                        <tr>
                          <div className="filter">
                            <div className="filter-item">
                              <td>
                                <Form.Label className="searchRange device-filter">
                                  Device Id:
                                </Form.Label>
                              </td>
                              <td>
                                <div>
                                  <Form.Control
                                    type="text"
                                    name="deviceIdFilter"
                                    id="deviceIdFilter"
                                    className="filter-control"
                                    onChange={(e) => this.handleInputChange(e)}
                                    placeholder="Search Id"
                                  />
                                </div>
                              </td>
                            </div>
                            <div className="filter-item">
                              <td>
                                <Form.Label className="searchRange device-filter">
                                  Device Name:
                                </Form.Label>
                              </td>
                              <td>
                                <div>
                                  <Form.Control
                                    type="text"
                                    name="deviceNameFilter"
                                    id="deviceNameFilter"
                                    className="filter-control"
                                    onChange={(e) => this.handleInputChange(e)}
                                    placeholder="Search Name"
                                  />
                                </div>
                              </td>
                            </div>
                            <div className="filter-item">
                              <td>
                                <Form.Label className="searchRange device-filter">
                                  Type:
                                </Form.Label>
                              </td>
                              <td>
                                <div>{this.typeDropdown()}</div>
                              </td>
                            </div>
                            <div className="filter-item">
                              <td>
                                <Form.Label className="searchRange device-filter">
                                  Status:
                                </Form.Label>
                              </td>
                              <td>
                                <div>{this.statusDropdown()}</div>
                              </td>
                            </div>
                            <div className="filter-item">
                              <td>
                                <Form.Label className="searchRange device-filter">
                                  Reply TN:
                                </Form.Label>
                              </td>
                              <td>
                                <div>
                                  <Form.Control
                                    type="text"
                                    name="replyTnFilter"
                                    id="replyTnFilter"
                                    className="filter-control"
                                    onChange={(e) => this.handleInputChange(e)}
                                    placeholder="Search Number"
                                  />
                                </div>
                              </td>
                            </div>
                          </div>
                        </tr>
                      </thead>
                    </tbody>
                  </table>
                </Form>
              </div>
            )}
            <div className="flex-between">
              {filterText.length > 0 && <div>{filterText}</div>}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    return (
      <div key="DeviceAllList" className="Home">
        {this.generateLander()}
        {this.generateCheckbox()}
        {this.generateSearchTools()}
        {this.generateTable()}
      </div>
    );
  }
}
