// OUR MODULES
import RequestRabbit from '../../RequestRabbit';
import config from '../../../Config';

// INSTANTIATE
const handleRequest = new RequestRabbit();

export default class SearchRequest {
  /*
    Get Data for Date Range
  */
  getCommissionReportData = async (monthValue, yearValue, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/billing/report/new-customer?monthNum=${monthValue}&year=${yearValue}`;
      const options = { method: 'GET' };

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return;
    }
  };
}
