/*
  AdminRequest.js - AdminRequest Networking File

  Contains networking functionality for Administration Components

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../RequestRabbit';

// CONFIGURATION
import config from '../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class AdminRequest {
  updateProvider = async (providerId, updateData, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/provider/${providerId}`;
      const body = JSON.stringify(updateData);
      console.log('Have update data:', updateData);
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Returns extra content for creating or editing a Provider
  */
  getProviderExtra = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/provider/extra`;
      const options = {
        method: 'GET',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  getAllCustomers = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/customer/`;
      const options = {
        method: 'GET',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Given a CustomerId, attempts to fetch a Customer
  */
  getSingleCustomer = async (customerId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/customer/${customerId}`;
      const options = {
        method: 'GET',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  updateCustomer = async (customerId, updateData, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/customer/${customerId}`;
      const body = JSON.stringify(updateData);
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  getTwilioNumbers = async (
    selectedCountry,
    region,
    postalCode,
    areaCode,
    contains,
    errorHandler
  ) => {
    try {
      if (!selectedCountry) errorHandler('Selected country required.');
      // TODO: Route has been moved to users/number
      let url = `${config.baseServerURL}/twilio/number?countryCode=${selectedCountry}`;
      if (region) url = url + `&regionCode=${region}`;
      if (postalCode) url = url + `&postalCode=${postalCode}`;
      if (areaCode) url = url + `&areaCode=${areaCode}`;
      if (contains) url = url + `&contains=${contains}`;

      const options = {
        method: 'GET',
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Saves the SecurePay or Payeezy token/credit card
  */
  saveCreditCardToken = async (paymentProvider, ccToken, errorHandler) => {
    try {
      let url = `${config.baseServerURL}/payments/card`;
      let body = {
        paymentProvider: paymentProvider,
      };
      if (paymentProvider.toLowerCase() === 'securepay') body.token = ccToken;
      else if (paymentProvider.toLowerCase() === 'payeezy')
        body.cc = /*JSON.stringify(*/ ccToken;
      body = JSON.stringify(body);
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Gets a single Twilio number by device_id and sid
  */
  getSingleTwilioNumber = async (deviceId, sid, errorHandler) => {
    let url = `${config.baseServerURL}/admin/device/${deviceId}?sid=${sid}&action=twilio`;
    console.log('Get Single Twilio Number URL is:', url);

    const options = {
      method: 'GET',
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJson = await response.json();

    return responseJson;
  };

  /*
    Creates or updates an acting_provider_id cookie which determines the Provider to make calls for.
  */
  updateActingProvider = async (providerId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/session/acting-provider`;
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      let body = {
        actingProviderId: providerId,
      };
      const options = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: headers,
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Creates or updates an acting_customer_id cookie which determines the Customer to make calls for.
  */
  updateActingCustomer = async (customerId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/session/acting-customer`;
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      let body = {
        actingCustomerId: customerId,
      };
      const options = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: headers,
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  getCustomerComments = async (customerId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/customer/${customerId}/comments`;
      const options = {
        method: 'GET',
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  editCustomerComments = async (
    customerId,
    commentId,
    comment,
    errorHandler
  ) => {
    try {
      const url = `${config.baseServerURL}/admin/customer/${customerId}/comments/${commentId}`;
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      let body = {
        customer_comments: comment,
      };
      const options = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: headers,
      };
      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  deleteCustomerComments = async (customerId, commentId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/customer/${customerId}/comments/${commentId}`;
      const options = {
        method: 'DELETE',
      };

      // Request
      await requestRabbit.request(url, options, errorHandler);
    } catch (e) {
      errorHandler(e.message);
    }
  };

  addCustomerComments = async (customerId, comment, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/customer/${customerId}/comments`;
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      let body = {
        customer_comments: comment,
      };
      const options = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: headers,
      };
      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Adds a new Customer Attachment for a Customer
  */
  addCustomerAttachments = async (customerId, type, file, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/customer/${customerId}/attachments`;

      // Set body of multi-part form
      let formData = new FormData();
      formData.set('upload_type', type);
      formData.set('file', file);

      // Request
      const options = {
        method: 'POST',
        body: formData,
      };
      let response = await requestRabbit.request(url, options, errorHandler);

      // Response
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
      }

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  getCustomerAttachments = async (customerId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/customer/${customerId}/attachments`;
      const options = {
        method: 'GET',
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      console.log('customer attachments endpoint:', responseJSON);
      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  deleteCustomerAttachment = async (customerId, attachmentId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/customer/${customerId}/attachments/${attachmentId}`;
      const options = {
        method: 'DELETE',
      };
      await requestRabbit.request(url, options, errorHandler);
    } catch (e) {
      errorHandler(e.message);
    }
  };

  downloadCustomerAttachment = async (
    customerId,
    attachmentId,
    attachmentName,
    errorHandler
  ) => {
    try {
      const url = `${config.baseServerURL}/admin/customer/${customerId}/attachments/${attachmentId}`;
      const options = {
        method: 'GET',
      };
      let response = await requestRabbit.request(url, options, errorHandler);

      if (response.status > 399 && response.status < 600) {
        let error = await response.json();
        if (error.err) errorHandler(error.err);
        else errorHandler('Got file download error');
        return;
      }

      const blob = await response.blob();

      // if MS Edge
      if (navigator.userAgent.includes('Edge')) {
        navigator.msSaveBlob(blob, attachmentName);
      }

      let downloadUrl = URL.createObjectURL(blob);
      // Set up <a> element to allow file download
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = downloadUrl;
      tempLink.setAttribute('download', attachmentName);

      // Add <a> element to body briefly to allow download
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);

      // Revoke URL once used
      URL.revokeObjectURL(downloadUrl);
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Get Card Details
  */
  getCardDetails = async (customerId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/billing/card/get/admin`;
      let data = { customerId: customerId };
      const body = JSON.stringify(data);
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };

      // Make Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      return errorHandler(e);
    }
  };

  /**
   * Get Primary Queue Stats
   * @param {*} errorHandler errorhandler
   * @returns primary queue stats
   */
  getPrimaryQueueStats = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/systemmonitor/stats/primaryQueue`;
      const options = {
        method: 'GET',
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      return errorHandler(e);
    }
  };

  /**
   * Get Total Stats Per mux id
   * @param {*} errorHandler errorhandler
   * @returns responseJson total stats per mux id
   */
  getTotalStatsPerMuxId = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/systemmonitor/stats/total`;
      const options = {
        method: 'GET',
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      return errorHandler(e);
    }
  };

  /**
   * Get Total Twilio Stats Per mux id
   * @param {*} errorHandler errorhandler
   * @returns responseJson total Twilio stats per mux id
   */
  getTotalTwilioStatsPerMuxId = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/systemmonitor/twiliostats/total`;
      const options = {
        method: 'GET',
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      return errorHandler(e);
    }
  };

  /**
   * Gets stats for the specific muxId and whether it is twilio or not..
   * @param {string} muxId mux_0
   * @param {boolean} isTwilio true or false
   * @param {function} errorHandler errorhandler
   * @returns responseJSON
   */
  getMuxIdDrilldown = async (muxId, isTwilio, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/systemmonitor/stats/${muxId}`;
      const headers = { 'Content-Type': 'application/json' };
      const body = {
        isTwilio: isTwilio,
      };
      const options = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: headers,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      // Response
      let responseJSON = await response.json();
      return responseJSON;
    } catch (e) {
      return errorHandler(e);
    }
  };

  /**
   * Gets daemon_config for the specific routeName.
   * @param {string} routeName route name
   * @param {function} errorHandler errorhandler
   * @returns responseJson
   */
  getDaemonConfigByRouteName = async (routeName, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/systemmonitor/daemonconfig/${routeName}`;
      const options = {
        method: 'GET',
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      return errorHandler(e);
    }
  };
  /**
   * Gets recent messages for the specific route Id.
   * @param {string} routeId route name
   * @param {function} errorHandler errorhandler
   * @returns responseJson
   */
  getRecentMessagesByRouteId = async (routeId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/systemmonitor/recentmessages/${routeId}`;
      const options = {
        method: 'GET',
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      return errorHandler(e);
    }
  };

  /**
   * Sends a request to the api-backend to shutdown the mux
   * @param {function} errorHandler errorhandler
   * @returns responseJson
   */
  sendMuxShutdown = async (errorHandler) => {
    // send mux shutdown call to api backend
    try {
      const url = `${config.baseServerURL}/admin/systemmonitor/muxshutdown`;
      const options = {
        method: 'GET',
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      return errorHandler(e);
    }
  };

  /**
   * Sends a request to the api-backend to shutdown the api
   * @param {function} errorHandler errorhandler
   * @returns responseJson
   */
  sendApiShutdown = async (errorHandler) => {
    // send mux shutdown call to api backend
    try {
      const url = `${config.baseServerURL}/admin/systemmonitor/apishutdown`;
      const options = {
        method: 'GET',
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      return errorHandler(e);
    }
  };

  getTrace = async (filter, errorHandler) => {
    try {
      const params = new URLSearchParams(filter);
      const url = `${config.baseServerURL}/admin/systemmonitor/trace?${params.toString()}`;
      const options = {
        method: 'GET',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        if (errorHandler) {
          errorHandler(responseJSON.error);
        } else  {
          console.error(responseJSON.error);
        }

        return [];
      }
      return responseJSON;
    } catch (e) {
      if (errorHandler) {
        return errorHandler(e);
      }

      console.error(e);
      return [];
    }
  };

  getTraceMessages = async (customerId, date, to, page, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/systemmonitor/trace/${customerId}?date=${date}&to=${to}&page=${page}`;
      const options = {
        method: 'GET',
      };

      const response = await requestRabbit.request(url, options, errorHandler);
      const responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        if (errorHandler) {
          errorHandler(responseJSON.error);
        } else {
          console.error(responseJSON.error);
        }

        return [];
      }
      return responseJSON;
    } catch (e) {
      if (errorHandler) {
        return errorHandler(e);
      }

      console.error(e);
      return [];
    }
  }

  getTraceEvents = async (smsId, emailId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/systemmonitor/trace/${smsId}/events?email_id=${emailId}`;
      const options = {
        method: 'GET',
      };
      const response = await requestRabbit.request(url, options, errorHandler);
      const responseJSON = await response.json();
      return responseJSON;
    } catch (e) {
      if (errorHandler) {
        return errorHandler(e);
      }

      console.error(e);
      return [];
    }
  }

  getAuthGroups = async (date, page, pageSize, errorHandler) => {
    try {
      const params = new URLSearchParams({date, page, pageSize});
      const url = `${config.baseServerURL}/admin/systemmonitor/trace/auth?${params.toString()}`;
      const options = {
        method: 'GET',
      };
      const response = await requestRabbit.request(url, options, errorHandler);
      const responseJSON = await response.json();
      return responseJSON;
    } catch (e) {
      if (errorHandler) {
        return errorHandler(e);
      }

      console.error(e);
      return [];
    }
  }

  getAuthLogs = async (email, date, page, pageSize, errorHandler) => {
    try {
      const params = new URLSearchParams({email, date, page, pageSize});
      const url = `${config.baseServerURL}/admin/systemmonitor/trace/auth?${params.toString()}`;
      const options = {
        method: 'GET',
      };
      const response = await requestRabbit.request(url, options, errorHandler);
      const responseJSON = await response.json();
      return responseJSON;
    } catch (e) {
      if (errorHandler) {
        return errorHandler(e);
      }

      console.error(e);
      return [];
    }
  }
}
