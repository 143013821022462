import React, { useEffect, useState } from "react";
import moment from 'moment';
import { Modal } from "react-bootstrap";
import momentLocalizer from 'react-widgets-moment';

import Batches from "./Batches";
import Details from "./Details";
import Messages from "./Messages";
import AdminRequest from '../../AdminRequest';
import Config from "../../../../Config";

const adminRequest = new AdminRequest();

moment.locale('en-us');
momentLocalizer();

const MessageLog = (props) => {  
  const [batch, setBatch] = useState({messages: []});
  const [showMessages, setShowMessages] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [detailsItem, setDetailsItem] = useState(null);
  const [filter, setFilter] = useState({
    onDate: new Date(),
    customer: '',
    user: '',
    withErrors: false,
    page: 1,
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);

  const batchSelect = (item) => {
    setBatch(item);
    setShowMessages(true);
  }

  const toggleDetails = (item) => {
    setDetailsItem(item);
    setShowDetails(true);
  }

  const refresh = async () => {
    setLoading(true);
    const data = await adminRequest.getTrace({
      date: filter.onDate.toISOString(),
      customer: filter.customer,
      user: filter.user,
      withErrors: filter.withErrors,
      page: filter.page,
      pageSize: Config.defaultPL,
    }, props.errorHandler);
    setData(data.items);
    setCount(data.total);
    setLoading(false);
  }

  useEffect(() => {
    refresh();
  }, [filter]);

  useEffect(() => {
    const modal = document.getElementById('messages-modal');
    console.log('modal', modal, showDetails);

    if (!modal) return;
    if (showDetails) {
      modal.parentElement.style.zIndex = 100;
    } else {
      modal.parentElement.style.zIndex = '';
    }
  }, [showDetails]);

  return (
    <>
      <Batches
        count={count}
        data={data}
        loading={loading}
        filter={filter}
        onBatchSelect={batchSelect}
        onFilterChange={setFilter}
        onRefresh={refresh}
      />
      
      <Modal show={showMessages} backdrop="static" onHide={() => setShowMessages(false)} size="lg" id="messages-modal">
        <Modal.Header closeButton>
          <Modal.Title>Messages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Messages
            batch={batch}
            date={filter.onDate.toISOString()}
            onDetails={toggleDetails}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showDetails} backdrop="static" onHide={() => setShowDetails(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Details item={detailsItem} errorHandler={props.errorHandler} />
        </Modal.Body>
      </Modal>
    </>
  )
};

export default MessageLog;
