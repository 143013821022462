/*
  Single.js - Admin Tariff Plan Single View

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';

// COMPONENT
import TariffLinesList from './Line/List';
import TariffLinesSingle from './Line/Single';
import ErrorText from '../../../../components/ErrorText';

// NETWORK
import TariffRequest from '../TariffRequest';
import formattedMessages from '../../Provider/FormattedMessages';

// INSTANTIATION
const tariffRequest = new TariffRequest();

export default class TariffPlanSingleView extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - tariffScheme: Tariff Scheme object
        - tariffPlan: Tariff Plan object
        - backButtonClick: Function to handle Back Button click
        - isNew: Whether or not it's handling a new object,
        - errorHandler: A function to pass errors to
    */

    console.log('Have Tariff Plan props:', this.props);

    this.state = {
      isEditing: false,
      edits: {},
      currencies: [],
      tariff_lines: [],
      tariff_plans: [],
      tariff_schemes: [],
      statuses: [],
      types: [],
      showSingleTariffLine: null,
      addNewTariffLine: false,
      hasError: false,
      errorMessage: '',
      isLoading: false,
      isSaving: false,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    let { tariffScheme, tariffPlan, errorHandler } = this.props;
    let extras = await tariffRequest.getPlanExtras();
    let tariffSchemes = await tariffRequest.getTariffSchemes();
    let tariffLines = [];
    if (tariffPlan) {
      tariffLines = await tariffRequest.getTariffLines(
        tariffScheme.tariff_scheme_id,
        tariffPlan.tariff_plan_id,
        errorHandler
      );
    }
    console.log('extras.currencies:', extras.currencies);
    return this.setState({
      tariff_lines: tariffLines,
      tariff_schemes: tariffSchemes,
      currencies: extras.currencies,
      tariff_plans: extras.tariff_plans,
      statuses: extras.statuses,
      types: extras.types,
      isLoading: false,
    });
  }

  backButtonClick = async (refreshFlag) => {
    let defaultState = {
      addNewTariffLine: false,
      showSingleTariffLine: null,
    };
    if (refreshFlag) {
      this.setState({ isLoading: true });
      let { tariffScheme, tariffPlan, errorHandler } = this.props;
      let tariffLines = await tariffRequest.getTariffLines(
        tariffScheme.tariff_scheme_id,
        tariffPlan.tariff_plan_id,
        errorHandler
      );
      defaultState.tariff_lines = tariffLines;
      defaultState.isLoading = false;
    }
    return this.setState(defaultState);
  };

  showSingleTariffLine = (line) => {
    return this.setState({
      showSingleTariffLine: line,
    });
  };

  addNewTariffLine = () => {
    return this.setState({
      addNewTariffLine: true,
    });
  };

  /*
    Handles Edit Mode on/off
  */
  toggleEdit = () => {
    return this.setState({
      isEditing: !this.state.isEditing,
      edits: {},
      hasError: false,
      errorMessage: '',
    });
  };

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let edits = this.state.edits;
    edits[name] = value;

    console.log('Edits is:', edits);

    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles Edit form submission
  */
  handleSubmit = async (e) => {
    try {
      console.log('Handle submit');
      e.preventDefault();
      let data = this.state.edits;
      this.setState({
        hasError: false,
        errorMessage: '',
      });

      // Have new Object, create
      if (this.props.isNew) {
        if (!data.copy_tariff_plan)
          return this.setState({
            hasError: true,
            errorMessage: 'Select a Plan to copy from.',
          });
        if (!data.tariff_plan_name)
          return this.setState({
            hasError: true,
            errorMessage: 'Set Plan name.',
          });
        if (!data.commitment_recur || isNaN(data.commitment_recur))
          return this.setState({
            hasError: true,
            errorMessage: 'Set Plan Monthly Licencing Commitment.',
          });
        if (!data.commitment_usage || isNaN(data.commitment_usage))
          return this.setState({
            hasError: true,
            errorMessage: 'Set Plan Monthly Usage Commitment.',
          });
        if (!data.included_messages || isNaN(data.included_messages))
          return this.setState({
            hasError: true,
            errorMessage: 'Set Plan Included Messages.',
          });
        if (!data.included_cf_minutes || isNaN(data.included_cf_minutes))
          return this.setState({
            hasError: true,
            errorMessage: 'Set Plan Included CF Minutes.',
          });
        if (!data.plan_currency)
          return this.setState({
            hasError: true,
            errorMessage: 'Select Plan currency.',
          });
        if (!data.plan_status)
          return this.setState({
            hasError: true,
            errorMessage: 'Select Plan status.',
          });
        if (!data.plan_type)
          return this.setState({
            hasError: true,
            errorMessage: 'Select Plan type.',
          });
        this.setState({ isSaving: true });
        await tariffRequest.createNewTariffPlan(
          this.props.tariffScheme.tariff_scheme_id,
          data,
          this.props.errorHandler
        );
        this.setState({ isSaving: false });
      }
      // Editing object, update
      else {
        if ('tariff_plan_name' in data && !data.tariff_plan_name)
          return this.setState({
            hasError: true,
            errorMessage: 'Set Plan name.',
          });
        if (
          'commitment_recur' in data &&
          (!data.commitment_recur || isNaN(data.commitment_recur))
        )
          return this.setState({
            hasError: true,
            errorMessage: 'Set Plan Monthly Licencing Commitment.',
          });
        if (
          'commitment_usage' in data &&
          (!data.commitment_usage || isNaN(data.commitment_usage))
        )
          return this.setState({
            hasError: true,
            errorMessage: 'Set Plan Monthly Usage Commitment.',
          });
        if (
          'included_messages' in data &&
          (!data.included_messages || isNaN(data.included_messages))
        )
          return this.setState({
            hasError: true,
            errorMessage: 'Set Plan Included Messages.',
          });
        if (
          'included_cf_minutes' in data &&
          (!data.included_cf_minutes || isNaN(data.included_cf_minutes))
        )
          return this.setState({
            hasError: true,
            errorMessage: 'Set Plan Included CF Minutes.',
          });
        await tariffRequest.updateTariffPlan(
          this.props.tariffScheme.tariff_scheme_id,
          this.props.tariffPlan.tariff_plan_id,
          data,
          this.props.errorHandler
        );
      }
      this.props.backButtonClick(true);
    } catch (e) {
      console.log('Have err:', e);
      // Handle error
    }
  };

  /*
    Handles Delete of Country Prefix Map
  */
  handleDelete = async (e) => {
    await tariffRequest.deleteTariffPlan(
      this.props.tariffScheme.tariff_scheme_id,
      this.props.tariffPlan.tariff_plan_id,
      this.props.errorHandler
    );
    this.props.backButtonClick(true);
  };

  /*
      Handles cancelling a tariff plan
    */
  cancelTariffPlan = () => {
    this.setState(
      {
        isSaving: true,
      },
      async () => {
        await tariffRequest.cancelTariffPlanId(
          this.props.tariffScheme.tariff_scheme_id,
          this.props.tariffPlan.tariff_plan_id,
          this.props.errorHandler
        );

        this.props.backButtonClick(true);
      }
    );
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    // If Showing Tariff Line content, don't generate a Lander
    if (this.state.addNewTariffLine || this.state.showSingleTariffLine)
      return null;
    return (
      <div key="TariffSchemeShow" className="lander">
        <p onClick={() => this.props.backButtonClick()} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backText}</span>
        </p>
        <h3>Tariff Plan Information</h3>
      </div>
    );
  };

  /*
    Convenience function to generate Form Groups
  */
  generateFormGroup = (label, displayValue, controlId) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <p>{displayValue}</p>
        </Col>
      </Form.Group>
    );
  };

  generateEditButton = () => {
    let { isEditing } = this.state;
    let buttonText = isEditing ? 'Cancel' : 'Edit';

    if (!this.props.isNew || typeof this.props.isNew === 'undefined') {
      return (
        <Button className="maxHeight" onClick={this.toggleEdit}>
          {buttonText}
        </Button>
      );
    }
    return null;
  };

  generateSaveButton = () => {
    if (this.state.isSaving) return <p>Saving...</p>;
    return (
      <Button className="maxHeight" variant="primary" type="submit">
        Save
      </Button>
    );
  };

  generateErrorText = () => {
    return (
      <ErrorText
        hasError={this.state.hasError}
        errorText={this.state.errorMessage}
      />
    );
  };

  generatePlanSelects = () => {
    let { statuses, types, currencies, tariff_plans, tariff_schemes } =
      this.state;
    let statusList = [];
    for (let i = 0; i < statuses.length; i++) {
      statusList.push(
        <option key={statuses[i].code} value={statuses[i].code}>
          {statuses[i].meaning}
        </option>
      );
    }
    let typesList = [];
    for (let i = 0; i < types.length; i++) {
      typesList.push(
        <option key={types[i].code} value={types[i].code}>
          {types[i].meaning}
        </option>
      );
    }
    let currenciesList = [];
    for (let i = 0; i < currencies.length; i++) {
      currenciesList.push(
        <option key={currencies[i].code} value={currencies[i].code}>
          {currencies[i].meaning}
        </option>
      );
    }
    let tariffPlanLists = [];
    // Push custom option
    tariffPlanLists.push(
      <option key={'N/A'} value={''}>
        {'N/A'}
      </option>
    );
    for (let i = 0; i < tariff_plans.length; i++) {
      tariffPlanLists.push(
        <option
          key={tariff_plans[i].tariff_plan_id}
          value={tariff_plans[i].tariff_plan_id}
        >
          {tariff_plans[i].select_name}
        </option>
      );
    }
    let tariffSchemeLists = [];
    tariffSchemeLists.push(
      <option key={'N/A'} value={'N/A'}>
        {'N/A'}
      </option>
    );
    for (let i = 0; i < tariff_schemes.length; i++) {
      tariffSchemeLists.push(
        <option
          key={tariff_schemes[i].tariff_scheme_id}
          value={tariff_schemes[i].tariff_scheme_id}
        >
          {tariff_schemes[i].tariff_scheme_name}
        </option>
      );
    }

    return [
      <select
        name={'plan_currency'}
        defaultValue={
          !this.props.isNew
            ? this.props.tariffPlan.plan_currency
            : currencies[0]
        }
        onChange={(e) => {
          if (this.props.onChange) this.props.onChange(e);
        }}
      >
        {currenciesList}
      </select>,
      <select
        name={'plan_status'}
        defaultValue={
          !this.props.isNew ? this.props.tariffPlan.plan_status : statuses[0]
        }
        onChange={(e) => {
          if (this.props.onChange) this.props.onChange(e);
        }}
      >
        {statusList}
      </select>,
      <select
        name={'plan_type'}
        defaultValue={
          !this.props.isNew ? this.props.tariffPlan.plan_type : types[0]
        }
        onChange={(e) => {
          if (this.props.onChange) this.props.onChange(e);
        }}
      >
        {typesList}
      </select>,
      <select
        name={'copy_tariff_plan'}
        defaultValue={
          !this.props.isNew
            ? this.props.tariffPlan.copy_tariff_plan
            : tariff_plans[0]
        }
        onChange={(e) => {
          if (this.props.onChange) this.props.onChange(e);
        }}
      >
        {tariffPlanLists}
      </select>,
      <select
        name={'move_to_scheme_id'}
        defaultValue={
          !this.props.isNew
            ? this.props.tariffPlan.tariff_scheme_id
            : tariff_plans[0]
        }
        onChange={(e) => {
          if (this.props.onChange) this.props.onChange(e);
        }}
      >
        {tariffSchemeLists}
      </select>,
    ];
  };

  generateBody = () => {
    let { tariffPlan, isNew } = this.props;
    let {
      isEditing,
      tariff_lines,
      showSingleTariffLine,
      addNewTariffLine,
      isLoading,
    } = this.state;
    let selects = this.generatePlanSelects();

    if (showSingleTariffLine || addNewTariffLine) {
      return (
        <TariffLinesSingle
          tariffPlan={this.props.tariffPlan}
          tariffLine={this.state.showSingleTariffLine}
          backButtonClick={this.backButtonClick}
          isNew={addNewTariffLine}
        ></TariffLinesSingle>
      );
    } else if (isNew) {
      return (
        <Fragment>
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            <Form.Group as={Row} controlId="copy_tariff_plan">
              <Form.Label column sm={2} className="info-label">
                * Copy Tariff Lines from Plan:
              </Form.Label>
              <Col sm={8}>{selects[3]}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="tariff_plan_name">
              <Form.Label column sm={2} className="info-label">
                * Name:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="tariff_plan_name" type="text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="commitment_recur">
              <Form.Label column sm={2} className="info-label">
                * Monthly Licencing Commitment:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="commitment_recur" type="text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="commitment_usage">
              <Form.Label column sm={2} className="info-label">
                * Monthly Usage Commitment:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="commitment_usage" type="text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="included_messages">
              <Form.Label column sm={2} className="info-label">
                * Included Messages:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="included_messages" type="text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="included_cf_minutes">
              <Form.Label column sm={2} className="info-label">
                * Included CF Minutes:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="included_cf_minutes" type="text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="max_users">
              <Form.Label column sm={2} className="info-label">
                Included Numbers:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="max_users" type="text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="plan_currency">
              <Form.Label column sm={2} className="info-label">
                * Currency:
              </Form.Label>
              <Col sm={8}>{selects[0]}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="plan_status">
              <Form.Label column sm={2} className="info-label">
                * Status:
              </Form.Label>
              <Col sm={8}>{selects[1]}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="plan_type">
              <Form.Label column sm={2} className="info-label">
                * Type:
              </Form.Label>
              <Col sm={8}>{selects[2]}</Col>
            </Form.Group>
            {/* <Button className='createProviderButton' variant='primary' type='submit'> */}
            {this.generateSaveButton()}
          </Form>
          {this.generateErrorText()}
          {this.generateEditButton()}
        </Fragment>
      );
    }
    // Display data if not editing
    else if (tariffPlan && !this.state.isEditing) {
      return (
        <Fragment>
          <Form>
            {this.generateFormGroup(
              '* Name:',
              tariffPlan.tariff_plan_name,
              'tariff_plan_name'
            )}
            {this.generateFormGroup(
              '* Monthly Licencing Commitment:',
              tariffPlan.commitment_recur,
              'commitment_recur'
            )}
            {this.generateFormGroup(
              '* Monthly Usage Commitment:',
              tariffPlan.commitment_usage,
              'commitment_usage'
            )}
            {this.generateFormGroup(
              '* Included Messages:',
              tariffPlan.included_messages,
              'included_messages'
            )}
            {this.generateFormGroup(
              '* Included CF Minutes:',
              tariffPlan.included_cf_minutes,
              'included_cf_minutes'
            )}
            {this.generateFormGroup(
              'Included Numbers:',
              tariffPlan.max_users,
              'max_users'
            )}
            {this.generateFormGroup(
              '* Currency:',
              tariffPlan.plan_currency_name,
              'plan_currency'
            )}
            {this.generateFormGroup(
              '* Status:',
              tariffPlan.plan_status_name,
              'plan_status'
            )}
            {this.generateFormGroup(
              '* Type:',
              tariffPlan.plan_type_name,
              'plan_type'
            )}
          </Form>
          {this.generateEditButton()}
          <Button
            className="cancelUserType btn-red btn btn-danger"
            onClick={this.cancelTariffPlan}
          >
            Cancel Plan
          </Button>
          <br />
          <br />
          <TariffLinesList
            tariffLines={tariff_lines}
            backButtonClick={this.backButtonClick}
            showSingleTariffLine={this.showSingleTariffLine}
            addNewTariffLine={this.addNewTariffLine}
            isLoading={isLoading}
          ></TariffLinesList>
        </Fragment>
      );
    } else if (tariffPlan && isEditing) {
      return (
        <Fragment>
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            <Form.Group as={Row} controlId="tariff_plan_name">
              <Form.Label column sm={2} className="info-label">
                * Name:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="tariff_plan_name"
                  type="text"
                  defaultValue={tariffPlan.tariff_plan_name}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="commitment_recur">
              <Form.Label column sm={2} className="info-label">
                * Monthly Licencing Commitment:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="commitment_recur"
                  type="text"
                  defaultValue={tariffPlan.commitment_recur}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="commitment_usage">
              <Form.Label column sm={2} className="info-label">
                * Monthly Usage Commitment:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="commitment_usage"
                  type="text"
                  defaultValue={tariffPlan.commitment_usage}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="included_messages">
              <Form.Label column sm={2} className="info-label">
                * Included Messages:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="included_messages"
                  type="text"
                  defaultValue={tariffPlan.included_messages}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="included_cf_minutes">
              <Form.Label column sm={2} className="info-label">
                * Included CF Minutes:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="included_cf_minutes"
                  type="text"
                  defaultValue={tariffPlan.included_cf_minutes}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="max_users">
              <Form.Label column sm={2} className="info-label">
                Included Numbers:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="max_users"
                  type="text"
                  defaultValue={tariffPlan.max_users}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="plan_currency">
              <Form.Label column sm={2} className="info-label">
                * Currency:
              </Form.Label>
              <Col sm={8}>{selects[0]}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="plan_status">
              <Form.Label column sm={2} className="info-label">
                * Status:
              </Form.Label>
              <Col sm={8}>{selects[1]}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="plan_type">
              <Form.Label column sm={2} className="info-label">
                * Type:
              </Form.Label>
              <Col sm={8}>{selects[2]}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="copy_tariff_plan">
              <Form.Label column sm={2} className="info-label">
                * Linked Plan:
              </Form.Label>
              <Col sm={8}>{selects[3]}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="copy_tariff_plan">
              <Form.Label column sm={2} className="info-label">
                * Move Plan to another Tariff Scheme:
              </Form.Label>
              <Col sm={8}>{selects[4]}</Col>
            </Form.Group>
            {/* <Button className='createProviderButton' variant='primary' type='submit'> */}
            <Button className="maxHeight" variant="primary" type="submit">
              Save
            </Button>
            {this.generateEditButton()}
          </Form>
          {this.generateErrorText()}
        </Fragment>
      );
    }

    return (
      <p>
        <span>(Carrier) No Data</span>
      </p>
    );
  };

  render() {
    return (
      <div className="Home carrier">
        {this.generateLander()}
        {this.generateBody()}
      </div>
    );
  }
}
