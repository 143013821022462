/*
  Admin.js - Admin Provider Main Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

// OUR COMPONENTS
import AdminTabs from '../index';

import ListItem from './ListItem';
import SingleProviderView from './SingleProviderView';
import Add from './Add';

// I18N
import formattedMessages from './FormattedMessages';

// STYLING
import '../Admin.css';

// NETWORK
import ProviderRequest from './ProviderRequest';
const providerRequest = new ProviderRequest();

export default class AdminProvider extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - providers: An array of Provider objects
    */

    this.state = {
      providers: [],
      showSingleProvider: false, // SingleProviderView
      selectedProvider: null, // SingleProviderView
      showAddProvider: false,
      error: null,
    };
  }

  async componentDidMount() {
    if (this.props.customer && this.props.customer !== null) {
      // Fetch list of Providers
      let sortedProviders = await this.getProviders();

      // Strictly for redirects to single providers
      if (this.props.location.state?.selectedProvider) {
        let select = this.props.location.state.selectedProvider;
        for (let i = 0; i < sortedProviders.length; i++) {
          if (sortedProviders[i].provider_id === select) {
            this.setState({
              selectedProvider: sortedProviders[i],
              showSingleProvider: true,
            });
            break;
          }
        }
      }
    }
  }

  /*
    Fetch all Providers in the system
  */
  getProviders = async () => {
    try {
      let providers = await providerRequest.getAllProviders(
        this.props.errorHandler
      );
      let sortedProviders = [];
      let activeP = [],
        trialP = [],
        suspendedP = [],
        cancelledP = [];

      // Ordering purposes
      for (let i = 0; i < providers.length; i++) {
        if (providers[i].provider_status === 'A') activeP.push(providers[i]);
        if (providers[i].provider_status === 'T') trialP.push(providers[i]);
        if (providers[i].provider_status === 'S') suspendedP.push(providers[i]);
        if (providers[i].provider_status === 'C') cancelledP.push(providers[i]);
      }

      // Alphabetical sort
      activeP = activeP.sort((a, b) => {
        if (a.provider_name < b.provider_name) return -1;
        if (a.provider_name > b.provider_name) return 1;
        return 0;
      });
      trialP = trialP.sort((a, b) => {
        if (a.provider_name < b.provider_name) return -1;
        if (a.provider_name > b.provider_name) return 1;
        return 0;
      });
      suspendedP = suspendedP.sort((a, b) => {
        if (a.provider_name < b.provider_name) return -1;
        if (a.provider_name > b.provider_name) return 1;
        return 0;
      });
      cancelledP = cancelledP.sort((a, b) => {
        if (a.provider_name < b.provider_name) return -1;
        if (a.provider_name > b.provider_name) return 1;
        return 0;
      });

      // Merge arrays together in correct order
      sortedProviders = sortedProviders.concat(activeP);
      sortedProviders = sortedProviders.concat(trialP);
      sortedProviders = sortedProviders.concat(suspendedP);
      sortedProviders = sortedProviders.concat(cancelledP);

      this.setState({
        providers: sortedProviders,
        showSingleProvider: false,
        selectedProvider: null,
        showAddProvider: false,
      });
      return sortedProviders;
    } catch (e) {
      this.setState({
        error: e.message,
      });
      return [];
    }
  };

  /*
    Used to display the Single Provider View
  */
  showSingleProvider = (e) => {
    return this.setState({
      showSingleProvider: true,
      selectedProvider: e,
    });
  };

  /*
    Used to display the Add Provider View
  */
  addNewProvider = () => {
    return this.setState({
      showAddProvider: true,
    });
  };

  /*
    Handle back button click
  */
  handleBackButtonClick = (refreshFlag) => {
    if (refreshFlag) {
      return this.getProviders();
    }

    return this.setState({
      showSingleProvider: false,
      selectedProvider: null,
      showAddProvider: false,
    });
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="ProviderLander" className="lander provider flex">
        <h3>{formattedMessages.listOfProviders}</h3>
        <Button
          className="btn add-new-template flexRight"
          onClick={() => this.addNewProvider()}
        >
          {formattedMessages.addNewText}
        </Button>
      </div>
    );
  };

  /*
    Generate Error Text
  */
  generateError = () => {
    if (!this.state.error) return null;

    return <div className="text-danger">{this.state.error}</div>;
  };

  /*
    Main Content generating function
  */
  generateContent = () => {
    if (this.state.showSingleProvider && this.state.selectedProvider) {
      return (
        <SingleProviderView
          provider={this.state.selectedProvider}
          backButtonClick={this.handleBackButtonClick}
          errorHandler={this.props.errorHandler}
          updateActingProviderDetails={this.props.updateActingProviderDetails}
        />
      );
    } else if (this.state.showAddProvider) {
      return (
        <Add
          backButtonClick={this.handleBackButtonClick}
          errorHandler={this.props.errorHandler}
        />
      );
    } else {
      return [this.generateLander(), this.generateTable()];
    }
  };

  /*
    Creates the Table/List to display all Provider data
  */
  generateTable = () => {
    return (
      <div key="ProviderList" className="provider table table-new">
        <table width="100%">
          <thead>
            <tr>
              <th
                className="tableTop medium column-right"
                style={{ width: '200px', marginLeft: '5px' }}
              >
                {formattedMessages.nameText}
              </th>
              <th
                className="tableTop medium column-left"
                style={{ marginLeft: '5px' }}
              >
                {formattedMessages.statusText}
              </th>
            </tr>
          </thead>
          <tbody>{this.generateTableBody()}</tbody>
        </table>
      </div>
    );
  };

  /*
    Generates the body of the Table/List to display all Provider data
  */
  generateTableBody = () => {
    let rows = [];
    let providers = this.state.providers;

    for (let i = 0; i < providers.length; i++) {
      rows.push(
        <ListItem
          key={providers[i].provider_id}
          id={providers[i].provider_id}
          name={providers[i].provider_name}
          active={providers[i].provider_status_name}
          provider={providers[i]}
          onClickName={(e) => this.showSingleProvider(e)}
        ></ListItem>
      );
    }
    return rows;
  };

  render() {
    let { accessLevel, accessType } = this.props;

    if (accessLevel === '3' && accessType === '3') {
      return (
        <AdminTabs
          tab="provider"
          accessLevel={accessLevel}
          accessType={accessType}
        >
          {this.generateContent()}
        </AdminTabs>
      );
    }
    return null;
  }
}
