// NPM
import React, { Component } from 'react';
import { Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

// COMPONENTS
import WebSMSTabs from '../index';
import LanguagePicker from '../../../components/LanguagePicker';
import CustomTooltip from '../../../components/CustomTooltip';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';
//import timezones from './Timezones';

// NETWORKING
import SettingsRequest from './SettingsRequest';

// TIMEZONES
import TimezoneRequest from '../../../components/requests/TimezoneRequest';
import { validatePhoneNumber } from '../../../utils/validate';
import { validatePassword } from '../../../utils/validate';
import ErrorText from '../../../components/ErrorText';
// INSTANTIATE
const timezoneRequest = new TimezoneRequest();
const settingsRequests = new SettingsRequest();

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveryReceipts: false,
      deliveryNumber: '',
      password: '',
      confirmPassword: '',
      validPassword: true,
      validConfirmPassword: true,
      deliveryOrigin: null,
      language_id: null,
      timeOffset: null,
      inboxDay: null,
      signature: null,
      forwardReplies: null,
      loaded: false,
      timezones: [],
    };
  }

  async componentDidMount() {
    if (this.props.customer && this.props.customer !== null) {
      await this.getSettings();
    }
  }

  getSettings = async () => {
    let settingsData = await settingsRequests.getSettings(
      this.props.errorHandler
    );

    return this.setState({
      settings: settingsData,
      deliveryNumber: settingsData.phone_number || '',
      language_id: settingsData.language,
      timeOffset:
        settingsData.time_offset === null ||
        settingsData.time_offset === undefined ||
        settingsData.time_offset === 'null'
          ? '+000010050010050'
          : settingsData.time_offset,
      timezoneString:
        settingsData.timezone_string === null ||
        settingsData.timezone_string === undefined ||
        settingsData.timezone_string === 'null'
          ? '+000010050010050'
          : settingsData.timezone_string,
      signature:
        settingsData.signature === null ||
        settingsData.signature === undefined ||
        settingsData.signature === 'null'
          ? ''
          : settingsData.signature,
      forwardReplies: settingsData.replies_type,
      deliveryReceipts:
        settingsData.receipt_flag === 'Y' || settingsData.receipt_flag
          ? true
          : false,
      deliveryOrigin: settingsData.message_originator,
      loaded: true,
      timezones: settingsData.timezones,
    });
  };

  /*
    Convenience function to handle the updating of a form element
  */
  handleInputChange = (e) => {
    const target = e.target;
    const value =
      target.name === 'deliveryReceipts' ? target.checked : target.value;
    const name = target.name;

    if (target.name === 'deliveryNumber' && !value) {
      this.setState({ forwardReplies: 'E' });
    }
    this.setState({ [name]: value }, () => {
      let { password, confirmPassword } = this.state;
      if (!password || password === '') {
        this.setState({
          validPassword: true,
        });
      } else {
        if (validatePassword(password)) {
          this.setState({
            validPassword: true,
          });
        } else {
          this.setState({
            validPassword: false,
          });
        }
      }
      if (confirmPassword && confirmPassword !== password) {
        this.setState({
          validConfirmPassword: false,
        });
      } else {
        this.setState({
          validConfirmPassword: true,
        });
      }
    });
  };

  /**
   *  Resets the page's info when the tab is clicked.
   */
  handlePageReset = () => {
    this.getSettings();
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      loaded: false,
      hasError: '',
    });
    const newSettings = {};
    if (this.state.deliveryReceipts !== null)
      newSettings.receipt_flag = this.state.deliveryReceipts;
    if (this.state.deliveryOrigin)
      newSettings.message_originator = this.state.deliveryOrigin;
    if (this.state.deliveryNumber && this.state.deliveryNumber.trim() !== '') {
      if (validatePhoneNumber(this.state.deliveryNumber)) {
        newSettings.phone_number = this.state.deliveryNumber;
      } else {
        return this.setState({
          hasError: true,
          error: formattedMessages.invalidPhoneText,
          loaded: true,
        });
      }
    } else {
      if (this.state.forwardReplies === 'S') {
        return this.setState({
          hasError: true,
          error: formattedMessages.missingPhoneText,
          loaded: true,
        });
      }
    }

    if (!this.state.password || this.state.password === '') {
      if (this.state.confirmPassword) {
        return this.setState({
          hasError: true,
          error: formattedMessages.passwordCheck,
          loaded: true,
        });
      }
    } else {
      if (!this.state.validPassword) {
        return this.setState({
          hasError: true,
          error: formattedMessages.passwordNotSecure,
          loaded: true,
        });
      }
      if (!this.state.password || !this.state.confirmPassword) {
        return this.setState({
          hasError: true,
          error: formattedMessages.passwordCheck,
          loaded: true,
        });
      }
      if (!this.state.validConfirmPassword) {
        return this.setState({
          hasError: true,
          error: formattedMessages.passwordMisMatch,
          loaded: true,
        });
      }
      newSettings.password = this.state.password;
      newSettings.password_check = this.state.confirmPassword;
    }

    newSettings.replies_type = this.state.forwardReplies;
    if (this.state.language_id) newSettings.language = this.state.language_id;
    if (this.state.timeOffset) newSettings.time_offset = this.state.timeOffset;
    newSettings.timezoneString = newSettings.time_offset;
    newSettings.signature = this.state.signature ? this.state.signature : '';

    let time = await timezoneRequest.getTimezoneOffset();

    localStorage.setItem('offset', time.timezone_offset);
    localStorage.setItem('timeString', time.timezone_name);
    localStorage.setItem('bias', time.timezone_bias);
    localStorage.setItem('timeShort', time.time_short);

    console.log('submit:', newSettings);
    await settingsRequests.updateSettings(newSettings, this.props.errorHandler);

    time = await timezoneRequest.getTimezoneOffset();

    localStorage.setItem('offset', time.timezone_offset);
    localStorage.setItem('timeString', time.timezone_name);
    localStorage.setItem('bias', time.timezone_bias);
    localStorage.setItem('timeShort', time.time_short);
    this.props.navigate('/websms/compose');

    return this.setState({
      loaded: true,
    });
  };

  async cancelForm() {
    this.setState({
      loaded: false,
      hasError: false,
    });
    let settingsData = await settingsRequests.getSettings(
      this.props.errorHandler
    );
    this.props.navigate('/websms/compose');
    return this.setState({
      settings: settingsData,
      deliveryNumber: settingsData.phone_number || '',
      password: settingsData.password_check ? null : '',
      confirmPassword: settingsData.confirm_password ? null : '',
      language_id: settingsData.language,
      timeOffset: settingsData.timezone_string,
      timezoneString: settingsData.timezone_string,
      signature: settingsData.signature,
      forwardReplies: settingsData.replies_type,
      deliveryReceipts: settingsData.receipt_flag === 'Y' ? true : false,
      deliveryOrigin: settingsData.message_originator,
      loaded: true,
      timezones: settingsData.timezones,
    });
  }

  /*
    Renders Error Text Component
  */
  generateErrorText = () => {
    return (
      <ErrorText hasError={this.state.hasError} errorText={this.state.error} />
    );
  };

  render() {
    let {
      deliveryReceipts,
      deliveryNumber,
      password,
      confirmPassword,
      validPassword,
      validConfirmPassword,
      language_id,
      timeOffset,
      signature,
      forwardReplies,
      timezones,
    } = this.state;
    let timezoneList = [];

    console.log('here', timeOffset);

    for (let i = 0; i < timezones.length; i++) {
      if (timeOffset === timezones[i].timezone_offset)
        console.log('found:', timezones[i].timezone_offset);
      timezoneList.push(
        <option
          key={timezones[i].timezone_offset}
          value={timezones[i].timezone_offset}
        >
          {timezones[i].timezone_name}
        </option>
      );
    }

    if (this.state.loaded === false) {
      return (
        <WebSMSTabs
          tab="settings"
          mobileMenuOpen={this.props.mobileMenuOpen}
          navigate={this.props.navigate}
          toggleMobileMenu={this.props.toggleMobileMenu}
          handlePageReset={this.handlePageReset}
        >
          <div className="Home">
            <div className="lander">Loading...</div>
          </div>
        </WebSMSTabs>
      );
    }
    return (
      <WebSMSTabs
        tab="settings"
        mobileMenuOpen={this.props.mobileMenuOpen}
        navigate={this.props.navigate}
        toggleMobileMenu={this.props.toggleMobileMenu}
        handlePageReset={this.handlePageReset}
      >
        <div className="Home">
          <div className="lander">
            <h2>{formattedMessages.settingsTitle}</h2>
            <br />
          </div>
          <Form onSubmit={this.handleSubmit} onChange={this.handleInputChange}>
            <Form.Group>
              <table width="75%" className="no-table-row-background">
                <tbody>
                  <tr valign="middle">
                    <td style={{ width: '200px' }}>
                      <Form.Label>
                        {formattedMessages.deliveryReceiptsText}
                        <CustomTooltip
                          tooltipText={formattedMessages.deliveryReceiptsTip}
                          margin="5px"
                        />
                      </Form.Label>
                    </td>
                    <td colSpan="2" className="table-check">
                      <Form.Check
                        checked={deliveryReceipts}
                        name="deliveryReceipts"
                        label="Enabled"
                        onChange={this.handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr valign="middle">
                    <td>
                      <Form.Label>{formattedMessages.mobileText}</Form.Label>
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={deliveryNumber}
                        name="deliveryNumber"
                        onChange={this.handleInputChange}
                      />
                    </td>
                    <div className="settings-text-inline">
                      {formattedMessages.forwardReplies}
                    </div>
                    <div className="settings-text-inline">
                      <Form.Check
                        checked={forwardReplies === 'S' ? true : false}
                        name="forwardReplies"
                        type="radio"
                        value="S"
                        disabled={!this.state.deliveryNumber}
                        onChange={this.handleInputChange}
                      />
                      {formattedMessages.forwardMobileText}
                    </div>
                    <div className="settings-text-inline">
                      <Form.Check
                        checked={forwardReplies === 'E' ? true : false}
                        name="forwardReplies"
                        type="radio"
                        value="E"
                        onChange={this.handleInputChange}
                      />
                      {formattedMessages.forwardEmailText}
                    </div>
                  </tr>
                  <tr valign="middle">
                    <td>
                      <Form.Label>{formattedMessages.languageText}</Form.Label>
                    </td>
                    <td>
                      <LanguagePicker
                        className="form-control"
                        selectedLanguageId={language_id}
                        onChange={this.handleInputChange}
                      ></LanguagePicker>
                    </td>
                  </tr>
                  <tr valign="middle">
                    <td>
                      <Form.Label>
                        {formattedMessages.timeOffsetText}
                      </Form.Label>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        name="timeOffset"
                        value={timeOffset}
                        onChange={this.handleInputChange}
                      >
                        {timezoneList}
                      </Form.Control>
                    </td>
                  </tr>
                  <tr valign="middle">
                    <td>
                      <Form.Label>
                        {formattedMessages.password}
                        <CustomTooltip
                          tooltipText="The passwords must be 8 or more characters long 
                                      and contains at least one uppercase and one lowercase letter, 
                                      a number and a symbol."
                          margin="5px"
                        />
                      </Form.Label>
                    </td>
                    <td>
                      <OverlayTrigger
                        placement="right"
                        show={password && !validPassword}
                        overlay={
                          <Tooltip id="password-tooltip">
                            <p style={{ textAlign: 'left' }}>
                              Password is not strong enough.
                            </p>
                          </Tooltip>
                        }
                      >
                        <Form.Control
                          type="password"
                          value={password}
                          name="password"
                          autoComplete="new-password"
                          onChange={this.handleInputChange}
                          isInvalid={!validPassword}
                        />
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr valign="middle">
                    <td>
                      <Form.Label>
                        {formattedMessages.confirmPassword}
                      </Form.Label>
                    </td>
                    <td>
                      <OverlayTrigger
                        placement="right"
                        show={password !== '' && !validConfirmPassword}
                        overlay={
                          <Tooltip id="confirm-password-tooltip">
                            <p style={{ textAlign: 'left' }}>
                              {"Passwords don't match."}
                            </p>
                          </Tooltip>
                        }
                      >
                        <Form.Control
                          type="password"
                          value={confirmPassword}
                          name="confirmPassword"
                          autoComplete="new-password"
                          onChange={this.handleInputChange}
                          isInvalid={password !== '' && !validConfirmPassword}
                        />
                      </OverlayTrigger>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <br />
              <Form.Label>{formattedMessages.signatureText}</Form.Label>
              <Form.Control
                className="settings-form-control"
                as="textarea"
                value={signature}
                name="signature"
                onChange={this.handleInputChange}
              />

              {this.generateErrorText()}
              <table width="100%" className="no-table-row-background">
                <tbody>
                  <tr>
                    <td align="left">
                      <Form.Group controlId="buttons">
                        <Button
                          className="maxHeight"
                          // style={{ 'width': '150px' }}
                          onClick={this.handleSubmit}
                        >
                          {formattedMessages.saveText}
                        </Button>
                        <Button
                          className="maxHeight"
                          // style={{ 'width': '150px' }}
                          onClick={() => this.cancelForm()}
                        >
                          {formattedMessages.cancelText}
                        </Button>
                      </Form.Group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Form.Group>
          </Form>
        </div>
      </WebSMSTabs>
    );
  }
}
