import React from 'react';

const ListItem = ({
  id,
  planName = '',
  monthlyLicCom = '',
  monthlyUsageCom = '',
  includedMessages = '',
  maxUsers = 'N/A',
  planStatus = '',
  onClickName = null,
  plan = null,
}) => (
  <tr key={`list${id}`} id={id}>
    <td className="column-left column-right" name="name">
      <span className="blue" onClick={() => onClickName(plan)}>
        {planName}
      </span>
    </td>
    <td className="medium column-right" name="active">
      <span>{monthlyLicCom}</span>
    </td>
    <td className="medium column-right" name="active">
      <span>{monthlyUsageCom}</span>
    </td>
    <td className="medium column-right" name="active">
      <span>{includedMessages}</span>
    </td>
    <td className="medium column-right" name="active">
      <span>{maxUsers}</span>
    </td>
    <td className="medium column-right" name="active">
      <span>{planStatus}</span>
    </td>
  </tr>
);

export default ListItem;
