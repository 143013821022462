/*
  Single.js - Admin Tariff Lines Single

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';

// COMPONENT
import ErrorText from '../../../../../components/ErrorText';

// NETWORK
import TariffRequest from '../../TariffRequest';
import formattedMessages from '../../../Provider/FormattedMessages';

// INSTANTIATION
const tariffRequest = new TariffRequest();

export default class TariffLineSingleView extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - tariffPlan: Tariff Plan object
        - tariffLine: Tariff Line object (optional)
        - backButtonClick: Function to handle Back Button click
        - isNew: Whether or not it's handling a new object,
        - errorHandler: A function to pass errors to
    */

    console.log('Have Tariff Line props:', this.props);

    this.state = {
      isEditing: false,
      edits: {},
      types: [],
      categories: [],
      periods: [],
      units: [],
      isSaving: false,
      hasError: false,
      errorMessage: '',
    };
  }

  async componentDidMount() {
    let extras = await tariffRequest.getLineExtras();
    console.log('Extra:', extras);
    return this.setState({
      types: extras.types,
      categories: extras.categories,
      periods: extras.periods,
      units: extras.units,
    });
  }

  /*
    Handles Edit Mode on/off
  */
  toggleEdit = () => {
    return this.setState({
      isEditing: !this.state.isEditing,
      edits: {},
      hasError: false,
      errorMessage: '',
    });
  };

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let edits = this.state.edits;
    edits[name] = value;

    console.log('Edits is:', edits);

    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles Edit form submission
  */
  handleSubmit = async (e) => {
    this.setState({ isSaving: true });
    try {
      console.log('Handle submit');
      e.preventDefault();
      let data = this.state.edits;
      this.setState({
        hasError: false,
        errorMessage: '',
      });

      // Have new Object, create
      if (this.props.isNew) {
        if (!data.tariff_name)
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff name.',
          });
        if (!data.tariff_type)
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff type.',
          });
        if (!data.category)
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff category.',
          });
        if (!data.rate || isNaN(data.rate))
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff rate.',
          });
        if (!data.period)
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff period.',
          });
        if (!data.unit)
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff unit.',
          });
        if (!data.quantity || isNaN(data.quantity))
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff quantity.',
          });
        if (!data.batches || isNaN(data.batches))
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff batches.',
          });
        await tariffRequest.createNewTariffLine(
          this.props.tariffPlan.tariff_scheme_id,
          this.props.tariffPlan.tariff_plan_id,
          data,
          this.props.errorHandler
        );
      }
      // Editing object, update
      else {
        if ('tariff_name' in data && !data.tariff_name)
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff name.',
          });
        if ('tariff_type' in data && !data.tariff_type)
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff type.',
          });
        if ('category' in data && !data.category)
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff category.',
          });
        if ('rate' in data && (!data.rate || isNaN(data.rate)))
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff rate.',
          });
        if ('period' in data && !data.period)
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff period.',
          });
        if ('unit' in data && !data.unit)
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff unit.',
          });
        if ('quantity' in data && (!data.quantity || isNaN(data.quantity)))
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff quantity.',
          });
        if ('batches' in data && (!data.batches || isNaN(data.batches)))
          return this.setState({
            hasError: true,
            errorMessage: 'Set Tariff batches.',
          });
        await tariffRequest.updateTariffLine(
          this.props.tariffPlan.tariff_scheme_id,
          this.props.tariffPlan.tariff_plan_id,
          this.props.tariffLine.tariff_line_id,
          data,
          this.props.errorHandler
        );
      }
      this.props.backButtonClick('refresh');
      this.setState({ isSaving: false });
    } catch (e) {
      console.log('Have err:', e);
      // Handle error
    }
  };

  /*
    Handles Delete of object
  */
  handleDelete = async (e) => {
    await tariffRequest.deleteTariffLine(
      this.props.tariffPlan.tariff_scheme_id,
      this.props.tariffPlan.tariff_plan_id,
      this.props.tariffLine.tariff_line_id,
      this.props.errorHandler
    );
    this.props.backButtonClick('refresh');
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="TariffLineShow" className="lander">
        <p onClick={() => this.props.backButtonClick()} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backText}</span>
        </p>
        <h3>Tariff Line Information</h3>
      </div>
    );
  };

  /*
    Convenience function to generate Form Groups
  */
  generateFormGroup = (label, displayValue, controlId) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <p>{displayValue}</p>
        </Col>
      </Form.Group>
    );
  };

  generateEditButton = () => {
    let { isEditing } = this.state;
    let buttonText = isEditing ? 'Cancel' : 'Edit';

    if (!this.props.isNew || typeof this.props.isNew === 'undefined') {
      return (
        <Button className="maxHeight" onClick={this.toggleEdit}>
          {buttonText}
        </Button>
      );
    }
    return null;
  };

  generateErrorText = () => {
    return (
      <ErrorText
        hasError={this.state.hasError}
        errorText={this.state.errorMessage}
      />
    );
  };

  /*
    Generates the different Select elements for the Add New and Edit states of
    this component.
  */
  generateLineSelects = () => {
    let { types, categories, periods, units } = this.state;
    // Set up Types Select elements
    let typesList = [];
    for (let i = 0; i < types.length; i++) {
      typesList.push(
        <option key={types[i].code} value={types[i].code}>
          {types[i].meaning}
        </option>
      );
    }
    // Set up Categories Select elements
    let categoriesList = [];
    for (let i = 0; i < categories.length; i++) {
      categoriesList.push(
        <option key={categories[i].code} value={categories[i].code}>
          {categories[i].meaning}
        </option>
      );
    }
    // Set up Periods Select elements
    let periodsLists = [];
    for (let i = 0; i < periods.length; i++) {
      periodsLists.push(
        <option key={periods[i].code} value={periods[i].code}>
          {periods[i].meaning}
        </option>
      );
    }
    // Set up Units Select elements
    let unitsLists = [];
    for (let i = 0; i < units.length; i++) {
      unitsLists.push(
        <option key={units[i].code} value={units[i].code}>
          {units[i].meaning}
        </option>
      );
    }

    return [
      <select
        name={'tariff_type'}
        defaultValue={
          !this.props.isNew ? this.props.tariffLine.tariff_type : types[0]
        }
        onChange={(e) => {
          if (this.props.onChange) this.props.onChange(e);
        }}
      >
        {typesList}
      </select>,
      <select
        name={'category'}
        defaultValue={
          !this.props.isNew ? this.props.tariffLine.category : categories[0]
        }
        onChange={(e) => {
          if (this.props.onChange) this.props.onChange(e);
        }}
      >
        {categoriesList}
      </select>,
      <select
        name={'period'}
        defaultValue={
          !this.props.isNew ? this.props.tariffLine.period : periods[0]
        }
        onChange={(e) => {
          if (this.props.onChange) this.props.onChange(e);
        }}
      >
        {periodsLists}
      </select>,
      <select
        name={'unit'}
        defaultValue={!this.props.isNew ? this.props.tariffLine.unit : units[0]}
        onChange={(e) => {
          if (this.props.onChange) this.props.onChange(e);
        }}
      >
        {unitsLists}
      </select>,
    ];
  };

  generateBody = () => {
    let { tariffLine, isNew } = this.props;
    let { isEditing } = this.state;
    let selects = this.generateLineSelects();

    if (isNew) {
      return (
        <Fragment>
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            <Form.Group as={Row} controlId="tariff_name">
              <Form.Label column sm={2} className="info-label">
                * Name:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="tariff_name" type="text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="tariff_type">
              <Form.Label column sm={2} className="info-label">
                * Type:
              </Form.Label>
              <Col sm={8}>{selects[0]}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="category">
              <Form.Label column sm={2} className="info-label">
                * Category:
              </Form.Label>
              <Col sm={8}>{selects[1]}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="rate">
              <Form.Label column sm={2} className="info-label">
                * Rate:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="rate" type="text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="period">
              <Form.Label column sm={2} className="info-label">
                * Period:
              </Form.Label>
              <Col sm={8}>{selects[2]}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="unit">
              <Form.Label column sm={2} className="info-label">
                * Unit:
              </Form.Label>
              <Col sm={8}>{selects[3]}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="quantity">
              <Form.Label column sm={2} className="info-label">
                * Quantity:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="quantity" type="text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="batches">
              <Form.Label column sm={2} className="info-label">
                * Batches:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="batches" type="text" />
              </Col>
            </Form.Group>
            <Button
              className="createProviderButton"
              variant="primary"
              type="submit"
            >
              Save
            </Button>
          </Form>
          {this.generateErrorText()}
          {this.generateEditButton()}
        </Fragment>
      );
    }
    // Display data if not editing
    else if (tariffLine && !this.state.isEditing) {
      return (
        <Fragment>
          <Form>
            {this.generateFormGroup(
              '* Name:',
              tariffLine.tariff_name,
              'tariff_name'
            )}
            {this.generateFormGroup(
              '* Type:',
              tariffLine.tariff_type_name,
              'tariff_type'
            )}
            {this.generateFormGroup(
              '* Category:',
              tariffLine.category_name,
              'category'
            )}
            {this.generateFormGroup('* Rate:', tariffLine.rate, 'rate')}
            {this.generateFormGroup(
              '* Period:',
              tariffLine.period_name,
              'period'
            )}
            {this.generateFormGroup('* Unit:', tariffLine.unit_name, 'unit')}
            {this.generateFormGroup(
              '* Quantity:',
              tariffLine.quantity,
              'quantity'
            )}
            {this.generateFormGroup(
              '* Batches:',
              tariffLine.batches,
              'batches'
            )}
          </Form>
          {this.generateEditButton()}
          <Button className="maxHeight" onClick={this.handleDelete}>
            Cancel
          </Button>
        </Fragment>
      );
    } else if (tariffLine && isEditing) {
      return (
        <Fragment>
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            <Form.Group as={Row} controlId="tariff_name">
              <Form.Label column sm={2} className="info-label">
                * Name:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="tariff_name"
                  type="text"
                  defaultValue={tariffLine.tariff_name}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="tariff_type">
              <Form.Label column sm={2} className="info-label">
                * Type:
              </Form.Label>
              <Col sm={8}>{selects[0]}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="category">
              <Form.Label column sm={2} className="info-label">
                * Category:
              </Form.Label>
              <Col sm={8}>{selects[1]}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="rate">
              <Form.Label column sm={2} className="info-label">
                * Rate:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="rate"
                  type="text"
                  defaultValue={tariffLine.rate}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="period">
              <Form.Label column sm={2} className="info-label">
                * Period:
              </Form.Label>
              <Col sm={8}>{selects[2]}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="unit">
              <Form.Label column sm={2} className="info-label">
                * Unit:
              </Form.Label>
              <Col sm={8}>{selects[3]}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="quantity">
              <Form.Label column sm={2} className="info-label">
                * Quantity:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="quantity"
                  type="text"
                  defaultValue={tariffLine.quantity}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="batches">
              <Form.Label column sm={2} className="info-label">
                * Batches:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="batches"
                  type="text"
                  defaultValue={tariffLine.batches}
                />
              </Col>
            </Form.Group>
            <Button
              className="createProviderButton"
              variant="primary"
              type="submit"
            >
              Save
            </Button>
            {this.generateEditButton()}
          </Form>
          {this.generateErrorText()}
        </Fragment>
      );
    }

    return <span>(Tariff Line) No Data</span>;
  };

  render() {
    return (
      <div className="Home carrier">
        {this.generateLander()}
        {this.generateBody()}
      </div>
    );
  }
}
