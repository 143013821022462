/*
  FormattedMessages.js - FormattedMessages for Error Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React from 'react';
import { FormattedMessage } from 'react-intl';

const FormattedMessages = {
  errorLogsTitle: (
    <FormattedMessage
      id="Websms.ErrorLogsTitle"
      defaultMessage="Error Logs"
    ></FormattedMessage>
  ),
  errorText: (
    <FormattedMessage
      id="Global.ErrorText"
      defaultMessage="Error:"
    ></FormattedMessage>
  ),
  errorActivityLog: (
    <FormattedMessage
      id="Websms.ErrorActivityLog"
      defaultMessage="Error Activity Log"
    ></FormattedMessage>
  ),
  searchingText: (
    <FormattedMessage
      id="App.SearchingText"
      defaultMessage="Searching..."
    ></FormattedMessage>
  ),
  tableHeaderLogTime: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderLogTime"
      defaultMessage="Log Time (UTC)"
    ></FormattedMessage>
  ),
  tableHeaderLogLevel: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderLogLevel"
      defaultMessage="Log Level"
    ></FormattedMessage>
  ),
  tableHeaderLogGroupId: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderLogGroupId"
      defaultMessage="Log Group Id"
    ></FormattedMessage>
  ),
  tableHeaderProcessName: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderProcessName"
      defaultMessage="Process Name"
    ></FormattedMessage>
  ),
  tableHeaderProgramName: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderProgramName"
      defaultMessage="Program Name"
    ></FormattedMessage>
  ),
  tableHeaderLogMessage: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderLogMessage"
      defaultMessage="Log Message"
    ></FormattedMessage>
  ),
  tableHeaderTag: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderTag"
      defaultMessage="Tag"
    ></FormattedMessage>
  ),
  tableHeaderJson: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderJson"
      defaultMessage="Json Object"
    ></FormattedMessage>
  ),
  tableHeaderAllFields: (
    <FormattedMessage
      id="Websms.TableHeaderAllFields"
      defaultMessage="Other Fields"
    ></FormattedMessage>
  ),
  clear: (
    <FormattedMessage id="Websms.clearFilters" defaultMessage="Clear Filters" />
  ),
  back: (
    <FormattedMessage
      id="Websms.backText"
      defaultMessage="Back to Error Logs"
    />
  ),
};

export default FormattedMessages;
