/*
  Sent/List.js - Component to generate the list of Sent SMS items

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';

import { FormattedMessage } from 'react-intl';
// COMPONENTS
import ListItem from './ListItem';

// CONFIG
import config from '../../../../Config';
import { getZoneOffsettedDate } from '../../../../utils/dates';

export default class ListComponent extends Component {
  constructor(props) {
    super(props);

    /*
			PROPS LIST
				- list: List of Sent SMS objects to be displayed
				- showSingleSentSms: A function to call when clicking on a Sent SMS message field
		*/

    this.state = {
      pageNumber: 1,
      error: null,
    };
    this.pageLength = config.defaultPL;
  }

  /*
    Handles the change event when clicking a pagination number
  */
  changeEvent = (i) => {
    return this.setState({
      pageNumber: i + 1,
    });
  };

  generateTableBody = () => {
    let { list } = this.props;
    let rows = [];
    // console.log('list:', list.length, list);
    for (let i = 0; i < list.length; i++) {
      let element = list[i];
      let rezoned = getZoneOffsettedDate(element.sms_sent);

      let smsText;
      try {
        smsText = decodeURIComponent(element.sms_text);
      } catch (err) {
        smsText = 'ERROR: MALFORMED TEXT';
      }

      rows.push(
        <ListItem
          key={element.sms_id}
          id={element.sms_id}
          createdDate={rezoned}
          from={element.originator || 'N/A'}
          to={element.dest_tn || ''}
          name={
            element.dest_name || element.dest_email || element.dest_tn || ''
          }
          messageText={smsText || ''}
          status={element.sent_flag || ''}
          sentSms={element}
          onClickMessage={(e) => this.props.showSingleSentSms(e)}
          handleCancelList={this.props.handleCancelList}
          checked={this.props.cancelList?.has(element.sms_id)}
        />
      );
    }
    return rows;
  };

  generateMobileTableBody = () => {
    let { list } = this.props;
    let rows = [];
    let { pageNumber } = this.state;
    let displayList = list.slice(
      (pageNumber - 1) * this.pageLength,
      pageNumber * this.pageLength - 1 + 1
    );
    for (let i = 0; i < displayList.length; i++) {
      let element = displayList[i];

      let rezoned = getZoneOffsettedDate(element.sms_sent);

      let smsText;
      try {
        smsText = decodeURIComponent(element.sms_text);
      } catch (err) {
        smsText = 'ERROR: MALFORMED TEXT';
      }

      rows.push(
        <tr
          key={i}
          id={element.sms_id}
          valign="top"
          onClick={() => this.props.showSingleSentSms(element)}
        >
          <td>
            <b>{rezoned}</b>
            <br />
            {element.dest_name || element.dest_tn || element.dest_email || ''}
            <br />
            {smsText || ''}
          </td>
        </tr>
      );
    }
    return rows;
  };

  render() {
    let dateTimeText = (
      <FormattedMessage id="Websms.DateTimeText" defaultMessage="Date/Time" />
    );
    let fromText = (
      <FormattedMessage id="websms.FromText" defaultMessage="From" />
    );
    let toText = <FormattedMessage id="Websms.ToText" defaultMessage="To" />;
    let name = <FormattedMessage id="Websms.Name" defaultMessage="Name" />;
    let messageText = (
      <FormattedMessage id="Websms.MessageText" defaultMessage="Message" />
    );
    let statusText = (
      <FormattedMessage id="Websms.StatusText" defaultMessage="Status" />
    );

    return (
      <Fragment>
        <div className="visible-desktop">
          <Table id="inbox">
            <thead>
              <tr key="head">
                <th className="medium textLeft tableTop">{dateTimeText}</th>
                <th className="medium textLeft tableTop">{fromText}</th>
                <th className="medium textLeft tableTop">{toText}</th>
                <th className="medium textLeft tableTop">{name}</th>
                <th className="wide textLeft tableTop">{messageText}</th>
                <th className="medium textLeft tableTop" colSpan={2}>
                  {statusText}
                </th>
              </tr>
            </thead>
            <tbody>{this.generateTableBody()}</tbody>
          </Table>
          {/*<Paginator itemCount={this.props.list.length} changeEvent = {this.changeEvent}/>*/}
        </div>
        <div className="visible-device">
          <table id="sent" width="100%" cellSpacing="0">
            <thead>
              <tr>
                <th className="medium textLeft tableTop"></th>
              </tr>
            </thead>
            <tbody>{this.generateMobileTableBody()}</tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}
