/*
  Register/index.js - Root Register Component

  Author: Kyle Combeer
  Company: Virtual Ark
*/

// NPM
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Modal,
  Button,
  Form,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
// import { FormattedMessage } from 'react-intl';

// COMPONENTS
import LoaderButton from '../../components/LoaderButton';

// I18N
import formattedMessages from './FormattedMessages';

// STYLING
import './Register.css';

// NETWORK
import RegisterRequest from './RegisterRequest';
const registerRequest = new RegisterRequest();
import CustomTooltip from '../../components/CustomTooltip';
import {
  validateName,
  validateNumberForCountry,
  sanitizeNumber,
  validatePassword,
  validateEmail,
} from '../../utils/validate';

export default class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      validPassword: true,
      validConfirmPassword: true,
      company: '',
      country: '',
      countryCode: '',
      tsAndCs: false,
      productOfInterest: '',
      extraInfo: '',
      hasRegistered: false,
      hasError: false,
      errorText: '',
      signUpAgreement: false,
      showVerification: false,
      verificationCode: '',
      resendingCode: false,
      verifyingCode: false,
      canResendCode: true,
    };
  }

  /*
    Validates form
  */
  isFormValid = () => {
    let isValidForm = false;
    let errorText = '';
    let {
      firstName,
      lastName,
      email,
      phoneNumber,
      company,
      password,
      confirmPassword,
      country,
      countryCode,
    } = this.state;
    // console.log('isFromValid?', JSON.stringify(this.state, null, 2));

    if (phoneNumber)
      try {
        phoneNumber = sanitizeNumber(phoneNumber, countryCode);
      } catch (e) {
        console.error('error with phone parsing:', e);
        phoneNumber = null;
      }

    if (!validateName(firstName)) errorText = formattedMessages.errorFirstName;
    else if (!validateName(lastName))
      errorText = formattedMessages.errorLastName;
    else if (!validateEmail(email))
      errorText = formattedMessages.errorEmailCheck;
    else if (!phoneNumber)
      errorText = formattedMessages.errorMissingPhoneNumber;
    else if (
      !validateNumberForCountry(phoneNumber, countryCode) ||
      phoneNumber.trim() === ''
    )
      errorText = formattedMessages.errorPhoneNumber;
    else if (!password || !confirmPassword)
      errorText = formattedMessages.errorPassword;
    else if (!validatePassword(password))
      errorText = formattedMessages.errorCheckPasswordStrong;
    else if (!(password === confirmPassword))
      errorText = formattedMessages.errorPasswordMatch;
    else if (!validateName(company)) errorText = formattedMessages.errorCompany;
    else if (!country) errorText = formattedMessages.errorCountry;

    if (!errorText) isValidForm = true;
    else this.setState({ errorText: errorText });
    return isValidForm;
  };

  /**
   * Goes back to the login page.
   */
  backToLogin = () => {
    this.props.navigate('/');
  };

  contactSupport = () => {
    location.assign = window.open('https://redoxygen.com/contact-us/');
  };

  /*
    Convenience function to handle the updating of a form element
  */
  handleInputChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (target.name === 'country') {
      const country = e.target.selectedOptions[0].attributes[0].value;
      const code = e.target.selectedOptions[0].attributes[1].value;
      // console.log(code, country);
      // Set the phone number to a more robust standard.

      return this.setState({
        country: country,
        countryCode: code,
      });
    }
    this.setState({ [name]: value }, () => {
      let { password, confirmPassword } = this.state;
      if (!password || password === '') {
        this.setState({
          validPassword: true,
        });
      } else {
        if (validatePassword(password)) {
          this.setState({
            validPassword: true,
          });
        } else {
          this.setState({
            validPassword: false,
          });
        }
      }
      if (confirmPassword && confirmPassword !== password) {
        this.setState({
          validConfirmPassword: false,
        });
      } else {
        this.setState({
          validConfirmPassword: true,
        });
      }
    });
  };

  /*
    Sets error text if in an error state, else returns nulls
  */
  setError = () => {
    if (!this.state.hasError) return;

    return (
      <div
        className={
          this.state.showVerification
            ? 'text-danger error no-margin'
            : 'errorText'
        }
      >
        {this.state.errorText}
      </div>
    );
  };

  /*
    Handles form submission
  */
  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      isLoading: true,
      hasError: false,
      errorText: '',
    });
    try {
      if (!this.isFormValid()) {
        return this.setState({
          hasError: true,
          isLoading: false,
        });
      }

      const {
        email,
        firstName,
        lastName,
        phoneNumber,
        password,
        confirmPassword,
        company,
        country,
        countryCode,
      } = this.state;

      const body = {
        email,
        firstName,
        lastName,
        phoneNumber: sanitizeNumber(phoneNumber, countryCode),
        password,
        confirmPassword,
        company,
        country,
        countryCode,
      };

      // Make request to start Customer registration
      let error = await registerRequest.registerNewCustomer(
        body,
        this.props.errorHandler
      );
      // If no error, go to next state
      if (!error) {
        return this.setState({
          isLoading: false,
          hasError: false,
          showVerification: true,
          canResendCode: true,
          resendingCode: false,
          verifyingCode: false,
        });
      }

      return this.setState({
        hasError: true,
        errorText: error.error,
        isLoading: false,
      });
    } catch (e) {
      console.log('Have regi cust network error:', e);
      return this.setState({
        hasError: true,
        errorText: 'Network Error',
        isLoading: false,
      });
    }
  };

  countryDropDown = () => {
    let list = [];
    let countries = [
      { name: 'Australia', code: 'AU' },
      { name: 'Belgium', code: 'BE' },
      { name: 'Denmark', code: 'DK' },
      { name: 'Finland', code: 'FI' },
      { name: 'Ireland', code: 'IE' },
      { name: 'Mexico', code: 'MX' },
      { name: 'Netherlands', code: 'NL' },
      { name: 'New Zealand', code: 'NZ' },
      { name: 'Norway', code: 'NO' },
      { name: 'Singapore', code: 'SG' },
      { name: 'Sweden', code: 'SE' },
      { name: 'Switzerland', code: 'CH' },
      { name: 'United Kingdom', code: 'UK' },
      { name: 'U S A & Canada', code: 'US' },
    ];

    if (!this.state.country)
      list.push(
        <option disabled hidden key={null} value={'none'}>
          Please choose an option
        </option>
      );
    for (let i = 0; i < countries.length; i++) {
      list.push(
        <option
          key={countries[i].name}
          value={countries[i].name}
          code={countries[i].code}
        >
          {countries[i].name}
        </option>
      );
    }
    return (
      <Form.Control
        as="select"
        name="country"
        className="input-margin-bottom full-width"
        onChange={(e) => this.handleInputChange(e)}
        defaultValue={'none'}
      >
        {list}
      </Form.Control>
    );
  };

  productDropDown = () => {
    let list = [];
    let products = [
      'Text From Email',
      'Text From Web Browers',
      'Bulk Text',
      'Scheduled Texts',
      'Dedicated Numbers and Call Forwarding',
      '10DLC Registration Support',
      'Third-Party Integration',
      'Partner Program',
    ];

    if (!this.state.productOfInterest)
      list.push(
        <option disabled hidden key={null} value={'none'}>
          Please choose an option
        </option>
      );
    for (let i = 0; i < products.length; i++) {
      list.push(
        <option key={products[i]} value={products[i]}>
          {products[i]}
        </option>
      );
    }
    return (
      <Form.Control
        as="select"
        name="productOfInterest"
        className="input-margin-bottom full-width"
        onChange={(e) => this.handleInputChange(e)}
        defaultValue={'none'}
      >
        {list}
      </Form.Control>
    );
  };

  closeVerification = () => {
    this.setState({
      showVerification: false,
      hasError: false,
      errorText: '',
    });
  };

  showVerification = () => {
    this.setState({
      showVerification: true,
    });
  };

  /*
   handle verifying code and create new trial customer
  */
  handleVerification = async () => {
    this.setState({ hasError: false, errorText: '', verifyingCode: true });
    let { verificationCode, email, password } = this.state;
    if (!verificationCode) {
      return this.setState({
        hasError: true,
        errorText: 'Please enter the code.',
        verifyingCode: false,
      });
    }
    let body = {
      code: verificationCode,
      email: email,
      password: password,
    };
    //Send the verification code to backend and return the response
    let result = await registerRequest.verifyPhoneNumberCode(
      body,
      this.props.errorHandler
    );

    // Success
    if (!result) {
      this.setState({
        showVerification: false,
        hasRegistered: true,
      });

      // Failure
    } else {
      this.setState({
        hasError: true,
        errorText: result.error,
        verifyingCode: false,
      });
    }
  };

  /*
    handle resend verification code generation
  */
  handleResendCode = async () => {
    this.setState({ resendingCode: true, hasError: false, errorText: '' });
    let body = {
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
    };
    let result = await registerRequest.generateResendCode(
      body,
      this.props.errorHandler
    );
    if (!result) {
      this.setState({
        resendingCode: false,
      });
    } else {
      let stateChange = {
        hasError: true,
        errorText: result.error,
      };
      if (
        result.error ===
        'You have sent too many verification code requests. Please contact support.'
      )
        stateChange.canResendCode = false;
      // console.log('stateChange', stateChange);
      this.setState(stateChange);
    }
  };

  showVerificationDialog = () => {
    let resendLink = (
      <span className="resendCode" onClick={this.handleResendCode}>
        {formattedMessages.resend}
      </span>
    );

    if (!this.state.canResendCode) resendLink = null;
    else if (this.state.resendingCode)
      resendLink = <span>{formattedMessages.sending}</span>;

    return (
      <Modal
        show={this.state.showVerification}
        onHide={this.closeVerification}
        onExited={() => this.setState({ verifyingCode: false })}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{formattedMessages.verificationModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="no-margin">
            {formattedMessages.verificationModalBody}
            <b>{this.state.phoneNumber}</b>.
          </p>
          <br />
          <input
            type="text"
            name="verificationCode"
            placeholder="XXXXXX"
            onChange={this.handleInputChange}
          />
          <br />
          <br />
          <p className="no-margin">
            {formattedMessages.resendCodeText} {resendLink}
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          {this.state.showVerification ? this.setError() : null}
          {this.state.verifyingCode ? (
            <p className="flexRight">{formattedMessages.sending}</p>
          ) : (
            <Button
              className="maxHeight flexRight"
              variant="primary"
              onClick={this.handleVerification}
            >
              {formattedMessages.submit}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  /*
    Render the Register Form components based on state
  */
  renderForm() {
    let signupText = formattedMessages.signUpText;
    let signupLoadingText = formattedMessages.signUpLoadingText;
    let successText = formattedMessages.successText;
    let { password, confirmPassword, validPassword, validConfirmPassword } =
      this.state;

    // User has completed the Registration process
    if (this.state.hasRegistered) {
      return (
        <div className="registerSuccess">
          <h5>{successText}</h5>
          {formattedMessages.validateText}
          <br />
          <br />
          <br />
          <Link to="/">{formattedMessages.backToLogin}</Link>
        </div>
      );
    }
    // User has not completed the Registration process
    else {
      return (
        <Form
          onSubmit={this.handleSubmit}
          onChange={this.handleInputChange}
          className="registerForm"
        >
          <Form.Group>
            <table width="100%" className="no-table-row-background">
              <tbody>
                <tr valign="middle">
                  <td>
                    <b>{formattedMessages.firstNameText}</b>
                    <FormControl
                      type="text"
                      value={this.state.firstName}
                      name="firstName"
                      onChange={this.handleInputChange}
                    />
                  </td>
                  <td></td>
                  <td>
                    <b>{formattedMessages.lastNameText}</b>
                    <FormControl
                      type="text"
                      value={this.state.lastName}
                      name="lastName"
                      onChange={this.handleInputChange}
                    />
                  </td>
                </tr>
                <tr valign="middle">
                  <td>
                    <b>{formattedMessages.emailText} *</b>
                    <CustomTooltip
                      tooltipText={formattedMessages.workEmailText}
                      margin="5px"
                    />
                    <Form.Control
                      type="text"
                      value={this.state.emailText}
                      name="email"
                      onChange={this.handleInputChange}
                    />
                  </td>
                  <td></td>
                  <td>
                    <b>{formattedMessages.phoneNumberText}</b>
                    <CustomTooltip
                      tooltipText={formattedMessages.whitelistText}
                      margin="5px"
                    />
                    <Form.Control
                      type="text"
                      value={this.state.phoneNumber}
                      name="phoneNumber"
                      onChange={this.handleInputChange}
                    />
                  </td>
                </tr>
                <tr valign="middle">
                  <td>
                    <b>{'Password *'}</b>
                    <CustomTooltip
                      tooltipText={formattedMessages.passwordFormat}
                      margin="5px"
                    />
                    <OverlayTrigger
                      placement="bottom"
                      show={password && !validPassword}
                      overlay={
                        <Tooltip id="password-tooltip">
                          <p style={{ textAlign: 'left' }}>
                            Password is not strong enough.
                          </p>
                        </Tooltip>
                      }
                    >
                      <Form.Control
                        type="password"
                        value={password}
                        name="password"
                        className="input-margin-bottom"
                        autoComplete="new-password"
                        onChange={this.handleInputChange}
                        isInvalid={!validPassword}
                      />
                    </OverlayTrigger>
                  </td>
                  <td></td>
                  <td>
                    <b>{'Confirm Password *'}</b>
                    <OverlayTrigger
                      placement="bottom"
                      show={password !== '' && !validConfirmPassword}
                      overlay={
                        <Tooltip id="confirm-password-tooltip">
                          <p style={{ textAlign: 'left' }}>
                            {"Passwords don't match."}
                          </p>
                        </Tooltip>
                      }
                    >
                      <Form.Control
                        type="password"
                        value={confirmPassword}
                        className="input-margin-bottom"
                        name="confirmPassword"
                        autoComplete="new-password"
                        onChange={this.handleInputChange}
                        isInvalid={password !== '' && !validConfirmPassword}
                      />
                    </OverlayTrigger>
                  </td>
                </tr>
                <tr valign="middle">
                  <td>
                    <b>{formattedMessages.companyText}</b>
                    <Form.Control
                      type="text"
                      value={this.state.companyNameText}
                      name="company"
                      onChange={this.handleInputChange}
                    />
                  </td>
                  <td></td>
                  <td>
                    <b>{formattedMessages.countryText}</b>
                    <div>{this.countryDropDown()}</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <Form.Check name="signUpAgreement" type="checkbox" /> By signing up,
            I agree to{' '}
            <a
              href="https://redoxygen.com/service-agreement/"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://redoxygen.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <hr />
            <br />
            {this.state.showVerification ? null : this.setError()}
            <LoaderButton
              block
              bssize="large"
              disabled={!this.state.signUpAgreement}
              type="submit"
              isLoading={this.state.isLoading}
              text={signupText}
              loadingText={signupLoadingText}
            />
          </Form.Group>
        </Form>
      );
    }
  }

  render() {
    return (
      <div className="RegisterPage">
        <div className="customerRegister">
          <p>
            <div
              onClick={this.backToLogin}
              className="backLink backLinkForgotPassword"
            >
              <i className="material-icons">keyboard_arrow_left</i>
              <span>{formattedMessages.backToLogin}</span>
            </div>
            <div
              className="backLink linkContactSupport"
              onClick={this.contactSupport}
            >
              <span>{formattedMessages.contactSupport}</span>
              <i className="material-icons">keyboard_arrow_right</i>
            </div>
          </p>
          <div className="customerVerify-padding">{this.renderForm()}</div>
        </div>
        <br />
        {this.showVerificationDialog()}
      </div>
    );
  }
}
