// NPM MODULES
import { DateTime } from 'luxon';
import React, { Component } from 'react';
import FormattedMessages from './FormattedMessages';

export default class SentSmsSingle extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - errorLog: Contains the error log info to display
        - backButtonClick: Function to go back to List view
    */

    this.state = {};
  }

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="ProviderShow">
        <p
          onClick={() => this.props.backButtonClick(false)}
          className="backLink"
        >
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{FormattedMessages.back}</span>
        </p>
      </div>
    );
  };

  generateBody = () => {
    let { errorLog } = this.props;
    // Display error log information
    if (errorLog) {
      let failedDate = DateTime.fromISO(errorLog.created_at);

      // Convert to UTC
      const utcDateTime = failedDate.toUTC();

      // Creates shallow copy of error log object to remove the json_object
      let errorLogView = { ...errorLog };
      delete errorLogView.json_object;
      errorLogView = JSON.stringify(errorLogView, null, 2);

      return (
        <div>
          <h2>Error Logs Information</h2>
          <br />
          <b>{FormattedMessages.tableHeaderLogTime}</b>
          <br />
          <span>{utcDateTime.toFormat('dd-LLL-yyyy h:mm:ss a')}</span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderLogLevel}</b>
          <br />
          <span>{errorLog.log_level}</span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderLogGroupId}</b>
          <br />
          <span>{errorLog.log_group_id || 'N/A'}</span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderProcessName}</b>
          <br />
          <span>{errorLog.process_name}</span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderProgramName}</b>
          <br />
          <span>{errorLog.program_name}</span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderLogMessage}</b>
          <br />
          <span className="json-view">{errorLog.log_message}</span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderTag}</b>
          <br />
          <span>{errorLog.tag || 'N/A'}</span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderJson}</b>
          <br />
          <span className="json-view">
            {JSON.stringify(JSON.parse(errorLog.json_object), null, 2)}
          </span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderAllFields}</b>
          <br />
          <span className="json-view">{errorLogView}</span>
        </div>
      );
    }

    return <span> No Data </span>;
  };

  render() {
    return (
      <div className="Home">
        {this.generateLander()}
        {this.generateBody()}
      </div>
    );
  }
}
