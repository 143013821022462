import React, { Component } from 'react';
import FormattedMessage from './FormattedMessages';
import ListComponent from './ListComponent';
import ListItem from './ListItem';
import { Form } from 'react-bootstrap';
import BillingTabs from '../index';

// NETWORKING
import BillingsSummaryRequest from './BillingsSummaryRequest';

// INSTANTIATE
const billingsSummaryRequest = new BillingsSummaryRequest();
// NPM MODULES
const moment = require('moment');

class BillingsSummary extends Component {
  constructor(props) {
    super(props);
    let d = new Date();
    let thisMonth = d.getMonth() + 1;

    this.state = {
      list: null,
      totals: null,
      loading: true,
      pageCount: 0,
      selectedYear: d.getFullYear(),
      selectedMonth: thisMonth,
    };
  }

  componentDidMount = async () => {
    if (this.props.customer && this.props.customer !== null) {
      if (this.props.incomingData && this.props.incomingData.length !== 0) {
        console.log('Have incoming data:', this.props.incomingData);
        await this.setState({
          // data: this.props.incomingData.CHANGETHISVARIABLE,
          // dataExists: true
        });
        return this.formatData();
      } else {
        // else if no data, fetch initial set
        let { selectedMonth, selectedYear } = this.state;

        let result = await billingsSummaryRequest.getData(
          selectedMonth,
          selectedYear,
          this.props.errorHandler
        );
        console.log('got traffic in result:', result);
        this.setState(
          {
            data: result,
            dataExists: true,
          },
          this.formatData
        );
        // Save to index for instant retrieval
        // this.props.saveSessionData(result, 'trafficInData');
      }
    }
  };

  getNewData = async () => {
    this.setState({
      data: [],
      dataExists: false,
      loading: true,
    });
    let { selectedMonth, selectedYear } = this.state;
    console.log('about to get billing summary data:');
    let result = await billingsSummaryRequest.getData(
      selectedMonth,
      selectedYear,
      this.props.errorHandler
    );
    console.log('got traffic in result:', result);
    await this.setState(
      {
        data: result,
        dataExists: true,
      },
      this.formatData
    );
  };

  /*
  Formats the data in a manner required for the Table
  to properly display the data
  */
  formatData = async () => {
    try {
      let totals = {
        SetupFees: 0,
        Licence: 0,
        Messages: 0,
        Replies: 0,
        MessageFees: 0,
        TotalFees: 0,
        GST: 0,
        Surcharge: 0,
        Total: 0,
        SetupFeesDisplay: 0,
        LicenceDisplay: 0,
        MessageFeesDisplay: 0,
        TotalFeesDisplay: 0,
        GSTDisplay: 0,
        CCSurchargeDisplay: 0,
        TotalDisplay: 0,
      };

      if (!this.state.data) {
        return null;
      } else {
        let data = await this.state.data.map((el) => {
          el.bill_date = moment(el.bill_date).format('DD-MMM-YY');
          if (el.paid_date) {
            el.paid_date = moment(el.paid_date).format('DD-MMM-YY');
          }
          console.log('currency: ', el.plan_currency);
          let setupFees = new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: el.plan_currency,
          }).format(el.setup_fees);
          //el.licence_fees = parseFloat(Number(el.licence_fees).toFixed(2));
          let licenseFees = new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: el.plan_currency,
          }).format(el.licence_fees);
          //el.message_fees = parseFloat(Number(el.message_fees).toFixed(2));
          let messageFees = new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: el.plan_currency,
          }).format(el.message_fees);
          //el.total_ex_gst = parseFloat(Number(el.total_ex_gst).toFixed(2));
          let totalExGST = new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: el.plan_currency,
          }).format(el.total_ex_gst);
          //el.gst_total = parseFloat(Number(el.gst_total).toFixed(2));
          let gstTotal = new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: el.plan_currency,
          }).format(el.gst_total);
          //el.total_inc_gst = parseFloat(Number(el.total_inc_gst).toFixed(2));
          let surchargeTotal = new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: el.plan_currency,
          }).format(el.credit_card_surcharge);
          let totalIncGST = new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: el.plan_currency,
          }).format(el.total_inc_gst);
          return (
            <ListItem
              key={el.bill_id}
              id={el.customer_id}
              date={el.bill_date}
              invoiceNo={el.invoice_no}
              name={el.customer_name}
              COO={el.coo_flag}
              paidDate={el.paid_date}
              paymentType={el.payment_type}
              suffix={el.cc_number_suffix}
              setupFees={setupFees}
              licenceFees={licenseFees}
              messages={el.message_count}
              replies={el.reply_count}
              messageFees={messageFees}
              totalFees={totalExGST}
              gst={gstTotal}
              surcharge={surchargeTotal}
              total={totalIncGST}
            />
          );
        });

        // Add totals
        this.state.data.forEach((el) => {
          totals.SetupFees = totals.SetupFees + el.setup_fees;
          totals.Licence = totals.Licence + el.licence_fees;
          totals.Messages = totals.Messages + el.message_count;
          totals.Replies = totals.Replies + el.reply_count;
          totals.MessageFees = totals.MessageFees + el.message_fees;
          totals.TotalFees = totals.TotalFees + el.total_ex_gst;
          totals.GST = totals.GST + el.gst_total;
          totals.Surcharge = totals.Surcharge + el.credit_card_surcharge;
          totals.Total = totals.Total + el.total_inc_gst;
        });

        // Format totals
        totals.SetupFeesDisplay = new Intl.NumberFormat('en-AU', {
          style: 'currency',
          currency: 'AUD',
        }).format(totals.SetupFees);
        totals.LicenceDisplay = new Intl.NumberFormat('en-AU', {
          style: 'currency',
          currency: 'AUD',
        }).format(totals.Licence);
        totals.MessageFeesDisplay = new Intl.NumberFormat('en-AU', {
          style: 'currency',
          currency: 'AUD',
        }).format(totals.MessageFees);
        totals.TotalFeesDisplay = new Intl.NumberFormat('en-AU', {
          style: 'currency',
          currency: 'AUD',
        }).format(totals.TotalFees);
        totals.GSTDisplay = new Intl.NumberFormat('en-AU', {
          style: 'currency',
          currency: 'AUD',
        }).format(totals.GST);
        totals.CCSurchargeDisplay = new Intl.NumberFormat('en-AU', {
          style: 'currency',
          currency: 'AUD',
        }).format(totals.Surcharge);
        totals.TotalDisplay = new Intl.NumberFormat('en-AU', {
          style: 'currency',
          currency: 'AUD',
        }).format(totals.Total);
        console.log('totals.SetupFees');
        console.log(totals.SetupFees);

        // if (this.props.saveSessionData) {
        //     this.props.saveSessionData(this.state.data);
        // }
        console.table(this.state.data);
        this.setState({
          list: data,
          totals: totals,
          loading: false,
        });
        // return this.setState(updatedState);
      }
    } catch (e) {
      console.log('data error:', e);
    }
  };

  changeEvent = (o) => {
    console.log(o);
  };
  handlePagination = (page) => {};

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value,
      },
      this.getNewData
    );
  }

  reloadPage = () => {
    window.location.reload(1);
  };

  generateYearMonthSelector = () => {
    let listYear = [];
    const date = new Date();
    let year = date.getFullYear();
    for (let i = 0; i <= 10; i++) {
      let selectedYear = year - i;
      listYear.push(<option value={selectedYear}>{selectedYear}</option>);
    }
    return (
      <div className="floatRight">
        <Form id="filter-form">
          <table className="no-table-row-background">
            <tbody className="filter-table">
              <div className="filter">
                <div className="filter-item">
                  <td>
                    <Form.Label className="no-margin">Month: </Form.Label>
                  </td>
                  <td>
                    <div>
                      <Form.Control
                        as="select"
                        name="selectedMonth"
                        value={this.state.selectedMonth}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value="1">
                          {FormattedMessage.January.props.defaultMessage.toString()}
                        </option>
                        <option value="2">
                          {FormattedMessage.February.props.defaultMessage.toString()}
                        </option>
                        <option value="3">
                          {FormattedMessage.March.props.defaultMessage.toString()}
                        </option>
                        <option value="4">
                          {FormattedMessage.April.props.defaultMessage.toString()}
                        </option>
                        <option value="5">
                          {FormattedMessage.May.props.defaultMessage.toString()}
                        </option>
                        <option value="6">
                          {FormattedMessage.June.props.defaultMessage.toString()}
                        </option>
                        <option value="7">
                          {FormattedMessage.July.props.defaultMessage.toString()}
                        </option>
                        <option value="8">
                          {FormattedMessage.August.props.defaultMessage.toString()}
                        </option>
                        <option value="9">
                          {FormattedMessage.September.props.defaultMessage.toString()}
                        </option>
                        <option value="10">
                          {FormattedMessage.October.props.defaultMessage.toString()}
                        </option>
                        <option value="11">
                          {FormattedMessage.November.props.defaultMessage.toString()}
                        </option>
                        <option value="12">
                          {FormattedMessage.December.props.defaultMessage.toString()}
                        </option>
                      </Form.Control>
                    </div>
                  </td>
                </div>
                <div className="filter-item">
                  <td>
                    <Form.Label className="no-margin">Year:</Form.Label>
                  </td>
                  <td>
                    <div>
                      <Form.Control
                        as="select"
                        name="selectedYear"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        {listYear}
                      </Form.Control>
                    </div>
                  </td>
                </div>
              </div>
            </tbody>
          </table>
        </Form>
      </div>
    );
  };

  generateList = () => {
    if (this.state.loading) {
      return <span>{FormattedMessage.loading}</span>;
    } else {
      return (
        <div className="list-container">
          <ListComponent
            changePage={this.changePage}
            totals={this.state.totals}
            list={this.state.list}
            handlePagination={this.handlePagination}
            pageCount={this.state.pageCount}
          />
        </div>
      );
    }
  };

  render() {
    let { accessLevel, accessType, acting_names } = this.props;

    if (accessLevel === '3' && accessType === '3') {
      return (
        <BillingTabs
          tab="summary"
          accessLevel={accessLevel}
          accessType={accessType}
        >
          <div className="Home">
            <div className="lander">
              {this.generateYearMonthSelector()}
              <br />
              <h3>{FormattedMessage.billingsSummaryTitle}</h3>
              Provider: {acting_names?.provider_name}
              <br />
              <br />
            </div>
            {this.generateList()}
          </div>
        </BillingTabs>
      );
    }
    return null;
  }
}

export default BillingsSummary;
