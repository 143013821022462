// NPM
import React, { Component, Fragment } from 'react';
import { Button, Form, Pagination, Modal } from 'react-bootstrap';
import CustomTooltip from '../components/CustomTooltip';
// STYLING
import './Paginator.css';

// CONFIG
import Config from '../Config';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';

export default class Paginator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      list: [],
      activePage: props.pageNumber || 0,
      pages: [],
      pagination: null,
      pagModal: false,
      typedPage: null,
      pageCount: 0,
    };
    this.changePage = this.changePage.bind(this);
  }

  componentDidMount() {
    // If prop exists, sets the parent component's local function (e.g. resetFuncion) to the paginator's changePage function
    if (this.props.changePageFunction)
      this.props.changePageFunction(this.changePage);
  }

  /*
    Generates the page count
  */
  handlePageCount = () => {
    if (!this.props.itemCount) return 0;
    // Uses pageLength passed in as props. If that doesn't exist, uses Config.defaultPL.
    let pageCount = Math.ceil(
      this.props.itemCount /
        (this.props.pageLength ? this.props.pageLength : Config.defaultPL)
    );
    return pageCount;
  };

  // OnClick event for arrow steppers
  handleSteppers = async (target) => {
    let pageCount = this.handlePageCount();
    let { activePage } = this.state;
    let page = activePage;
    switch (target) {
      case 'First':
        page = 0;
        break;
      case 'Prev':
        if (page !== 0) {
          page = page - 1;
        }
        break;
      case 'Next':
        if (page !== pageCount - 1) {
          page = page + 1;
        }
        break;
      case 'Last':
        page = pageCount - 1;
        break;
      default:
        page = activePage;
    }
    await this.setState({
      activePage: page,
    });
    // incase selecting first/last entry
    this.canContinue();
  };
  // OnClick event for number buttons in pagination
  handleNumber = async (i) => {
    let a = document.getElementById('page_' + i);
    let b = document.getElementById('page_' + this.state.activePage);
    if (a && a.parentElement && a.parentElement.classList) {
      a.parentElement.classList.add('active');
    }
    if (b && b.parentElement && b.parentElement.classList) {
      b.parentElement.classList.remove('active');
    }
    if (this.props.changeEvent) {
      this.returnPaginationData(i);
    }
    await this.setState({
      activePage: i,
    });
    // incase selecting first/last entry
    this.canContinue();
  };

  returnPaginationData = (i) => {
    this.props.changeEvent(i);
  };

  // Handles activating/disabling the stepper buttons for Pagination, stops from going over/under the available amount of pages
  canContinue = () => {
    // function is used every time the active number is changed, add on change event handler here
    if (
      this.props.changeEvent !== undefined &&
      this.props.changeEvent !== null
    ) {
      this.returnPaginationData(this.state.activePage);
    }
  };

  // Handles the construction of buttons for pagination
  handlePageButtons = () => {
    let pages = [];
    let pageCount = this.handlePageCount();
    let { activePage } = this.state;
    // if there are less than 6 entries, only show page numbers, no need to concat the count for any fewer
    if (pageCount <= 5) {
      for (let i = 0; i < pageCount; i++) {
        pages.push(
          <Pagination.Item
            className="pagination-item"
            key={'page_' + i.toString()}
            id={'page_' + i.toString()}
            onClick={() => this.handleNumber(i)}
            active={i === activePage}
          >
            {i + 1}
          </Pagination.Item>
        );
      }
    } else if (activePage <= 2) {
      // within the first 3 entries, have the active highlight move and ellipsis at the end to indicate more entries
      for (let i = 0; i < 4; i++) {
        pages.push(
          <Pagination.Item
            className="pagination-item"
            key={'page_' + i.toString()}
            id={'page_' + i.toString()}
            onClick={() => this.handleNumber(i)}
            active={i === activePage}
          >
            {i + 1}
          </Pagination.Item>
        );
      }
      pages.push(
        <Pagination.Ellipsis
          className="pagination-ellipsis"
          key={'page_ellipsis_1'}
          onClick={this.handlePageModal}
        />
      );
    } else if (activePage + 3 >= pageCount) {
      // when 3 away from the end, remove trailing ellipsis and move active highlight to indicate no more pages
      pages.push(
        <Pagination.Ellipsis
          className="pagination-ellipsis"
          key={'page_ellipsis_2'}
          onClick={this.handlePageModal}
        />
      );
      for (let i = pageCount - 4; i < pageCount; i++) {
        pages.push(
          <Pagination.Item
            className="pagination-item"
            key={'page_' + i.toString()}
            id={'page_' + i.toString()}
            onClick={() => this.handleNumber(i)}
            active={i === activePage}
          >
            {i + 1}
          </Pagination.Item>
        );
      }
    } else {
      // after the 4th entry and before the end, start shifting the numbers, keeping the active highlight in the middle
      let i = activePage;
      pages.push(
        <Pagination.Ellipsis
          className="pagination-ellipsis"
          key={'page_ellipsis_1'}
          onClick={this.handlePageModal}
        />
      );
      pages.push(
        <Pagination.Item
          className="pagination-item"
          key={'page_' + (i - 1).toString()}
          id={'page_' + (i - 1).toString()}
          onClick={() => this.handleNumber(i - 1)}
          active={i - 1 === activePage}
        >
          {i}
        </Pagination.Item>
      );
      pages.push(
        <Pagination.Item
          className="pagination-item"
          key={'page_' + i.toString()}
          id={'page_' + i.toString()}
          onClick={() => this.handleNumber(i)}
          active={i === activePage}
        >
          {i + 1}
        </Pagination.Item>
      );
      pages.push(
        <Pagination.Item
          className="pagination-item"
          key={'page_' + (i + 1).toString()}
          id={'page_' + (i + 1).toString()}
          onClick={() => this.handleNumber(i + 1)}
          active={i + 1 === activePage}
        >
          {i + 2}
        </Pagination.Item>
      );
      pages.push(
        <Pagination.Ellipsis
          className="pagination-ellipsis"
          key={'page_ellipsis_2'}
          onClick={this.handlePageModal}
        />
      );
    }
    return pages;
  };
  // OnClick event for pagination ellipsis
  handlePageModal = (clearValues) => {
    let show = this.state.pagModal === true ? false : true;
    this.setState({
      pagModal: show,
    });
    if (clearValues) this.setState({ typedPage: null });
  };
  // handles the typing event in the modal
  handleChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (this.checkNumber(value)) {
      this.setState({
        typedPage: value,
      });
    } else {
      this.setState({
        typedPage: null,
      });
    }
  }

  checkNumber(value) {
    var regex = /[^0-9]+/;
    if (regex.test(value.toString())) return false;
    else if (
      value === '' ||
      value === undefined ||
      value === '0' ||
      value === null
    )
      return false;
    return true;
  }

  // Function designed to be called by parent components
  changePage(page = 0) {
    this.setState({
      activePage: page,
    });
  }

  // sanitizes the input for the modal, making sure that the entry is within the upper/lower boundaries
  handlePageSelect = async () => {
    let pageCount = this.handlePageCount();
    let i = this.state.typedPage;
    if (i) {
      if (i > pageCount || i < 1) return;
      await this.setState({
        pagModal: false,
        activePage: i - 1,
        typedPage: null,
      });
      this.canContinue();
    }
  };

  /**
   * onChange event for the edit dropdown.
   * @param {event} e
   */
  onPageLengthChanged = async (e) => {
    e.persist();
    await this.setState({ activePage: 0 });
    this.props.editFunction(e);
    this.canContinue();
  };

  /**
   * renders the dropdown that allows user to change the number of entries displayed per page.
   * @returns JSX for the dropdown
   */
  renderEditDropdown = () => {
    // only works if an editFunction is passed in as a props.
    if (this.props.editFunction) {
      return (
        <div>
          <Form.Control
            as="select"
            defaultValue={this.props.pageLength ? this.props.pageLength : 10}
            onChange={(e) => this.onPageLengthChanged(e)}
            name="editPage"
            className="pagination-dropdown"
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Control>
        </div>
      );
    }
    return null;
  };

  render() {
    const { activePage } = this.state;
    let pageCount = this.handlePageCount();
    let pages = this.handlePageButtons();

    // If no page count, do not display paginator
    if (pageCount === 0) return null;

    // Active and disabled classes for the pagination steppers
    const activeClass = 'pagination-steppers';
    const disabledClass = activeClass + ' disabled';

    // Boolean that is true if this is the last page
    const lastPage = activePage === pageCount - 1;

    return (
      <Fragment>
        <Pagination className="align-item-centre">
          <Pagination.First
            className={activePage === 0 ? disabledClass : activeClass}
            name="First"
            id="First"
            onClick={() => this.handleSteppers('First')}
          />
          <Pagination.Prev
            className={activePage === 0 ? disabledClass : activeClass}
            name="Prev"
            id="Prev"
            onClick={() => this.handleSteppers('Prev')}
          />
          {pages}
          <Pagination.Next
            className={lastPage ? disabledClass : activeClass}
            name="Next"
            id="Next"
            onClick={() => this.handleSteppers('Next')}
          />
          <Pagination.Last
            className={lastPage ? disabledClass : activeClass}
            name="Last"
            id="Last"
            onClick={() => this.handleSteppers('Last')}
          />
          {this.renderEditDropdown()}
          {this.props.showTotal && (
            <div>
              &nbsp;&nbsp;{this.props.showTotal}: {this.props.itemCount}
            </div>
          )}
          {this.props.toolTip && (
            <CustomTooltip
              tooltipText={this.props.toolTip}
              margin="5px"
              className="tooltip-Record"
            />
          )}
        </Pagination>
        <Modal
          centered
          show={this.state.pagModal}
          onHide={() => this.handlePageModal(true)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Page between 1 &amp; {pageCount}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="flex-modal">
            <Form.Control
              onChange={(e) => this.handleChange(e)}
              name="typedPage"
              type="number"
              className="auto"
              max={pageCount}
              min={1}
            />
            <Button onClick={this.handlePageSelect} className="maxHeight">
              {formattedMessages.go}
            </Button>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}
