import React, { Fragment, useState } from 'react';
import { Form } from 'react-bootstrap';
import DateRangeSelector from '../../../../components/DateRangeSelector';
import FormattedMessage from '../../FormattedMessages';

/* Generates the Search Tools for querying data */
const generateSearchTools = ({
  clearFilters,
  accessLevel,
  accessType,
  handleInputChange,
  handleUserDataSearch,
  customerTypeFilterDropdown,
  statusFilterDropdown,
  currentlySearching,
  selectorKeyRefresh,
}) => {
  const [open, setOpen] = useState(false);

  const statusDropdown = () => {
    const list = [];
    const invoiceStatus = [
      'All',
      'Invoiced',
      'Paid',
      'Failed',
      'Pending',
      'Paid - Manual',
      'Refund',
      'Cancelled',
    ];

    if (!statusFilterDropdown)
      list.push(
        <option disabled selected hidden key={null} value>
          Select Status
        </option>
      );

    for (let i = 0; i < invoiceStatus.length; i++) {
      list.push(
        <option key={invoiceStatus[i]} value={invoiceStatus[i]}>
          {invoiceStatus[i]}
        </option>
      );
    }
    return (
      <Form>
        <Form.Control
          as="select"
          name="statusFilter"
          className="filter-control"
          onChange={(e) => handleInputChange(e)}
        >
          {list}
        </Form.Control>
      </Form>
    );
  };

  // Generates the dropdown for customer type filter
  const customerTypeDropdown = () => {
    const list = [
      <option key={'pre-paid'} value={'B'}>
        {'Pre-paid'}
      </option>,
      <option key={'post-paid'} value={'A'}>
        {'Post-paid'}
      </option>,
    ];

    if (!customerTypeFilterDropdown)
      list.push(
        <option disabled selected hidden key={'no-type'} value>
          Select type
        </option>
      );

    return (
      <Form>
        <Form.Control
          as="select"
          name="customerTypeFilter"
          className="filter-control"
          onChange={(e) => handleInputChange(e)}
        >
          {list}
        </Form.Control>
      </Form>
    );
  };

  const isGod = accessLevel === '3' && accessType === '3';
  const providerContent = isGod && (
    <div className="filter-item">
      <td>
        <Form.Label className="searchRange invoice-filter">
          Provider:
        </Form.Label>
      </td>
      <td>
        <div>
          <Form.Control
            type="text"
            name="providerFilter"
            id="providerFilter"
            className="filter-control"
            onChange={(e) => handleInputChange(e)}
            placeholder="Search Provider"
          />
        </div>
      </td>
    </div>
  );
  const customerContent = isGod && (
    <div className="filter-item">
      <td>
        <Form.Label className="searchRange invoice-filter">
          Customer:
        </Form.Label>
      </td>
      <td>
        <div>
          <Form.Control
            type="text"
            name="customerFilter"
            id="customerFilter"
            className="filter-control"
            onChange={(e) => handleInputChange(e)}
            placeholder="Search Customer"
          />
        </div>
      </td>
    </div>
  );
  const currencyContent = isGod && (
    <div className="filter-item">
      <td>
        <Form.Label className="searchRange invoice-filter">
          Currency:
        </Form.Label>
      </td>
      <td>
        <div>
          <Form.Control
            type="text"
            name="currencyFilter"
            id="currencyFilter"
            className="filter-control"
            onChange={(e) => handleInputChange(e)}
            placeholder="Search Customer"
          />
        </div>
      </td>
    </div>
  );

  // Destructures which filters the table is currently displaying fields on.
  let {
    providerFilter,
    invoiceFilter,
    customerFilter,
    statusFilter,
    currencyFilter,
    customerTypeFilter,
  } = currentlySearching || {};

  let filterText = [];
  let values = [];
  values = ['Invoice:', 'Status:'];
  let filterArray = [invoiceFilter, statusFilter];
  if (isGod) {
    let typeFilterText = '';
    if (customerTypeFilter === 'A') typeFilterText = 'Post-paid';
    if (customerTypeFilter === 'B') typeFilterText = 'Pre-paid';

    values = [
      'Provider:',
      'Customer:',
      'Invoice No:',
      'Status:',
      'Currency:',
      'Type:',
    ];
    filterArray = [
      providerFilter,
      customerFilter,
      invoiceFilter,
      statusFilter,
      currencyFilter,
      typeFilterText,
    ];
  }

  // Pushes spans to an array for rendering. Iterates to avoid code duplication.
  filterArray.map((filter, i) => {
    if (filter)
      filterText.push(
        <span>
          {filterText.length === 0 ? '' : '; '} <b>{values[i]}</b> {filter}
        </span>
      );
  });

  return (
    <Fragment>
      <DateRangeSelector
        key={selectorKeyRefresh ? 1 : 0} // Changing the key remounts it
        handleDateSearch={(fromDate, toDate, timeRange) => {
          handleUserDataSearch(fromDate, toDate, timeRange);
        }}
      ></DateRangeSelector>

      <div className="Home">
        <div className="lander">
          <br />
          <span className="showHideLink-left">
            <span className="advanced-filters" onClick={() => setOpen(!open)}>
              {open ? (
                <i className="material-icons">keyboard_arrow_down</i>
              ) : (
                <i className="material-icons">keyboard_arrow_right</i>
              )}
              Advanced Filters
            </span>

            <div className="clear-filters" onClick={clearFilters}>
              {FormattedMessage.clear}
            </div>
          </span>

          {open && (
            <div>
              <p className="no-margin">{FormattedMessage.filterNote}</p>
              <Form id="filter-form">
                <table className="no-table-row-background">
                  <tbody className="filter-table">
                    <div className="filter">
                      {providerContent}
                      <div className="filter-item">
                        <td>
                          <Form.Label className="searchRange invoice-filter">
                            Invoice No:
                          </Form.Label>
                        </td>
                        <td>
                          <div>
                            <Form.Control
                              type="text"
                              name="invoiceFilter"
                              id="invoiceFilter"
                              className="filter-control"
                              onChange={(e) => handleInputChange(e)}
                              placeholder="Search Invoice No"
                            />
                          </div>
                        </td>
                      </div>
                      {customerContent}
                      <div className="filter-item">
                        <td>
                          <Form.Label className="searchRange invoice-filter">
                            Status:
                          </Form.Label>
                        </td>
                        <td>
                          <div>{statusDropdown()}</div>
                        </td>
                      </div>
                      {currencyContent}
                      {isGod && (
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange invoice-filter">
                              Type:
                            </Form.Label>
                          </td>
                          <td>
                            <div>{customerTypeDropdown()}</div>
                          </td>
                        </div>
                      )}
                    </div>
                  </tbody>
                </table>
              </Form>

              <br></br>
            </div>
          )}
          <div className="flex-between">
            {filterText.length > 0 && <div>{filterText}</div>}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default generateSearchTools;
