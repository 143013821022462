import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  emailText: (
    <FormattedMessage
      id="RegisterIndex.EmailLabel"
      defaultMessage="Email"
    ></FormattedMessage>
  ),
  firstNameText: (
    <FormattedMessage
      id="RegisterIndex.FirstNameLabel"
      defaultMessage="First Name *"
    ></FormattedMessage>
  ),
  lastNameText: (
    <FormattedMessage
      id="RegisterIndex.LastNameLabel"
      defaultMessage="Last Name *"
    ></FormattedMessage>
  ),
  phoneNumberText: (
    <FormattedMessage
      id="RegisterIndex.PhoneNumberLabel"
      defaultMessage="Phone number *"
    ></FormattedMessage>
  ),
  companyText: (
    <FormattedMessage
      id="RegisterIndex.CompanyLabel"
      defaultMessage="Company *"
    ></FormattedMessage>
  ),
  countryText: (
    <FormattedMessage
      id="RegisterIndex.CountryLabel"
      defaultMessage="Country *"
    ></FormattedMessage>
  ),
  productText: (
    <FormattedMessage
      id="RegisterIndex.ProductLabel"
      defaultMessage="Product Of Interest"
    ></FormattedMessage>
  ),
  extraInfoText: (
    <FormattedMessage
      id="RegisterIndex.extraInfoLabel"
      defaultMessage="Anything else you think we should know?"
    ></FormattedMessage>
  ),
  signUpText: (
    <FormattedMessage
      id="RegisterIndex.SignupButton"
      defaultMessage="Signup"
    ></FormattedMessage>
  ),
  signUpLoadingText: (
    <FormattedMessage
      id="RegisterIndex.SignupLoadingText"
      defaultMessage="Signing up..."
    ></FormattedMessage>
  ),
  successText: (
    <FormattedMessage
      id="RegisterIndex.SuccessLabel"
      defaultMessage="Success"
    ></FormattedMessage>
  ),
  backToLogin: (
    <FormattedMessage
      id="RegisterIndex.BackToLogin"
      defaultMessage="Back To Login"
    ></FormattedMessage>
  ),
  contactSupport: (
    <FormattedMessage
      id="RegisterIndex.ContactSupport"
      defaultMessage="Contact Support"
    ></FormattedMessage>
  ),
  validateText: (
    <FormattedMessage
      id="RegisterIndex.validateText"
      defaultMessage="You have successfully registered for an account with Red Oxygen."
    ></FormattedMessage>
  ),
  whitelistText: (
    <FormattedMessage
      id="RegisterIndex.whitelistText"
      defaultMessage="This is the only number you'll be able to send SMS during your trial."
    ></FormattedMessage>
  ),
  workEmailText: (
    <FormattedMessage
      id="RegisterIndex.workEmailText"
      defaultMessage="Email with business domains could be eligible for additional trial credits."
    ></FormattedMessage>
  ),
  verificationModalTitle: (
    <FormattedMessage
      id="RegisterIndex.VerificationModalTitle"
      defaultMessage="Verification Required"
    ></FormattedMessage>
  ),
  verificationModalBody: (
    <FormattedMessage
      id="RegisterIndex.VerificationModalBody"
      defaultMessage="To complete the process, please enter the 6-digit verification code sent to "
    ></FormattedMessage>
  ),
  resendCodeText: (
    <FormattedMessage
      id="RegisterIndex.ResendCodeText"
      defaultMessage="Didn't receive a code?"
    ></FormattedMessage>
  ),
  resend: (
    <FormattedMessage
      id="RegisterIndex.Resend"
      defaultMessage="Resend."
    ></FormattedMessage>
  ),
  sending: (
    <FormattedMessage
      id="RegisterIndex.Sending"
      defaultMessage="Sending..."
    ></FormattedMessage>
  ),
  submit: (
    <FormattedMessage
      id="RegisterIndex.Submit"
      defaultMessage="Submit"
    ></FormattedMessage>
  ),
  errorFirstName: (
    <FormattedMessage
      id="RegisterIndex.ErrorFirstName"
      defaultMessage="Requires a first name."
    />
  ),
  errorLastName: (
    <FormattedMessage
      id="RegisterIndex.ErrorLastName"
      defaultMessage="Requires a last name."
    />
  ),
  errorEmailCheck: (
    <FormattedMessage
      id="RegisterIndex.ErrorEmailCheck"
      defaultMessage="The email provided is not in a valid format."
    />
  ),
  errorMissingPhoneNumber: (
    <FormattedMessage
      id="RegisterIndex.errorMissingPhoneNumber"
      defaultMessage='Requires a valid phone number. Should only contain numbers and "+", eg: +0123456789/0123456789.'
    />
  ),
  errorPhoneNumber: (
    <FormattedMessage
      id="RegisterIndex.ErrorPhoneNumber"
      defaultMessage='Incorrect phone number format. Should only contain numbers and "+", eg: +0123456789/0123456789.'
    />
  ),
  errorCompany: (
    <FormattedMessage
      id="RegisterIndex.ErrorCompany"
      defaultMessage="Requires a company name."
    />
  ),
  errorPassword: (
    <FormattedMessage
      id="RegisterIndex.ErrorPassword"
      defaultMessage="Requires a password in both password fields."
    />
  ),
  errorCheckPasswordStrong: (
    <FormattedMessage
      id="RegisterIndex.ErrorCheckPasswordStrong"
      defaultMessage="Password is not strong enough. Please ensure it is 8 or more characters long and contains at least one uppercase and one lowercase letter, a number and a symbol."
    />
  ),
  errorPasswordMatch: (
    <FormattedMessage
      id="RegisterIndex.ErrorPasswordMatch"
      defaultMessage="Passwords don't match."
    />
  ),
  errorCountry: (
    <FormattedMessage
      id="RegisterIndex.ErrorCountry"
      defaultMessage="Requires a country."
    />
  ),
  passwordFormat: (
    <FormattedMessage
      id="RegisterIndex.PasswordFormat"
      defaultMessage="The password must be 8 or more characters long and contains at least one uppercase and one lowercase letter, a number and a symbol."
    />
  ),
};

export default FormattedMessages;
