// Require `PhoneNumberFormat`.
const PNF = require('google-libphonenumber').PhoneNumberFormat;

// Get an instance of `PhoneNumberUtil`.
const phoneUtil =
  require('google-libphonenumber').PhoneNumberUtil.getInstance();

/**
 * Checks if the phone number matches the format(+0123456789/0123456789):
 * - Minimum length of 4
 * - Maximum length of 15
 * - Allow any digit from 0 to 9 and an optional '+' sign at the start
 * - Not allow any letter, white space or other symbols
 * @returns True if the userTn prefix matches the format
 */
export function validatePhoneNumber(userTn) {
  if (!userTn || userTn === '') return false;
  let regExPattern = /^(\+?\d{4,15})$/;
  return regExPattern.test(userTn);
}

export function stripNonNumerical(inputString) {
  if (!inputString || inputString === '') {
    return '';
  }
  // Use a regular expression to match non-numerical characters (except '+')
  const strippedString = inputString.replace(/[^0-9+]/g, '');

  return strippedString;
}

export function validateNumberForCountry(phoneNumber, region) {
  console.log(`phone ${phoneNumber} and region ${region}`);
  let number;

  try {
    number = phoneUtil.parseAndKeepRawInput(phoneNumber, region);
  } catch (error) {
    return false;
  }

  if (region === 'US')
    return (phoneUtil.isValidNumberForRegion(number, 'US') || phoneUtil.isValidNumberForRegion(number, 'CA')); 

  if (phoneUtil.isValidNumberForRegion(number, region)) {
    return true;
  }
}

export function sanitizeNumber(phoneNumber, region) {
  const number = phoneUtil.parseAndKeepRawInput(phoneNumber, region);
  return phoneUtil.format(number, PNF.E164);
}

/*
    Takes a string and tests whether it meets the following password criteria:
      - At least one capital letter
      - At least one lowercase letter
      - At least one symbol
      - At least one number
      - Minimum length of 8 characters
  */
export function validatePassword(str) {
  if (!str || str === '') return false;
  let regExPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[`~!@#$%^&*()_|+=?;:'",.<>{/\\[\]}])(?=.{8,})/;
  return regExPattern.test(str);
}

export function validateName(name) {
  if (!name || (name && name.trim() === '')) return false;
  return true;
}

/*Checks whether the Max SMS per day has only numbers */
export function validateSms(max_sms_per_day) {
  if (!max_sms_per_day || max_sms_per_day === '') return false;
  let regExPattern = /^[0-9]*$/;
  return regExPattern.test(max_sms_per_day);
}

/*
    Basic email validation on client side
    Taken from: https://stackoverflow.com/a/46181/8191495
  */
export function validateEmail(email) {
  const regExPattern =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExPattern.test(email);
}
