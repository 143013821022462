/*
  WebSMS Users index.js - WebSMS Users Main Component File

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Button } from 'react-bootstrap';

// COMPONENTS
import Add from './Add';
import Edit from './Edit';
import List from './List';
import UsersTabs from '../index';

// I18N
import formattedMessages from './FormattedMessages';

// NETWORK
import UserRequest from './UserRequest';
import CostCentreRequest from '../CostCentres/CostCentreRequest';

const userRequest = new UserRequest();
const costCentreRequest = new CostCentreRequest();

export default class WebSMSUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      stv: false,
      selected: null,
      selectedIndex: null,
      addNew: false,
      options: [],
      loaded: false,
      pageNumber: 1,
      filterLetter: '',
    };
  }

  async componentDidMount() {
    if (this.props.customer && this.props.customer !== null) {
      // Get Users data save
      let users = await userRequest.getUsers(this.props.errorHandler);
      let costCentres = await costCentreRequest.getCentres(
        this.props.errorHandler
      );

      let sortedUsers = await this.sortList(users);
      let letters = await this.sortLetters(sortedUsers);

      // Strictly for redirects to single users
      if (this.props.location.state?.selected) {
        const { selected } = this.props.location.state;
        console.log('redirect selected:', selected);

        // Finds the redirect's "selected user" within the list of users found
        const index = sortedUsers.findIndex(
          (el) => el?.customer_user_id === selected
        );

        // if customer user entry found for redirect user id
        if (index !== -1) {
          this.setState({
            selected: sortedUsers[index],
            stv: true,
          });
        }
      }

      this.setState({
        users: sortedUsers,
        costCentres: costCentres,
        letters: letters,
        loaded: true,
      });
    }
  }

  sortList(data) {
    //sorts alphabetically
    let sortedList = data.sort(function (a, b) {
      if (a.user_name.toLowerCase() < b.user_name.toLowerCase()) {
        return -1;
      }
      if (a.user_name.toLowerCase() > b.user_name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    //Sort by status
    let filtered = [];
    //add active
    for (let i = 0; i < sortedList.length; i++) {
      if (
        sortedList[i].user_status.toLowerCase() === 'a' ||
        sortedList[i].user_status.toLowerCase() === 'active'
      ) {
        filtered.push(sortedList[i]);
      }
    }
    for (let i = 0; i < sortedList.length; i++) {
      if (
        sortedList[i].user_status.toLowerCase() === 'c' ||
        sortedList[i].user_status.toLowerCase() === 'cancelled'
      ) {
        filtered.push(sortedList[i]);
      }
    }

    return filtered;
  }

  sortLetters = async (users) => {
    let letters = [];
    users.forEach((user) => {
      if (!letters.includes(user.user_name.toUpperCase().charAt(0))) {
        letters.push(user.user_name.toUpperCase().charAt(0));
      }
    });
    return letters.sort();
  };

  handleAddNew = () => {
    return this.setState({
      addNew: true,
    });
  };

  /*
    Handles receiving data back from List - ListItem click
  */
  selectUser = (user) => {
    if (!user) {
      return this.setState({
        stv: true,
        selected: null,
      });
    }

    return this.setState({
      stv: true,
      selected: user,
    });
  };

  /*
    Handles when a back button is clicked in one of the child components
  */
  handleBackClick = async (refreshFlag) => {
    let defaultState = {
      stv: false,
      addNew: false,
    };

    if (refreshFlag) {
      let data = await userRequest.getUsers(this.props.errorHandler);
      let sortedUsers = this.sortList(data);
      let letters = await this.sortLetters(sortedUsers);
      defaultState.users = sortedUsers;
      defaultState.letters = letters;
    }

    // Return to default state
    this.setState(defaultState, () =>
      this.resetFunction(this.state.pageNumber - 1)
    );
  };

  /*
    Generates a 'letter filter' component that allows a User to filter other Users who have the same first letter
    as the one they select.
  */
  generateFirstLetterFilter = () => {
    // NOTE: Extra space in end </span> is required to space letters out on display
    let letterHtml = this.state.letters.map((el) => {
      return (
        <span key={el}>
          <span
            key={el}
            className={
              this.state.filterLetter === el ? 'clicked-object' : 'click-object'
            }
            onClick={() => this.handleLetterFilter(el)}
          >
            {el}
          </span>{' '}
        </span>
      );
    });
    return <div>{letterHtml}</div>;
  };

  changeEvent = (i) => {
    return this.setState({
      pageNumber: i + 1,
    });
  };

  /*
    Handles click from the 'letter filter' component.
  */
  handleLetterFilter = async (letter) => {
    if (letter === this.state.filterLetter) {
      this.setState({
        filterLetter: '',
      });
    } else {
      this.setState({
        filterLetter: letter,
      });
    }
    this.resetFunction();
    this.changeEvent(0);
  };

  /*
    Generates the main part of the Users Component
  */
  generateBody = () => {
    let { stv, selected, addNew, pageNumber, filterLetter } = this.state;
    let { accessLevel, accessType } = this.props;

    // Add User
    if (addNew) {
      return (
        <Add
          accessLevel={accessLevel}
          accessType={accessType}
          callBack={this.handleBackClick}
          errorHandler={this.props.errorHandler}
        />
      );
    }

    // Edit User
    if (stv) {
      return (
        <span className="singleView">
          <Edit
            accessLevel={accessLevel}
            accessType={accessType}
            callBack={this.handleBackClick}
            user={selected}
            errorHandler={this.props.errorHandler}
          />
        </span>
      );
    }

    // List Users
    return (
      <Fragment>
        <div className="flex">
          <h3>{formattedMessages.webSMSUsers}</h3>
          <Button
            className="d-inline flexRight btn add-new-template"
            onClick={() => this.handleAddNew()}
          >
            {formattedMessages.addNew}
          </Button>
        </div>
        <div className="search">
          {/*<Select*/}
          {/*  onChange={this.handleChange}*/}
          {/*  isSearchable={true}*/}
          {/*  components={{ Control: ControlComponent }}*/}
          {/*  value={this.state.selectedOption}*/}
          {/*  options={this.formatUserSelectArray()} />*/}
        </div>
        <span className="main">
          <List
            list={this.state.users}
            callBack={this.selectUser}
            costCentres={this.state.costCentres}
            letters={this.state.letters}
            pageNumber={pageNumber}
            filterLetter={filterLetter}
            changeEvent={(i) => {
              this.setState({
                pageNumber: i + 1,
              });
            }}
            changePageFunction={(ref) => (this.resetFunction = ref)}
            generateFirstLetterFilter={this.generateFirstLetterFilter}
          />
        </span>
      </Fragment>
    );
  };

  render() {
    let { accessLevel, accessType } = this.props;
    if (accessLevel <= '0' || accessType === '0') {
      return null;
    }
    if (this.state.loaded) {
      return (
        <UsersTabs tab="list">
          <div className="Home">
            <div className="lander">{this.generateBody()}</div>
          </div>
        </UsersTabs>
      );
    } else {
      return (
        <UsersTabs tab="list">
          <div className="Home">
            <div className="lander">{formattedMessages.loading}</div>
          </div>
        </UsersTabs>
      );
    }
  }
}
