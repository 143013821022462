/*
  App.js - Routes Main File

  Author: Elle Dunbar (2019)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// COMPONENTS
import Home from './containers/Home/';

import Compose from './containers/Websms/Compose/index';
import BulkSend from './containers/Websms/BulkSend/index';
import AddressBook from './containers/Websms/AddressBook';
import Inbox from './containers/Websms/Inbox';
import Distribution from './containers/Websms/DistributionList';
import Sent from './containers/Websms/Sent';
import Errors from './containers/Websms/Error';
import Template from './containers/Websms/TemplateList';
import Settings from './containers/Websms/Settings';

import Providers from './containers/Admin/Provider';
import Customers from './containers/Admin/Customer';
import ErrorLog from './containers/Admin/ErrorLog';
import TraceLog from './containers/Admin/TraceLog';
import ServiceConfig from './containers/Admin/ServiceConfig';

import Tariff from './containers/Admin/Tariffing';
import AdminFindCustomer from './containers/Admin/FindUsers';
import SystemMonitor from './containers/Admin/SystemMonitor';

import Invoices from './containers/Billing/Invoices/';
import PaymentMethod from './containers/Billing/PaymentMethod/';
import InvoiceShow from './containers/Billing/Invoices/InvoiceShow';
import BillingSummary from './containers/Billing/BillingsSummary';
import NewCustomerReport from './containers/Billing/NewCustomerReport';
import CommissionReport from './containers/Billing/CommissionReport';
import ExpiredCards from './containers/Billing/ExpiredCardsReport';
import PurchaseMessages from './containers/Billing/Purchase';
import UnpaidAccounts from './containers/Billing/Invoices/UnpaidAccounts';

import Administrators from './containers/Users/Administrators';
import CostCenters from './containers/Users/CostCentres';
import UserList from './containers/Users/Users';
import FindUser from './containers/Users/FindUsers';

import CustomerReports from './containers/Reports/Customers';
import CostCenterReports from './containers/Reports/CostCentre';
import UserReports from './containers/Reports/UserReport';
import MessageReports from './containers/Reports/Message';
import IncomingReports from './containers/Reports/Incoming';
import ProviderReports from './containers/Reports/Provider';
import OutgoingReports from './containers/Reports/Outgoing';
import TopUserReports from './containers/Reports/TopUser';

/*
  https://stackoverflow.com/questions/69864165/error-privateroute-is-not-a-route-component-all-component-children-of-rou
  as the above link explains, custom Route components no longer work.
*/
//import AppliedRoute from './components/AppliedRoutes';

import Login from './containers/Login/';
import Register from './containers/Register/';
import ForgotPassword from './containers/PasswordRecovery/';
import NewPassword from './containers/PasswordRecovery/NewPassword';

export default class AppRoutes extends Component {
  /* 
    I did some research and found this https://stackoverflow.com/questions/48150567/react-router-difference-between-component-and-render that 
    explains why they chose 'render' over 'component' (< react router v6 stuff. both were precursors to 'element'). Basically, better performance
    https://reactrouter.com/en/v6.3.0/faq and https://blog.logrocket.com/migrating-react-router-v6-complete-guide/ suggests that whats below is the 
    only available replacement BUT I suspect thats because render's advantage was moved to using functional components and hooks
    and away from class components.
  
  
    Interesting source but likely outdated: https://medium.com/missive-app/45-faster-react-functional-components-now-3509a668e69f 
  */

  /**
   * routes for pre-login
   */
  nonUserRoutes() {
    let { nonWebSmsProps } = this.props;
    return (
      <Routes>
        <Route path="/" element={<Login {...nonWebSmsProps} />} />
        <Route path="/login" element={<Login {...nonWebSmsProps} />} />
        <Route path="/register" element={<Register {...nonWebSmsProps} />} />
        <Route
          path="/forgotpassword"
          element={<ForgotPassword {...nonWebSmsProps} />}
        />
        <Route
          path="/recoverpassword/verify/:uniqueUrl"
          element={<NewPassword {...nonWebSmsProps} />}
        />
        <Route path="/*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    );
  }

  /**
   * routes for post-login
   */
  userRoutes() {
    let { nonWebSmsProps, webSmsProps } = this.props;

    return (
      <Routes>
        <Route path="users">
          <Route
            path="administrators"
            element={<Administrators {...nonWebSmsProps} />}
          />
          <Route
            path="cost-centers"
            element={<CostCenters {...nonWebSmsProps} />}
          />
          <Route path="list" element={<UserList {...nonWebSmsProps} />} />
          <Route path="find" element={<FindUser {...nonWebSmsProps} />} />
        </Route>

        <Route path="billing">
          <Route path="invoices" element={<Invoices {...nonWebSmsProps} />} />
          <Route
            path="payment-method"
            element={<PaymentMethod {...nonWebSmsProps} />}
          />
          <Route path="invoiceshow">
            <Route path=":customer">
              <Route
                path=":tab"
                element={<InvoiceShow {...nonWebSmsProps} />}
              />
            </Route>
          </Route>
          <Route
            path="summary"
            element={<BillingSummary {...nonWebSmsProps} />}
          />
          <Route
            path="new-customer-report"
            element={<NewCustomerReport {...nonWebSmsProps} />}
          />
          <Route
            path="commission-report"
            element={<CommissionReport {...nonWebSmsProps} />}
          />
          <Route
            path="expired-cards"
            element={<ExpiredCards {...nonWebSmsProps} />}
          />
          <Route
            path="purchase"
            element={<PurchaseMessages {...nonWebSmsProps} />}
          />
          <Route
            path="unpaid-accounts"
            element={<UnpaidAccounts {...nonWebSmsProps} />}
          />
        </Route>

        <Route path="/reports">
          <Route
            path="customer"
            element={<CustomerReports {...nonWebSmsProps} />}
          />
          <Route
            path="cost-center"
            element={<CostCenterReports {...nonWebSmsProps} />}
          />
          <Route path="user" element={<UserReports {...nonWebSmsProps} />} />
          <Route
            path="message"
            element={<MessageReports {...nonWebSmsProps} />}
          />
          <Route
            path="incoming"
            element={<IncomingReports {...nonWebSmsProps} />}
          />
          <Route
            path="outgoing"
            element={<OutgoingReports {...nonWebSmsProps} />}
          />
          <Route
            path="provider"
            element={<ProviderReports {...nonWebSmsProps} />}
          />
          <Route
            path="top-user"
            element={<TopUserReports {...nonWebSmsProps} />}
          />
        </Route>

        <Route path="websms">
          <Route path="compose" element={<Compose {...webSmsProps} />} />
          <Route path="bulk-send" element={<BulkSend {...webSmsProps} />} />
          <Route
            path="inbox"
            element={<Inbox {...webSmsProps} />}
            replyCallback={this.replyCallback}
          />
          <Route
            path="sent"
            element={<Sent {...webSmsProps} />}
            replyCallback={this.replyCallback}
          />
          <Route path="errors" element={<Errors {...webSmsProps} />} />
          <Route path="template" element={<Template {...webSmsProps} />} />
          <Route path="address" element={<AddressBook {...webSmsProps} />} />
          <Route
            path="distribution"
            element={<Distribution {...webSmsProps} />}
          />
          <Route path="settings" element={<Settings {...webSmsProps} />} />
        </Route>

        <Route path="admin">
          <Route path="provider" element={<Providers {...nonWebSmsProps} />} />
          <Route path="customers" element={<Customers {...nonWebSmsProps} />} />
          <Route path="customer" element={<Customers {...nonWebSmsProps} />} />
          <Route
            path="customer/:id"
            element={<Customers {...nonWebSmsProps} />}
          />
          <Route
            path="customer/:type/:id"
            element={<Customers {...nonWebSmsProps} />}
          />
          <Route path="errorlog" element={<ErrorLog {...nonWebSmsProps} />} />
          <Route path="tracelog" element={<TraceLog {...nonWebSmsProps} />} />
          <Route
            path="service-config"
            element={<ServiceConfig {...nonWebSmsProps} />}
          />
          <Route path="tariffs" element={<Tariff {...nonWebSmsProps} />} />
          <Route
            path="find-customer"
            element={<AdminFindCustomer {...nonWebSmsProps} />}
          />
          <Route
            path="system-monitor"
            element={<SystemMonitor {...nonWebSmsProps} />}
          />
        </Route>

        {/* Generic Routes */}
        <Route path="/home" element={<Home {...webSmsProps} />} />
        <Route path="/*" element={<Navigate to="/home" replace={true} />} />
      </Routes>
    );
  }

  render() {
    let tokenExists = localStorage.getItem('rox_user_token');
    let routes = tokenExists ? this.userRoutes() : this.nonUserRoutes();

    return <Fragment>{routes}</Fragment>;
  }
}
