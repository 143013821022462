/*
  ErrorComponent.js - Error Log Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Table, Form } from 'react-bootstrap';
import { DateTime } from 'luxon';
import Paginator from '../../../components/Paginator';
import config from '../../../Config';
import Single from './Single';

// OUR COMPONENTS
import DateRangeSelector from '../../../components/DateRangeSelector';

// NETWORKING
import ErrorRequest from './ErrorRequest';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';
import AdminTabs from '../index';

// REQUEST
const errorRequest = new ErrorRequest();

export default class Errors extends Component {
  constructor(props) {
    super(props);

    /*
      Expected props
        - errorHandler: The regular error handler passed in throughout the website.
        - accessLevel: The access level for the logged in user.
        - accessType: The access type for the logged in user.
    */

    this.state = {
      isSearching: true,
      hasError: false,
      errorMessage: '',
      errorLogs: [],
      paginatedErrors: [],
      filterOpen: false,
      selectorKeyRefresh: false,
      logTimeFilter: null,
      logLevelFilter: null,
      logGroupIdFilter: null,
      processNameFilter: null,
      programNameFilter: null,
      logMessageFilter: null,
      tagFilter: null,
      pageNumber: 1,
      pageLength: config.defaultPL,
      currentlySearching: {},
    };
  }

  handleErrorLogSearch = async (fromDate, toDate, timeRange) => {
    try {
      this.setState({ isSearching: true });
      let searchParams = {
        fromDate: fromDate,
        toDate: toDate,
        timeRange: timeRange,
        timeRangeStart: fromDate,
        timeRangeEnd: toDate,
        logTimeFilter: this.state.logTimeFilter,
        logLevelFilter: this.state.logLevelFilter,
        logGroupIdFilter: this.state.logGroupIdFilter,
        processNameFilter: this.state.processNameFilter,
        programNameFilter: this.state.programNameFilter,
        logMessageFilter: this.state.logMessageFilter,
        tagFilter: this.state.tagFilter,
      };
      let errorLogs = await errorRequest.searchErrorLog(
        searchParams,
        this.props.errorHandler
      );
      this.setState(
        {
          errorLogs: errorLogs,
          fromDate: searchParams.fromDate,
          toDate: searchParams.toDate,
          timeRange: timeRange,
          currentlySearching: {
            logTimeFilter: searchParams.logTimeFilter,
            logLevelFilter: searchParams.logLevelFilter,
            logGroupIdFilter: searchParams.logGroupIdFilter,
            processNameFilter: searchParams.processNameFilter,
            programNameFilter: searchParams.programNameFilter,
            logMessageFilter: searchParams.logMessageFilter,
            tagFilter: searchParams.tagFilter,
          },
          isSearching: false,
          pageNumber: 1,
        },
        this.setPaginatedResults
      );
    } catch (e) {
      console.error(e);
      return this.setState({
        errorLogs: e.message,
        isSearching: false,
      });
    }
  };

  /*
    Back button
  */
  backButtonClick = () => {
    return this.setState({
      selected: null,
    });
  };

  /* Changes the amount of entries per pagination page. */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState(
        { pageLength: value, pageNumber: 1 },
        this.setPaginatedResults
      );
    }
  };

  setPaginatedResults = async () => {
    let { errorLogs, pageNumber, pageLength } = this.state;
    this.setState(
      {
        paginatedErrors: errorLogs.slice(
          (pageNumber - 1) * pageLength,
          pageNumber * pageLength
        ),
      },
      () => this.changeFunction(this.state.pageNumber - 1)
    );
  };

  changeFunction(page = 0) {
    this.setState({
      activePage: page,
    });
  }

  /**
   * Handles the change event when clicking a pagination number
//    */
  changeEvent = async (i) => {
    this.setState(
      {
        pageNumber: i + 1,
      },
      this.setPaginatedResults
    );
  };

  /*
    Handles the selection and display of a single error logs
  */

  onClickMessage = (error) => {
    return this.setState({
      selected: error,
    });
  };

  toggleMenu = () =>
    this.setState(({ filterOpen }) => ({ filterOpen: !filterOpen }));

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div className="lander">
        <h3>{formattedMessages.errorLogsTitle}</h3>
        <p>
          {formattedMessages.errorActivityLog} {'for all Customers'}
        </p>
      </div>
    );
  };

  /*
    Convenience function to handle the updating of a form element
  */
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    console.log('handleInputChange event name:', name);
    console.log('handleInputChange event value:', value);

    this.setState({
      [name]: value,
    });
  }

  clearFilters = () => {
    this.setState({
      logTimeFilter: null,
      logLevelFilter: null,
      logGroupIdFilter: null,
      processNameFilter: null,
      programNameFilter: null,
      logMessageFilter: null,
      tagFilter: null,
    });
    document.getElementById('filter-form').reset();
  };

  /*
    Generates the Search Tools for querying the data
  */
  generateSearchTools = () => {
    const { filterOpen, currentlySearching, selectorKeyRefresh } = this.state;

    // Destructures which filters the table is currently displaying fields on.
    let {
      logTimeFilter,
      logLevelFilter,
      logGroupIdFilter,
      processNameFilter,
      programNameFilter,
      logMessageFilter,
      tagFilter,
    } = currentlySearching || {};

    let filterText = [];
    let values = [
      'Log Time:',
      'Log Level:',
      'Log Group Id:',
      'Process Name:',
      'Program Name:',
      'Log Message:',
      'Tag:',
    ];

    // Pushes spans to an array for rendering. Iterates to avoid code duplication.
    [
      logTimeFilter,
      logLevelFilter,
      logGroupIdFilter,
      processNameFilter,
      programNameFilter,
      logMessageFilter,
      tagFilter,
    ].map((filter, i) => {
      if (filter)
        filterText.push(
          <span>
            {filterText.length === 0 ? '' : '; '} <b>{values[i]}</b> {filter}
          </span>
        );
    });

    return (
      <Fragment>
        <DateRangeSelector
          key={selectorKeyRefresh ? 1 : 0} // Changing the key remounts it
          handleDateSearch={(fromDate, toDate, timeRange) =>
            this.handleErrorLogSearch(fromDate, toDate, timeRange)
          }
        ></DateRangeSelector>
        <div className="Home">
          <div className="lander">
            <br />
            <span className="showHideLink-left">
              <span className="advanced-filters" onClick={this.toggleMenu}>
                {filterOpen ? (
                  <i className="material-icons">keyboard_arrow_down</i>
                ) : (
                  <i className="material-icons">keyboard_arrow_right</i>
                )}
                Advanced Filters
              </span>
              <div className="clear-filters" onClick={this.clearFilters}>
                {formattedMessages.clear}
              </div>
            </span>
            {filterOpen && (
              <div>
                <Form id="filter-form">
                  <table className="no-table-row-background">
                    <tbody className="filter-table">
                      <div className="filter">
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filtering-label">
                              Log Level:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="logLevelFilter"
                                id="logLevelFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Log Level"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filtering-label">
                              Log Group Id:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="logGroupIdFilter"
                                id="logGroupIdFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Log Group Id"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filtering-label">
                              Process Name:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="processNameFilter"
                                id="processNameFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Process Name"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filtering-label">
                              Program Name:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="programNameFilter"
                                id="programNameFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Program Name"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filtering-label">
                              Log Message:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="logMessageFilter"
                                id="logMessageFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Log Message"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filtering-label">
                              Tag:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="tagFilter"
                                id="tagFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Tag"
                              />
                            </div>
                          </td>
                        </div>
                      </div>
                    </tbody>
                  </table>
                </Form>

                <br></br>
              </div>
            )}
            <div className="flex-between">
              {filterText.length > 0 && <div>{filterText}</div>}
            </div>
          </div>
        </div>
        <br />
      </Fragment>
    );
  };

  reloadPage = () => {
    window.location.reload(1);
  };

  generateTable = () => {
    if (this.state.isSearching) {
      return <div>{formattedMessages.searchingText}</div>;
    } else if (this.state.errorLogs.length === 0) {
      return (
        <div>
          <span>No results found.</span>
        </div>
      );
    }

    return (
      <div className="errorTable">
        <Table>
          <thead>
            <tr>
              <td className="medium tableTop textLeft">
                {formattedMessages.tableHeaderLogTime}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.tableHeaderLogLevel}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.tableHeaderLogGroupId}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.tableHeaderProcessName}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.tableHeaderProgramName}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.tableHeaderLogMessage}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.tableHeaderTag}
              </td>
            </tr>
          </thead>
          <tbody>{this.generateTableRows()}</tbody>
        </Table>
        <Paginator
          itemCount={this.state.errorLogs.length}
          changeEvent={this.changeEvent}
          pageLength={this.state.pageLength}
          editFunction={this.changePageLength}
          changePageFunction={(ref) => (this.changeFunction = ref)}
        />
      </div>
    );
  };

  /*
    Loops and generates Table rows from the data
  */
  generateTableRows = () => {
    let { paginatedErrors } = this.state;
    let rows = [];
    let errorLogs = paginatedErrors;
    let failedDate;
    let shortId;

    for (let i = 0; i < errorLogs.length; i++) {
      failedDate = DateTime.fromISO(errorLogs[i].log_time);

      // Convert to UTC
      const utcDateTime = failedDate.toUTC();

      shortId = 'N/A';
      if (errorLogs[i].log_group_id) shortId = errorLogs[i].log_group_id;

      let row = (
        <tr key={i} id={errorLogs[i].log_errors_id} className="default-cursor">
          <td className="medium textLeft">
            {utcDateTime.toFormat('dd-LLL-yyyy h:mm:ss a')}
          </td>
          <td className="medium textLeft">{errorLogs[i].log_level}</td>
          <td className="textLeft">{shortId}</td>
          <td className="medium textLeft">{errorLogs[i].process_name}</td>
          <td className="medium textLeft">{errorLogs[i].program_name}</td>
          <td className="medium textLeft">
            <span
              className="blue"
              onClick={() => this.onClickMessage(errorLogs[i])}
            >
              {errorLogs[i].log_message}
            </span>
          </td>
          <td className="medium textLeft">{errorLogs[i].tag || 'N/A'}</td>
        </tr>
      );
      rows.push(row);
    }
    return rows;
  };

  render() {
    let { accessLevel, accessType } = this.props;
    if (this.state.selected) {
      return (
        <AdminTabs accessLevel={accessLevel} accessType={accessType}>
          <Single
            errorLog={this.state.selected}
            backButtonClick={this.backButtonClick}
          />
        </AdminTabs>
      );
    }
    let { paginatedErrors } = this.state;
    console.log('paginatedErrors:', paginatedErrors);
    if (accessLevel !== '3' || accessType !== '3') {
      return null;
    }
    if (this.state.hasError) {
      return (
        <div>{`${formattedMessages.errorText} ${this.state.errorMessage}`}</div>
      );
    } else {
      return (
        <AdminTabs
          tab="errorlog"
          accessLevel={accessLevel}
          accessType={accessType}
        >
          <div className="Home">
            {this.generateLander()}
            {this.generateSearchTools()}
            {this.generateTable()}
          </div>
        </AdminTabs>
      );
    }
  }
}
