import React from 'react';

const ListItem = ({
  id,
  name = '',
  active = '',
  onClickName = null,
  provider = null,
}) => (
  <tr key={`list${id}`} id={id}>
    <td className="medium column-right left" name="name">
      <span className="blue" onClick={() => onClickName(provider)}>
        {name}
      </span>
    </td>
    <td className="" name="active">
      <span>{active}</span>
    </td>
  </tr>
);

export default ListItem;
