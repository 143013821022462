/*
  Device/index.js - Main Device Component

  Author: Elle Dunbar (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
// import {Table} from 'react-bootstrap';
// import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap';

// OUR COMPONENTS
import Search from './Search';
import Single from './Single';
import All from './All';

import CustomerSingle from '../../Customer/Edit';
import UserReport from '../../../Reports/UserReport';
import PhoneNumberSingle from './Number/Single';
import DeviceRequest from './DeviceRequest';

const deviceRequest = new DeviceRequest();

// STYLING
import '../../Admin.css';

export default class Device extends Component {
  constructor(props) {
    super(props);

    /*
      PROPS LIST
      - handleViewTwilioNumbers: Function to handle when View Available Twilio
      Numbers button is clicked
    */

    this.state = {
      selectedDevice: null,
      selectedCustomer: null,
      selectedUser: null,
      selectedNumber: null,
      viewAllDevices: false,
      backTo: null,
    };
  }

  async componentDidMount() {
    // Handles redirects from the customer edit page
    if (this.props.location?.state?.devicesRedirect) {
      const { devicesRedirect } = this.props.location.state;

      // Note: I know this is a weird way of doing this but we do not have
      // a get single device function?
      const devices = await deviceRequest.searchDevices(
        { device_id: devicesRedirect },
        this.props.errorHandler
      );

      // If the device not found, device page acts as normal
      if (!devices || !devices.length) return;

      this.setState({
        selectedDevice: devices[0],
        viewAllDevices: false,
        backTo: 'Customer',
      });
    }
  }

  viewAllDevices = () => {
    return this.setState({
      viewAllDevices: true,
    });
  };

  handleBackToAllDevices = () => {
    return this.setState({
      viewAllDevices: true,
      selectedDevice: null,
      selectedCustomer: null,
      selectedUser: null,
      selectedNumber: null,
      addNewDevice: false,
      backTo: 'All',
    });
  };

  handleBackClick = () => {
    return this.setState({
      selectedDevice: null,
      selectedCustomer: null,
      selectedUser: null,
      selectedNumber: null,
      viewAllDevices: false,
      addNewDevice: false,
      backTo: 'All',
    });
  };

  handleAddNewDevice = () => {
    return this.setState({
      viewAllDevices: false,
      addNewDevice: true,
      backToAll: 'All',
    });
  };

  onClickDeviceName = (device, backToAll) => {
    return this.setState({
      viewAllDevices: false,
      selectedDevice: device,
      backToAll: backToAll ? 'All' : null,
    });
  };

  onClickCustomerName = (customer) => {
    this.props.navigate('/admin/customer/' + customer.customer_id);
  };

  onClickUserName = (customerUser) => {
    return this.setState({
      selectedUser: customerUser,
    });
  };

  onClickPhoneNumber = (phoneNumber) => {
    return this.setState({
      selectedNumber: phoneNumber,
    });
  };

  /*
    Generate main content
  */
  generateContent() {
    let {
      selectedDevice,
      selectedCustomer,
      selectedUser,
      selectedNumber,
      viewAllDevices,
      addNewDevice,
      backTo,
    } = this.state;

    if (this.props.location?.state?.devicesRedirect && !selectedDevice) {
      return 'Loading...';
    }

    if (viewAllDevices) {
      return (
        <All
          errorHandler={this.props.errorHandler}
          backButtonClick={this.handleBackClick}
          showSingleDevice={this.onClickDeviceName}
          addNewDevice={this.handleAddNewDevice}
        />
      );
    }

    // Have a Selected Device
    if (selectedDevice || addNewDevice) {
      let backButtonClick = this.handleBackClick;
      if (backTo === 'All') backButtonClick = this.handleBackToAllDevices;
      else if (backTo === 'Customer')
        backButtonClick = () => this.props.navigate(-1);

      return (
        <Single
          device={selectedDevice}
          isNew={addNewDevice}
          backButtonClick={backButtonClick}
          errorHandler={this.props.errorHandler}
        />
      );
    }

    // Have a Selected Customer
    if (selectedCustomer) {
      return (
        <CustomerSingle
          customer={selectedCustomer}
          backButtonClick={this.handleBackClick}
          errorHandler={this.props.errorHandler}
        />
      );
    }

    if (selectedUser) {
      return <UserReport userData={selectedUser} />;
    }

    if (selectedNumber) {
      return (
        <PhoneNumberSingle
          device={selectedNumber}
          backButtonClick={this.handleBackClick}
        />
      );
    }

    return (
      <Search
        handleViewTwilioNumbers={this.props.handleViewTwilioNumbers}
        viewAllDevices={this.viewAllDevices}
        showSingleDevice={this.onClickDeviceName}
        showSingleCustomer={this.onClickCustomerName}
        showSingleUserReport={this.onClickUserName}
        showSinglePhoneNumber={this.onClickPhoneNumber}
      />
    );
  }

  render() {
    return <div className="Home device">{this.generateContent()}</div>;
  }
}
