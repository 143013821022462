import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import Paginator from "../../../../components/Paginator";
import AdminRequest from "../../AdminRequest";
import Config from "../../../../Config";
import { DateTimePicker } from "react-widgets";

const adminRequest = new AdminRequest();

const AuthLog = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [showMessages, setShowMessages] = useState(false);
  const [messages, setMessages] = useState([]);
  const [onDate, setOnDate] = useState(new Date());
  const [selectedEmail, setSelectedEmail] = useState('');
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [messagesPage, setMessagesPage] = useState(1);
  const [messagesCount, setMessagesCount] = useState(0);

  const refresh = async () => {
    setLoading(true);
    const data = await adminRequest.getAuthGroups(onDate, page, Config.defaultPL, props.errorHandler);
    data.items.forEach(item => {
      item.success_count = item.message_count - item.failure_count;
    });
    setData(data.items);
    setCount(data.total);
    setLoading(false);
  }

  const refreshMessages = async () => {
    if (!selectedEmail) return;

    setMessagesLoading(true);
    const data = await adminRequest.getAuthLogs(selectedEmail, onDate, messagesPage, Config.defaultPL, props.errorHandler);
    setMessages(data.items);
    setMessagesCount(data.total);
    setMessagesLoading(false);
  }

  const toggleDetails = async (item) => {
    setSelectedEmail(item.customer_user_email);
    setMessages([]);
    setMessagesPage(1);
    setShowMessages(true);
  }

  useEffect(() => {
    refresh();
  }, [page, onDate]);

  useEffect(() => {
    refreshMessages();
  }, [messagesPage, selectedEmail]);

  return (
    <>
      <div className="lander flex">
        <h3>Auth Log</h3>
        <div className="flex flexRight">
          {loading && (
            <div className="loading-spinner">
              <Spinner animation="border" variant="primary" size="sm"/>
              &nbsp;Loading...
            </div>
          )}
          <Button className="btn add-new-template" onClick={() => refresh()}>
            Refresh
          </Button>
        </div>
      </div>

      <div>
        <DateTimePicker 
          type="date" 
          format="MMM DD, YYYY"
          time={false}
          placeholder="Since" 
          value={onDate}
          onChange={(value) => { setOnDate(new Date(value)); }} 
        />
      </div>

      <Table>
        <thead>
          <tr>
            <th>Last Attempt</th>
            <th>Email</th>
            <th>Counts</th>
          </tr>
        </thead>
        <tbody>
          {!loading && data.length === 0 && (
            <tr>
              <td colSpan="3" className="text-center">No data found</td>
            </tr>
          )}
          {data.map((item, index) => {
            return (
              <tr key={index} onClick={() => toggleDetails(item)} className="clickable">
                <td>{
                  new Intl.DateTimeFormat('en-us', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                  }).format(new Date(item.last_updated))
                }</td>
                <td>{item.customer_user_email}</td>
                <td>
                  <span className="count-success" title="Success">
                    { item.success_count }
                  </span>
                  &nbsp;-&nbsp;
                  <span className="count-failed" title="Failed">
                    {item.failure_count}
                  </span>
                  &nbsp;/&nbsp;
                  <span title="Total">
                    {item.message_count}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {count > 10 && (
        <Paginator
          itemCount={count}
          changeEvent={(page) => { setPage(page + 1); }}
        />
      )}

      <Modal show={showMessages} backdrop="static" onHide={() => setShowMessages(false)} size="lg" id="messages-modal">
        <Modal.Header closeButton>
          <Modal.Title>Messages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              {messagesLoading && (
                <tr>
                  <td colSpan="2">
                    <div className="loading-spinner">
                      <Spinner animation="border" variant="primary" size="sm"/>
                      &nbsp;Loading...
                    </div>
                  </td>
                </tr>
              )}
              {messages.map((message, index) => {
                const final = message.logs.find(log => log.tag === 'AUTH_FINAL');
                const result = final || message.logs.find(log => log.tag === 'AUTH_DETAILS');
                const clazz = result.log_message.toLowerCase().includes('fail') ? 'text-danger' : '';

                return <tr key={index}>
                  <td>{
                    new Intl.DateTimeFormat('en-us', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric'
                    }).format(new Date(result.log_time))
                  }</td>
                  <td>
                    <span className={clazz}>
                      {result.log_message}
                    </span>
                  </td>
                </tr>
              })}
            </tbody>
          </Table>
          {messagesCount > 10 && (
            <Paginator
              itemCount={messagesCount}
              changeEvent={(page) => { setMessagesPage(page + 1); }}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AuthLog;
