/*
  Add.js - Create Administrator Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM
import React, { Component } from 'react';
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {
  validateName,
  validatePhoneNumber,
  validatePassword,
  validateEmail,
} from '../../../utils/validate';
// COMPONENTS
import ErrorText from '../../../components/ErrorText';

// I18N
import formattedMessages from './FormattedMessages';

// NETWORKS
import AdministratorRequest from './AdministratorRequest';
import UsersTabs from '../index';
import CountryPicker from '../../../components/CountryPicker';
import CustomTooltip from '../../../components/CustomTooltip';
const administratorRequest = new AdministratorRequest();

class AdministratorAdd extends Component {
  constructor(props) {
    super(props);

    /*
      PROPS LIST
        - handleBackClick: Function to handle going back
        - errorHandler: Function to handle generated errors
    */

    this.state = {
      access_levels: [],
      edits: {},
      validPassword: true,
      validConfirmPassword: true,
      error: '',
      isSaving: false,
      showCheckbox: false,
    };
  }

  async componentDidMount() {
    try {
      let extras = await administratorRequest.getExtras(
        this.props.errorHandler
      );
      return this.setState(extras);
    } catch (e) {
      return this.setState({
        error: e.message,
      });
    }
  }

  /*
    Holds state changes from the form
  */
  handleFormChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    let edits = this.state.edits;
    if (name === 'formCountry') edits.contact_country = value;
    else edits[name] = value;
    console.log(edits);
    return this.setState(
      {
        edits: edits,
      },
      () => {
        let { edits } = this.state;
        if (!edits.password || edits.password === '') {
          this.setState({
            validPassword: true,
          });
        } else {
          if (validatePassword(edits.password)) {
            this.setState({
              validPassword: true,
            });
          } else {
            this.setState({
              validPassword: false,
            });
          }
        }
        if (edits.password_check && edits.password_check !== edits.password) {
          this.setState({
            validConfirmPassword: false,
          });
        } else {
          this.setState({
            validConfirmPassword: true,
          });
        }
      }
    );
  };

  /*
    Validates form.  Returns whether form is valid and, if not valid,
    returns an error text as well.
  */
  validateForm = async () => {
    let isValidForm = false;
    let errText = '';
    let { edits } = this.state;
    // Validation checks
    if (!validateName(edits.contact_name)) {
      errText = formattedMessages.errTextName;
    } else if (!edits.contact_tn) {
      errText = formattedMessages.errTextPhoneNumber;
    } else if (
      edits.contact_tn &&
      (edits.contact_tn.trim() === '' || !validatePhoneNumber(edits.contact_tn))
    ) {
      errText = formattedMessages.errFormatPhoneNumber;
    } else if (
      edits.contact_mobile &&
      (edits.contact_mobile.trim() === '' ||
        !validatePhoneNumber(edits.contact_mobile))
    ) {
      errText = formattedMessages.errFormatMobileNumber;
    } else if (!edits.contact_email) {
      errText = formattedMessages.errTextEmail;
    } else if (!edits.password) {
      errText = formattedMessages.errTextPassword;
    } else if (!validatePassword(edits.password)) {
      errText = formattedMessages.errCheckPasswordStrong;
    } else if (!edits.password || !edits.password_check) {
      errText = formattedMessages.errCheckPassword;
    } else if (!(edits.password === edits.password_check)) {
      errText = formattedMessages.errCheckPasswordMatch;
    } else if (!edits.contact_country || edits.contact_country === undefined)
      errText = formattedMessages.errTextCountry;
    else if (!edits.access_level)
      errText = formattedMessages.errTextAccessLevel;
    else if (!validateEmail(edits.contact_email))
      errText = formattedMessages.errTextEmailCheck;

    // If no error text, form is valid
    if (!errText) isValidForm = true;
    // else set error text in state.
    else this.setState({ error: errText });

    return isValidForm;
  };

  /*
   Handles submitting the form
 */
  async handleCheck() {
    let { edits } = this.state;
    this.setState({ isSaving: true });
    if (await this.validateForm()) {
      let { user, contact } = await administratorRequest.getUserByEmail(
        edits.contact_email,
        this.props.errorHandler
      );
      console.log('user', user);
      console.log('contact', contact);
      if (contact) {
        return this.setState({
          error: formattedMessages.errContactExists,
          isSaving: false,
        });
      }
      if (user === 'Other Customer') {
        return this.setState({
          error: formattedMessages.errUserExists,
          isSaving: false,
        });
      } else if (user) return this.setState({ showCheckbox: true });
      this.handleSubmit();
    }
    this.setState({ isSaving: false });
  }

  async handleSubmit() {
    let { edits } = this.state;
    let newUserObject = await administratorRequest.createAdministrator(
      edits,
      this.props.errorHandler
    );
    if (!newUserObject.error) this.props.handleBackClick('refresh');
    this.setState({ isSaving: false });
  }

  toggleSaving() {
    let toggle = !this.state.isSaving;
    this.setState({
      isSaving: toggle,
    });
  }

  /* 
  Handles modal for upgrading a user to an admin
  */
  closeModal = () => {
    return this.setState({
      showCheckbox: false,
      isSaving: false,
    });
  };

  generateModal = () => {
    return (
      <Modal
        show={this.state.showCheckbox}
        onHide={this.closeModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formattedMessages.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{formattedMessages.modalBody}</Modal.Body>
        <Modal.Footer>
          <Button
            className="maxHeight"
            variant="primary"
            onClick={this.handleSubmit.bind(this)}
          >
            {formattedMessages.modalYes}
          </Button>
          <Button
            className="no-button btn btn-secondary"
            variant="secondary"
            onClick={this.closeModal}
          >
            {formattedMessages.modalNo}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  /*
    Renders Error Component
  */
  generateErrorText = () => {
    if (!this.state.error) return null;
    return <ErrorText hasError={true} errorText={this.state.error} />;
  };

  /*
    Generates the Access Levels Select component
  */
  generateAccessLevelsSelect = () => {
    let { access_levels, edits } = this.state;
    let accessLevelList = [];

    accessLevelList.push(
      <option selected disabled hidden key={null} value={null}>
        {'Select One'}
      </option>
    );

    for (let i = 0; i < access_levels.length; i++) {
      accessLevelList.push(
        <option key={access_levels[i].code} value={access_levels[i].code}>
          {access_levels[i].meaning}
        </option>
      );
    }

    return (
      <Form.Control
        onChange={this.handleFormChange}
        as="select"
        name="access_level"
        value={edits.access_level || null}
      >
        {accessLevelList}
      </Form.Control>
    );
  };

  /*
    Generates the Country Select component
  */
  generateCountry = () => {
    let { selected } = this.state;
    if (!selected || !selected.customer_contact_id) {
      selected = this.props.selected; // Set to props if no state
    }
    if (!selected) selected = {};
    return (
      <CountryPicker
        className="form-control"
        onChange={this.handleFormChange}
        defaultValue={null}
        selectDefault={true}
      />
    );
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="AddAdmin" className="lander">
        <p onClick={() => this.props.handleBackClick()} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backToAdmin}</span>
        </p>
        <h3>{formattedMessages.AddAdminLandingText}</h3>
      </div>
    );
  };

  /*
    Handles whether or not to display the submit button
  */
  generateButton = () => {
    if (this.state.isSaving) return <p>Saving...</p>;
    return (
      <Button
        type="submit"
        className="maxHeight"
        onClick={() => this.handleCheck()}
      >
        {formattedMessages.save}
      </Button>
    );
  };

  generateFormGroup = (controlId, label, type) => {
    return (
      <Form.Group as={Row} controlId={controlId} autoComplete="new-inputs">
        <Form.Label column sm={2} className="detail-label">
          {label}:
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type={type || 'text'}
            name={controlId}
            autoComplete="new-inputs"
          />
        </Col>
      </Form.Group>
    );
  };

  checkValue = (val, type) => {
    if (val === this.state.edits?.[type]) return true;
    return false;
  };

  generateForm = () => {
    let { validPassword, validConfirmPassword, edits } = this.state;
    return (
      <Form
        onSubmit={this.handleCheck}
        onChange={this.handleFormChange}
        autoComplete="off"
      >
        {this.generateFormGroup('contact_name', '* Name')}
        {this.generateFormGroup('contact_posn', 'Position')}
        {this.generateFormGroup('contact_dept', 'Department')}
        {this.generateFormGroup('contact_tn', '* Contact Phone')}
        {this.generateFormGroup('contact_mobile', 'Mobile Phone')}
        {this.generateFormGroup('contact_email', '* Email')}
        <Form.Group as={Row} controlId="password">
          <Form.Label column sm={2} className="detail-label">
            * Password{' '}
            <CustomTooltip
              tooltipText="The passwords must be 8 or more characters long 
              and contains at least one uppercase and one lowercase letter, 
              a number and a symbol."
              margin="5px"
            />
          </Form.Label>
          <Col sm={8}>
            <OverlayTrigger
              placement="right"
              show={edits.password !== '' && !validPassword}
              overlay={
                <Tooltip id="password-tooltip">
                  <p style={{ textAlign: 'left' }}>
                    Password is not strong enough.
                  </p>
                </Tooltip>
              }
            >
              <Form.Control
                type="password"
                name="password"
                autoComplete="new-password"
                isInvalid={!validPassword}
              />
            </OverlayTrigger>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="password_check">
          <Form.Label column sm={2} className="detail-label">
            * Confirm Password
          </Form.Label>
          <Col sm={8}>
            <OverlayTrigger
              placement="right"
              show={edits.password !== '' && !validConfirmPassword}
              overlay={
                <Tooltip id="confirm-password-tooltip">
                  <p style={{ textAlign: 'left' }}>
                    {"Passwords don't match."}
                  </p>
                </Tooltip>
              }
            >
              <Form.Control
                type="password"
                name="password_check"
                autoComplete="new-password"
                isInvalid={edits.password && !validConfirmPassword}
              />
            </OverlayTrigger>
          </Col>
        </Form.Group>
        {this.generateFormGroup('contact_address1', 'Street Address')}
        {this.generateFormGroup('contact_address2', 'Continued...')}
        {this.generateFormGroup('contact_city', 'City')}
        {this.generateFormGroup('contact_state', 'State')}
        {this.generateFormGroup('contact_postcode', 'Postcode')}
        <Form.Group as={Row} controlId="contact_country">
          <Form.Label column sm={2} className="detail-label">
            {'* Country'}:
          </Form.Label>
          <Col sm={8}>{this.generateCountry()}</Col>
        </Form.Group>

        <Form.Group as={Row} controlId="access_level">
          <Form.Label column sm={2} className="detail-label">
            {formattedMessages.accessLevel}:
          </Form.Label>
          <Col sm={8}>{this.generateAccessLevelsSelect()}</Col>
        </Form.Group>
        <Form.Group as={Row} controlId="billing_contact">
          <Form.Label column sm={2} className="detail-label">
            {formattedMessages.billingContactType}:
          </Form.Label>
          <Col sm={8}>
            <Form.Check
              inline
              checked={this.checkValue('P', 'billing_contact')}
              label={formattedMessages.primary}
              name="billing_contact"
              type="radio"
              value="P"
              id={'billing_contact_primary'}
            />
            <Form.Check
              inline
              checked={this.checkValue('S', 'billing_contact')}
              label={formattedMessages.secondary}
              name="billing_contact"
              type="radio"
              value="S"
              id={'billing_contact_secondary'}
            />
            <Form.Check
              inline
              checked={this.checkValue('N', 'billing_contact')}
              label={formattedMessages.na}
              name="billing_contact"
              type="radio"
              value="N"
              id={'billing_contact_na'}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="sales_contact">
          <Form.Label column sm={2} className="detail-label">
            {formattedMessages.salesContactType}:
          </Form.Label>
          <Col sm={8}>
            <Form.Check
              inline
              checked={this.checkValue('P', 'sales_contact')}
              label={formattedMessages.primary}
              name="sales_contact"
              type="radio"
              value="P"
              id={'sales_contact_primary'}
            />
            <Form.Check
              inline
              checked={this.checkValue('S', 'sales_contact')}
              label={formattedMessages.secondary}
              name="sales_contact"
              type="radio"
              value="S"
              id={'sales_contact_secondary'}
            />
            <Form.Check
              inline
              checked={this.checkValue('N', 'sales_contact')}
              label={formattedMessages.na}
              name="sales_contact"
              type="radio"
              value="N"
              id={'sales_contact_na'}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="technical_contact">
          <Form.Label column sm={2} className="detail-label">
            {formattedMessages.technicalContactType}:
          </Form.Label>
          <Col sm={8}>
            <Form.Check
              inline
              checked={this.checkValue('P', 'technical_contact')}
              label={formattedMessages.primary}
              name="technical_contact"
              type="radio"
              value="P"
              id={'technical_contact_primary'}
            />
            <Form.Check
              inline
              checked={this.checkValue('S', 'technical_contact')}
              label={formattedMessages.secondary}
              name="technical_contact"
              type="radio"
              value="S"
              id={'technical_contact_secondary'}
            />
            <Form.Check
              inline
              checked={this.checkValue('N', 'technical_contact')}
              label={formattedMessages.na}
              name="technical_contact"
              type="radio"
              value="N"
              id={'technical_contact_na'}
            />
          </Col>
        </Form.Group>
        {this.generateErrorText()}
        {this.generateButton()}
        {this.generateModal()}
      </Form>
    );
  };

  render() {
    return (
      <UsersTabs tab="admin">
        <div>
          {this.generateLander()}
          <br />
          {this.generateForm()}
        </div>
      </UsersTabs>
    );
  }
}

export default AdministratorAdd;
