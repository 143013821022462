// NPM
import React, { Component } from 'react';
import FormattedMessage from '../FormattedMessages';

// COMPONENTS
import ListComponent from './ListComponent';
import ListItem from './ListItem';
import BillingTabs from '../index';
import InvoiceAdjustment from './invoiceAdjustment';
import StagedModal from '../../../components/StagedModal';
import InvoiceSearchTools from './FilterComponent';

// NETWORKING
import InvoiceRequest from './InvoiceRequest';
const invoiceRequests = new InvoiceRequest();

export default class Invoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stv: false,
      data: [],
      pageData: [],
      pageCount: 0,
      dateType: 'id',
      tab: '',
      showFilterDetails: false,
      loading: true,
      searchParams: null,
      fromDate: new Date(),
      toDate: new Date(),
      isSelected: false,
      singleBill: null,
      showStaged: false,
      timeRange: null,
      stage: 0,
      refreshPage: false,
      modalParameters: {},
      modalActionType: null,
      currentlySearching: {},
      selectorKeyRefresh: false,
      pageLoaded: false,
      refreshedBill: null,
    };
  }

  performAction = async (invoiceId, customerId, providerId, billId) => {
    console.log('performAction');
    switch (this.state.modalActionType) {
      case 'processPendingInvoice':
        console.log('processPendingInvoice');
        await this.processPendingInvoice(billId, customerId);
        break;
      case 'transitionPendingToInvoiced':
        console.log('transitionPendingToInvoiced');
        await this.transitionPendingToInvoiced(customerId, providerId, billId);
        break;
      case 'transitionBillToFailed':
        console.log('transitionBillToFailed');
        await this.transitionBillToFailed(customerId, providerId, billId);
        break;
      case 'cancelPendingTransaction':
        console.log('cancelPendingTransaction');
        await this.cancelPendingTransaction(customerId, providerId, billId);
        break;
      case 'emailInvoice':
        console.log('emailInvoice');
        await this.emailRequestHandler(customerId, providerId, billId);
        break;
      case 'refreshInvoice':
        console.log('refreshRequestHandler');
        await this.refreshRequestHandler(customerId, providerId, billId);
        break;
      default:
        console.error('Invoice attempted to perform an unknown action');
        break;
    }
  };

  /**
   * Handles clicking on the process invoice dropdown button
   * @param {String | uuid} billId id for bill
   * @param {String | uuid} customerId id for customer logged in
   */
  processPendingInvoice = async (billId, customerId) => {
    if (!this.state.showStaged) {
      this.setState({
        stage: 0,
        showStaged: true,
        modalParameters: {
          billId,
          customerId,
          stageTitle: 'Process Transaction',
          stageConfirmation:
            'Are you sure you would like to process payment on this invoice?',
          stagePending: 'Processing transaction...',
          stageSuccess: 'Invoice paid successfully!',
          stageFailure: 'Invoice payment failed!',
        },
        modalActionType: 'processPendingInvoice',
      });
    } else if (this.state.stage === 0) {
      this.setState({ stage: 1 });
      let results = await invoiceRequests.processInvoiceTransaction(
        this.state.modalParameters?.customerId,
        this.state.modalParameters?.billId,
        this.props.errorHandler
      );
      console.log('got results from pay invoice:', results);
      if (results.message === 'Successful Payment') this.setState({ stage: 2 });
      else
        this.setState({
          stage: 3,
          modalParameters: {
            stageTitle: 'Invoice Bill',
            errorMsg: results.message,
            stageFailure: 'Invoice payment failed!',
          },
        });
      this.dataChange(results, results.paid_date);
    }
  };

  transitionPendingToInvoiced = async (customerId, providerId, billId) => {
    if (!this.state.showStaged) {
      this.setState({
        stage: 0,
        showStaged: true,
        modalParameters: {
          billId,
          customerId,
          providerId,
          stageTitle: 'Invoice Bill',
          stageConfirmation:
            'Are you sure you would like convert this pending bill to invoiced?',
          stagePending: 'invoicing bill...',
          stageSuccess: 'Bill Invoiced successfully!',
          stageFailure: 'Invoice Generation failed!',
        },
        modalActionType: 'transitionPendingToInvoiced',
      });
    } else if (this.state.stage === 0) {
      this.setState({ stage: 1 });
      let result = await invoiceRequests.updateStatus(
        this.state.modalParameters?.providerId,
        this.state.modalParameters?.customerId,
        this.state.modalParameters?.billId,
        'I', // Invoiced
        this.props.errorHandler
      );
      console.log('got results updating invoice:', result);
      if (result.status === 'Invoiced') this.setState({ stage: 2 });
      else this.setState({ stage: 3 });
      this.dataChange(result);
    }
  };

  transitionBillToFailed = async (customerId, providerId, billId) => {
    if (!this.state.showStaged) {
      this.setState({
        stage: 0,
        showStaged: true,
        modalParameters: {
          billId,
          customerId,
          providerId,
          stageTitle: 'Fail Transaction',
          stageConfirmation: 'Are you sure you want to fail this invoice?',
          stagePending: 'Failing Invoice...',
          stageSuccess: 'Invoice successfully transitioned to "Failed"!',
          stageFailure: 'Invoice failed to transition to "Failed"!',
        },
        modalActionType: 'transitionBillToFailed',
      });
    } else if (this.state.stage === 0) {
      this.setState({ stage: 1 });
      let result = await invoiceRequests.updateStatus(
        this.state.modalParameters?.providerId,
        this.state.modalParameters?.customerId,
        this.state.modalParameters?.billId,
        'F', //Failed
        this.props.errorHandler
      );
      console.log('got results updating invoice:', result);
      if (result.status === 'Failed') this.setState({ stage: 2 });
      else this.setState({ stage: 3 });
      this.dataChange(result);
    }
  };

  cancelPendingTransaction = async (customerId, providerId, billId) => {
    if (!this.state.showStaged) {
      this.setState({
        stage: 0,
        showStaged: true,
        modalParameters: {
          billId,
          customerId,
          providerId,
          stageTitle: 'Cancel Transaction',
          stageConfirmation: 'Are you sure you want to cancel this invoice?',
          stagePending: 'Canceling Invoice...',
          stageSuccess: 'Invoice Cancelled successfully!',
          stageFailure: 'Invoice failed to be cancelled!',
        },
        modalActionType: 'cancelPendingTransaction',
      });
    } else if (this.state.stage === 0) {
      this.setState({ stage: 1 });
      let result = await invoiceRequests.updateStatus(
        this.state.modalParameters?.providerId,
        this.state.modalParameters?.customerId,
        this.state.modalParameters?.billId,
        'C', // Cancelled
        this.props.errorHandler
      );
      console.log('got results updating invoice:', result);
      if (result.status === 'Cancelled') this.setState({ stage: 2 });
      else this.setState({ stage: 3 });
      this.dataChange(result);
    }
  };

  removeStaged = async (refreshPage, redirectToInvoice) => {
    this.setState({
      showStaged: false,
      modalParameters: {},
    });
    if (refreshPage) {
      this.setState({
        loading: true,
      });
      let searchParams = {};
      searchParams.fromDate = this.state.fromDate;
      searchParams.toDate = this.state.toDate;
      searchParams.timeRange = this.state.timeRange;
      searchParams.timeRangeStart = this.state.fromDate;
      searchParams.timeRangeEnd = this.state.toDate;
      searchParams.providerFilter = this.state.providerFilter;
      searchParams.invoiceFilter = this.state.invoiceFilter;
      searchParams.customerFilter = this.state.customerFilter;
      searchParams.currencyFilter = this.state.currencyFilter;
      searchParams.customerTypeFilter = this.state.customerTypeFilter;
      searchParams.statusFilter = this.state.statusFilter;
      let data = await invoiceRequests.searchInvoices(
        searchParams,
        this.props.errorHandler
      );
      data = this.sortByDate(data);
      this.setState(
        {
          data: data,
          refreshPage: false,
          loading: false,
          pageCount: data.length,
        },
        this.formatData
      );
    }

    if (redirectToInvoice) {
      let { refreshedBill } = this.state;
      const redirectUrl = `/billing/invoiceshow/${refreshedBill.customer_id}/${refreshedBill.bill_id}`;
      this.props.navigate(redirectUrl);
      this.setState({
        redirectToInvoice: false,
      });
    }
  };

  emailRequestHandler = async (providerId, customerId, billId) => {
    if (!this.state.showStaged) {
      this.setState({
        stage: 0,
        showStaged: true,
        modalParameters: {
          billId,
          customerId,
          providerId,
          stageTitle: 'Email Invoice',
          stageConfirmation: 'Are you sure you want to Email this invoice?',
          stagePending: 'Emailing Invoice...',
          stageSuccess: 'Invoice Sent successfully!',
          stageFailure: 'Invoice send failed!',
        },
        modalActionType: 'emailInvoice',
      });
    } else if (this.state.stage === 0) {
      this.setState({ stage: 1 });

      let response = await invoiceRequests.emailInvoice(
        this.state.modalParameters?.providerId,
        this.state.modalParameters?.customerId,
        this.state.modalParameters?.billId,
        this.props.errorHandler
      );
      console.log(`response in index.js: ${response}`);
      if (response) {
        this.setState({
          stage: 2,
          modalParameters: {
            ...this.state.modalParameters,
            stageSuccess:
              'Invoice Sent successfully to:\n' +
              response.contacts?.join(', \n'),
          },
        });
      } else {
        this.setState({ stage: 3 });
        console.log('error with email, got no response');
      }
    }
  };

  printRequestHandler(id) {
    window.history.pushState(
      id,
      'Invoice:' + id,
      '/billing/invoiceshow/' + id + '?print=true'
    );
  }

  adjustRequestHandler = (element) => {
    console.log('billId', element.bill_id);
    return this.setState({
      isSelected: true,
      singleBill: element,
    });
  };

  backButtonClick = async (refresh) => {
    let defaultState = {
      isSelected: false,
      singleBill: null,
    };
    if (refresh) {
      let searchParams = {};
      searchParams.fromDate = this.state.fromDate;
      searchParams.toDate = this.state.toDate;
      searchParams.timeRange = this.state.timeRange;
      searchParams.timeRangeStart = this.state.fromDate;
      searchParams.timeRangeEnd = this.state.toDate;
      searchParams.providerFilter = this.state.providerFilter;
      searchParams.invoiceFilter = this.state.invoiceFilter;
      searchParams.customerFilter = this.state.customerFilter;
      searchParams.currencyFilter = this.state.currencyFilter;
      searchParams.customerTypeFilter = this.state.customerTypeFilter;
      searchParams.statusFilter = this.state.statusFilter;
      let data = await invoiceRequests.searchInvoices(
        searchParams,
        this.props.errorHandler
      );
      data = this.sortByDate(data);
      defaultState.data = data;
      defaultState.pageCount = data.length;
    }
    return this.setState(defaultState, refresh ? this.formatData : null);
  };

  refreshRequestHandler = async (providerId, customerId, billId) => {
    if (!this.state.showStaged) {
      this.setState({
        stage: 0,
        showStaged: true,
        modalParameters: {
          billId,
          customerId,
          providerId,
          stageTitle: 'Refresh Invoice',
          stageConfirmation: 'Are you sure you want to refresh this invoice?',
          stagePending: 'Refreshing Invoice...',
          stageSuccess: 'Invoice refreshed successfully!',
          stageFailure: 'Invoice refresh failed!',
        },
        modalActionType: 'refreshInvoice',
      });
    } else if (this.state.stage === 0) {
      this.setState({ stage: 1 });

      let response = await invoiceRequests.refreshInvoice(
        this.state.modalParameters?.billId,
        this.state.modalParameters?.customerId,
        this.props.errorHandler
      );
      console.log(`response in index.js:`, response);
      if (response) {
        this.setState({
          stage: 2,
          redirectToInvoice: true,
          refreshedBill: response,
        });
      } else {
        this.setState({ stage: 3 });
        console.log('error with refresh, got no response');
      }
    }
  };

  /**
   * Changes the data on the front-end for the specific invoice
   * @param {Object} result The data being changed
   * @param {string} updatePaidDate Used as a boolean  */
  dataChange = async (result, updatePaidDate) => {
    if (!result) return;

    let data = this.state.data;
    data.forEach((element) => {
      if (element.invoice_number === result.invoice) {
        element.billing_status = result.status;
        if (updatePaidDate) {
          element.paid_date = result.paid_date;
        }
      }
    });
    this.setState(
      {
        data: data,
      },
      this.formatData
    );
  };

  /**
   * Sorts a list of bills by bill date
   * @param {Array} bills Array of bill objects
   * @returns
   */
  sortByDate(bills) {
    const statusMap = { N: 1, I: 1, Y: 2, C: 3, F: 2 };

    return bills.sort(function (a, b) {
      if (a.bill_date < b.bill_date) return 1;
      if (a.bill_date > b.bill_date) return -1;
      return statusMap[a.billing_flag] - statusMap[b.billing_flag];
    });
  }

  stvCallback = () => {
    this.setState({
      stv: false,
    });
  };

  /*
    Handles searching User Data between two dates
  */
  handleUserDataSearch = async (fromDate, toDate, timeRange) => {
    try {
      this.setState({ loading: true });
      let searchParams = {};
      searchParams.fromDate = fromDate;
      searchParams.toDate = toDate;
      searchParams.timeRange = timeRange;
      searchParams.timeRangeStart = fromDate;
      searchParams.timeRangeEnd = toDate;
      searchParams.providerFilter = this.state.providerFilter;
      searchParams.invoiceFilter = this.state.invoiceFilter;
      searchParams.customerFilter = this.state.customerFilter;
      searchParams.currencyFilter = this.state.currencyFilter;
      searchParams.customerTypeFilter = this.state.customerTypeFilter;
      searchParams.statusFilter = this.state.statusFilter;

      const filterCustomer = this.props.location?.state?.customerName;
      let stateFields = {};

      // The reason for pageLoaded is to make sure this only happens on the first search
      if (filterCustomer && !this.state.pageLoaded) {
        stateFields.customerFilter = filterCustomer;
        searchParams.customerFilter = filterCustomer;
      }

      const data = await invoiceRequests.searchInvoices(
        searchParams,
        this.props.errorHandler
      );

      const sortedData = this.sortByDate(data);
      console.log(sortedData);
      this.setState(
        {
          ...stateFields,
          loading: true,
          data: sortedData,
          pageCount: sortedData.length,
          fromDate: fromDate,
          toDate: toDate,
          timeRange: timeRange,
          pageLoaded: true,
          currentlySearching: {
            providerFilter: searchParams.providerFilter,
            invoiceFilter: searchParams.invoiceFilter,
            customerFilter: searchParams.customerFilter,
            statusFilter: searchParams.statusFilter,
            currencyFilter: searchParams.currencyFilter,
            customerTypeFilter: searchParams.customerTypeFilter,
          },
        },
        this.formatData
      );
    } catch (e) {
      console.error(e);
      return this.setState({
        error: e.message,
        loading: false,
      });
    }
  };

  /*
    Convenience function to handle the updating of a form element
  */
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    console.log('handleInputChange event name:', name);
    console.log('handleInputChange event value:', value);

    this.setState({
      [name]: value,
    });
  }

  clearFilters = () => {
    this.setState({
      providerFilter: null,
      invoiceFilter: null,
      customerFilter: null,
      statusFilter: null,
      currencyFilter: null,
      customerTypeFilter: null,
    });
    document.getElementById('filter-form').reset();
  };

  /*
  Redirect to customer detail page
   */
  selectCustomer = async (customerId) => {
    await this.props.updateActingCustomerDetails(customerId);
    this.props.navigate('/admin/customer/' + customerId);
  };

  /*
    Formats the data in a manner required for the Table
    to properly display the data
  */
  formatData = async () => {
    let { accessLevel, accessType } = this.props;

    try {
      if (this.state.data.length === 0) {
        this.setState({
          list: null,
          loading: false,
        });
      }
      {
        let data = await this.state.data.map((el) => {
          return (
            <ListItem
              id={el.provider_id}
              key={el.invoice_number} // was originally set to provider_id, but why? resulted in many warnings.
              provider={el.provider_name}
              billId={el.bill_id}
              invoiceId={el.invoice_number}
              customerId={el.customer_id}
              name={el.customer_name}
              invoiceDate={el.invoice_date}
              paidDate={el.paid_date}
              status={el.billing_status}
              currency={el.currency_symbol}
              dollar={el.plan_currency}
              customerType={
                el.customer_type || this.props.customer.customer_type
              }
              amount={el.amount}
              isGodAdmin={accessLevel === '3' && accessType === '3'}
              emailRequestHandler={() =>
                this.emailRequestHandler(
                  el.provider_id,
                  el.customer_id,
                  el.bill_id
                )
              }
              printRequestHandler={() =>
                this.printRequestHandler(el.invoice_number)
              }
              refreshRequestHandler={() =>
                this.refreshRequestHandler(
                  el.provider_id,
                  el.customer_id,
                  el.bill_id
                )
              }
              adjustRequestHandler={() => this.adjustRequestHandler(el)}
              errorHandler={this.props.errorHandler}
              processPendingInvoice={this.processPendingInvoice}
              transitionPendingToInvoiced={this.transitionPendingToInvoiced}
              transitionBillToFailed={this.transitionBillToFailed}
              cancelPendingTransaction={this.cancelPendingTransaction}
              dataChange={this.dataChange}
              selectCustomer={this.selectCustomer}
            />
          );
        });

        this.setState({
          list: data,
          loading: false,
        });
      }
    } catch (e) {
      console.log('data error:', e);
    }
  };

  render() {
    const { accessLevel, accessType, customer } = this.props;
    const {
      isSelected,
      singleBill,
      showStaged,
      stage,
      modalParameters,
      refreshPage,
      redirectToInvoice,
      loading,
      currentlySearching,
      statusFilter,
      customerTypeFilter,
      selectorKeyRefresh,
    } = this.state;

    // if normal User or sales agent, do not show anything.
    if (accessLevel <= '0' || accessType === '0') {
      return null;
    }

    const invoiceFilter = (
      <InvoiceSearchTools
        clearFilters={() => this.clearFilters()}
        handleInputChange={(e) => this.handleInputChange(e)}
        accessLevel={accessLevel}
        accessType={accessType}
        handleUserDataSearch={(fromDate, toDate, timeRange) =>
          this.handleUserDataSearch(fromDate, toDate, timeRange)
        }
        statusFilterDropdown={statusFilter}
        customerTypeFilterDropdown={customerTypeFilter}
        currentlySearching={currentlySearching}
        selectorKeyRefresh={selectorKeyRefresh}
      />
    );

    // Defaults to just loading for the render content.
    let renderContent = (
      <div className="Home">
        <div className="lander">
          <h3>{FormattedMessage.invoicesText}</h3>
          {invoiceFilter}
          <div className="list-container">{FormattedMessage.loading}</div>
        </div>
      </div>
    );

    // If in error state, just shows the word Error... TODO: Change this
    if (this.state.errorState) {
      renderContent = 'Error';
    } else if (isSelected) {
      renderContent = (
        <InvoiceAdjustment
          backButton={this.backButtonClick}
          bill={singleBill}
          errorHandler={this.props.errorHandler}
        ></InvoiceAdjustment>
      );
    }
    // Else if not loading
    else if (!loading) {
      // If not loading and this.state.data is an empty array, it means no results found.
      if (this.state.data.length === 0) {
        renderContent = (
          <div className="Home">
            <div className="lander">
              <h3>{FormattedMessage.invoicesText}</h3>
              {invoiceFilter}
              <div className="list-container">
                {FormattedMessage.noResultsFound}
              </div>
            </div>
          </div>
        );
      }
      // Else if not loading and there are results, displays them through list component
      else {
        renderContent = (
          <div className="Home">
            <div className="lander">
              <h3>{FormattedMessage.invoicesText}</h3>
              {invoiceFilter}
              <div className="list-container">
                <ListComponent
                  changePage={this.changePage}
                  list={this.state.list}
                  isGodAdmin={accessLevel === '3' && accessType === '3'}
                  handlePagination={this.handlePagination}
                  pageCount={this.state.pageCount}
                />
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <BillingTabs
        tab="invoices"
        accessLevel={accessLevel}
        accessType={accessType}
        customerType={customer?.customer_type}
      >
        {!isSelected && <div className="inboxWrapper"></div>}
        {renderContent}

        <StagedModal
          showStaged={showStaged}
          removeStaged={() => this.removeStaged(refreshPage, redirectToInvoice)}
          removeStagedSlow={() => this.setState({ stage: 0 })}
          stage={stage}
          performAction={this.performAction}
          stageTitle={modalParameters?.stageTitle}
          stageConfirmation={modalParameters?.stageConfirmation}
          stagePending={modalParameters?.stagePending}
          stageSuccess={modalParameters?.stageSuccess}
          stageFailure={
            modalParameters?.stageFailure +
            (modalParameters?.errorMsg ? ': ' + modalParameters.errorMsg : '')
          }
        />
      </BillingTabs>
    );
  }
}
