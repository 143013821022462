/*
  SingleCustomerView.js - Admin Customer Single View Main Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import {
  Form,
  Button,
  Modal,
  Col,
  Row,
  Table,
  FormControl,
  FormGroup,
  ListGroup,
  Collapse,
} from 'react-bootstrap';

// COMPONENTS
import CountryPicker from '../../../components/CountryPicker';
import { FilePicker } from 'react-file-picker';

// Manage Pool Number Form
import ManagePoolNumber from './ManagePoolNumber';
import ManageDedicatedNumbers from '../../../components/ManagementPages/ManageDedicatedNumbers';

// I18N
import formattedMessages from './FormattedMessages';

// STYLING
import '../Admin.css';

// NETWORKING
import AdminRequest from '../AdminRequest';
import AttachmentListComponent from './Attachments/ListComponent';
import AttachmentListItem from './Attachments/ListItem';
import CommentListComponent from './Comments/ListComponent';
import CommentListItem from './Comments/ListItem';

// NETWORKING
import CustomerRequest from './CustomerRequest';
const customerRequest = new CustomerRequest();
const adminRequest = new AdminRequest();
export default class CustomerEdit extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - customer: A single Customer object
        - backButtonClick: Function to handle Back Button click
        - togglePaymentMethod: Function to handle Add New Payment Method click
    */

    this.state = {
      isEditing: false,
      isSaving: false,
      isSavingComment: false,
      isSavingAttachment: false,
      campaigns: null,
      campaignType: 'Twilio',
      newCampaign: '',
      toggleCampaign: false,
      toggleNumbersView: false,
      edits: {},
      tariff_plans: [],
      class_types: [],
      sales_agents: null,
      comments: props.comments,
      editableComments: [],
      attachments: props.attachments,
      addNewCommentSelected: false,
      addNewAttachmentSelected: false,
      customerAttachmentToDelete: null,
      newComment: '',
      uploadType: 'O',
      errorText: null,
      error: null,
      poolNumbers: null,
      dedicatedNumbers: null,
      managePoolNumbers: false,
      manageDedicatedNumbers: false,
      selectedUser: null,
      file: null,
    };
  }

  async componentDidMount() {
    let { errorHandler, customer, updateActingCustomerDetails } = this.props;

    await updateActingCustomerDetails(customer.customer_id);

    const results = await customerRequest.getCustomerExtras(errorHandler);

    this.setState({
      tariff_plans: results.tariff_plans,
      class_types: results.class_types,
      sales_agents: results.sales_agents,
    });
    // Update the acting_customer_id cookie with the
    // selected Customer (in order to assume their identity).
  }

  /*
    Handles changes in the form
  */
  handleChange = async (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleCloseConfirmation = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  showConfirmationModal = (customerAttachmentId) => {
    this.setState({
      showConfirmation: true,
      customerAttachmentToDelete: customerAttachmentId,
    });
  };

  showConfirmationDialog = () => {
    let { customerAttachmentToDelete } = this.state;
    return (
      <Modal
        show={this.state.showConfirmation}
        onHide={this.handleCloseConfirmation}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deletion Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you would like to delete the attachments?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="maxHeight btn btn-primary"
            onClick={() =>
              this.deleteAttachmentRequest(customerAttachmentToDelete)
            }
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="maxHeight btn btn-primary"
            onClick={this.handleCloseConfirmation}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  fetchComments = async () => {
    let { customer, errorHandler } = this.props;
    let comments = await adminRequest.getCustomerComments(
      customer.customer_id,
      errorHandler
    );
    return comments;
  };

  fetchAttachments = async () => {
    let { customer, errorHandler } = this.props;
    let attachments = await adminRequest.getCustomerAttachments(
      customer.customer_id,
      errorHandler
    );
    return attachments;
  };

  /*
    Handles Edit Mode on/off
  */
  toggleEdit = () => {
    let edits = this.state.edits;
    delete edits.addCampaign;
    delete edits.removeCampaign;
    this.getCampaigns();
    return this.setState({
      edits: edits,
      isEditing: !this.state.isEditing,
      campaignType: 'Twilio',
      newCampaign: '',
    });
  };

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let { edits } = this.state;

    // Convert val if checkbox
    if (target.type === 'checkbox') {
      value = value === true ? 'Y' : 'N';
    }

    if (name === 'newCampaign') {
      if ((!value && value !== '') || value.trim().length > 10) return;
      return this.setState({ newCampaign: value.trim() });
    }

    if (name === 'campaignType') {
      return this.setState({ campaignType: value });
    }

    if (name === 'code_type') {
      edits.customer_type = value.charAt(0);
      edits.customer_class = value.charAt(1);
    } else if (name === 'formCountry') {
      edits.country_code_id = value;
    } else {
      edits[name] = value;
    }

    return this.setState({
      edits: edits,
    });
  };

  /**
   * Checks if the phone number matches the format(+123456789/123456789)
   *
   * @returns True if the userTn prefix matches the format
   */
  async validatePhoneNumber(number) {
    //check if matches zero or one plus sign followed by one or more numbers
    let regExPattern = /^\+?\d+$/;
    return regExPattern.test(number);
  }

  /*
    Handles Edit form submission
  */
  handleSubmit = async (e) => {
    e.preventDefault();
    let { edits } = this.state;
    console.log('edits: ', edits);

    try {
      let def_orig = edits.default_originator;
      let valid = await this.validatePhoneNumber(def_orig);
      if (
        def_orig &&
        (def_orig.length > 15 ||
          ((def_orig.charAt(0) !== '+' || !valid) && def_orig.length > 11))
      ) {
        return this.setState({
          hasError: true,
          error:
            "The Originator should not be longer than 11 characters (or 15 characters if its a phone number starting with a '+')",
          isSaving: false,
        });
      }

      this.setState(
        {
          isSaving: true,
        },
        async () => {
          let { edits } = this.state;
          let { customer, errorHandler } = this.props;

          await adminRequest.updateCustomer(
            customer.customer_id,
            edits,
            errorHandler
          );
          this.props.backButtonClick('refresh');
        }
      );
    } catch (e) {
      return this.setState({
        error: e.message,
        isSaving: false,
      });
    }
  };

  /*
    Generates an error message
  */
  generateError = () => {
    if (!this.state.error) return null;
    return <div className="text-danger">{this.state.error}</div>;
  };

  generateFormButton = () => {
    let { isSaving, isEditing } = this.state;
    let buttonText = isEditing
      ? formattedMessages.cancel
      : formattedMessages.edit;

    if (isSaving) return <p>Saving...</p>;
    return (
      <Fragment>
        <Button className="maxHeight" variant="primary" type="submit">
          {formattedMessages.save}
        </Button>
        <Button className="maxHeight" onClick={this.toggleEdit}>
          {buttonText}
        </Button>
      </Fragment>
    );
  };

  /*
    Generates the Class - Type Select control
  */
  generateClassTypeSelect = () => {
    let { class_types, edits } = this.state;
    let { customer } = this.props;
    let classTypeLists = [];

    for (let i = 0; i < class_types.length; i++) {
      classTypeLists.push(
        <option
          key={class_types[i].class_type_status_id}
          value={class_types[i].code_type + class_types[i].code_class}
        >
          {class_types[i].select_name}
        </option>
      );
    }

    let classTypeValue = edits.customer_class
      ? `${edits.customer_type}${edits.customer_class}`
      : `${customer.customer_type}${customer.customer_class}`;
    return (
      <Form.Control
        as="select"
        name="code_type"
        value={classTypeValue}
        onChange={this.handleFormChange}
      >
        {classTypeLists}
      </Form.Control>
    );
  };

  /*
    Generates the Tariff Plan Select control
  */
  generateTariffPlanSelect = () => {
    let { tariff_plans } = this.state;
    let { customer } = this.props;
    let tariffPlanLists = [];

    for (let i = 0; i < tariff_plans.length; i++) {
      tariffPlanLists.push(
        <option
          key={tariff_plans[i].tariff_plan_id}
          value={tariff_plans[i].tariff_plan_id}
        >
          {tariff_plans[i].select_name}
        </option>
      );
    }
    return (
      <Form.Control
        as="select"
        name="tariff_plan_id"
        defaultValue={customer ? customer.tariff_plan_id : null}
      >
        {tariffPlanLists}
      </Form.Control>
    );
  };

  /*
    Generates the Sales Agent Select control
  */
  generateSalesAgentSelect = () => {
    const { sales_agents, edits } = this.state;
    const { customer } = this.props;

    let salesAgentList = (
      <option hidden key={''} value={''}>
        {'Loading...'}
      </option>
    );

    if (sales_agents) {
      salesAgentList = [
        <option hidden key={''} value={''}>
          {'None'}
        </option>,
      ];

      for (let i = 0; i < sales_agents.length; i++) {
        salesAgentList.push(
          <option
            key={sales_agents[i].sales_agent_id}
            value={sales_agents[i].sales_agent_id}
          >
            {sales_agents[i].sales_agent_name}
          </option>
        );
      }
    }
    return (
      <Form.Control
        as="select"
        name="sales_agents_id"
        value={edits.sales_agents_id || customer?.sales_agents_id || ''}
      >
        {salesAgentList}
      </Form.Control>
    );
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="ProviderShow" className="lander">
        <p
          onClick={() => this.props.backButtonClick('list')}
          className="backLink"
        >
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backText}</span>
        </p>
        <Button
          className="maxHeight float-right"
          onClick={() =>
            this.props.navigate('/billing/invoices', {
              state: {
                customerName: this.props.customer?.customer_name,
              },
            })
          }
        >
          {formattedMessages.viewInvoices}
        </Button>
      </div>
    );
  };

  removeCampaign = (i, code) => {
    let { edits } = this.state;

    // Check if the campaign we are trying to remove had just been
    // added in which case we just don't try to add it.
    if (edits.addCampaign) {
      let search = edits.addCampaign.findIndex(
        (element) => element.campaign_code === code
      );
      if (search !== -1) {
        edits.addCampaign.splice(search, 1);
        return this.setState({
          edits: edits,
        });
      }
    }

    // Sets removeCampaign field to array if it doesn't exist yet.
    edits.removeCampaign ??= [];
    edits.removeCampaign.push(code);

    this.setState({
      edits: edits,
    });
  };

  addCampaign = () => {
    let { newCampaign, campaignType, edits, campaigns } = this.state;

    if (newCampaign === '') return;

    // Checks if already exists.
    if (
      (edits.addCampaign &&
        edits.addCampaign.findIndex(
          (element) => element.campaign_code === newCampaign
        ) !== -1) ||
      (campaigns &&
        campaigns.findIndex(
          (element) => element.campaign_code === newCampaign
        ) !== -1)
    )
      return;

    let newCampaignObj = {
      campaign_code: newCampaign,
      campaign_type: campaignType,
    };

    // Check if the campaign we are trying to add had already been
    // attempted to be removed in which case we just don't remove it.
    if (edits.removeCampaign) {
      let search = edits.removeCampaign.indexOf(newCampaign);
      if (search !== -1) {
        edits.removeCampaign.splice(search, 1);
        return this.setState({
          newCampaign: '',
          edits: edits,
        });
      }
    }

    // Sets addCampaign field to array if it doesn't exist yet.
    edits.addCampaign ??= [];
    edits.addCampaign.push(newCampaignObj);

    this.setState({
      newCampaign: '',
      edits: edits,
    });
  };

  generatePoolNumbersTable = () => {
    const htmlRows = [];
    let { poolNumbers, toggleNumbersView } = this.state;

    let poolNumbersContent = <div>{formattedMessages.loading}</div>;

    if (poolNumbers) {
      if (poolNumbers.length === 0)
        poolNumbersContent = (
          <div>
            No pool numbers found for this customer
            <br />
            <br />
          </div>
        );
      else {
        poolNumbers.forEach((item, i) => {
          if (!item.vendor && item.route_name) {
            const splitString = item.route_name.split(' ');
            item.vendor = splitString[0];
          }

          htmlRows.push(
            <tr key={i} style={{ textAlign: 'center' }}>
              <td className="medium textLeft">{i + 1}</td>
              <td
                className="medium textLeft blue"
                onClick={() =>
                  this.props.navigate('/admin/service-config', {
                    state: {
                      devicesRedirect: item.device_id,
                    },
                  })
                }
              >
                {item.device_id}
              </td>
              <td className="medium textLeft">{item.device_name}</td>
              <td className="medium textLeft">{item.device_status}</td>
              <td className="medium textLeft">{item.reply_tn}</td>
              <td className="medium textLeft">{item.messaging_service}</td>
              <td className="medium textLeft">{item.campaign_code}</td>
              <td className="medium textLeft">{item.vendor}</td>
              <td className="medium textLeft"></td>
            </tr>
          );
        });

        const thClass = 'tableTop medium column-right';
        poolNumbersContent = (
          <Table className="table-new">
            <thead>
              <tr>
                <th className={thClass} style={{ width: '3%' }}>
                  Row
                </th>
                <th className={thClass} style={{ width: '5%' }}>
                  Device ID
                </th>
                <th className={thClass} style={{ width: '31%' }}>
                  Device Name
                </th>
                <th className={thClass} style={{ width: '8%' }}>
                  Device Status
                </th>
                <th className={thClass} style={{ width: '10%' }}>
                  Reply TN
                </th>
                <th className={thClass} style={{ width: '20%' }}>
                  Messaging Service
                </th>
                <th className={thClass} style={{ width: '8%' }}>
                  Campaign ID
                </th>
                <th className={thClass} style={{ width: '7%' }}>
                  Gateway
                </th>
                <th className={thClass} style={{ width: '8%' }}>
                  Forward Calls to
                </th>
              </tr>
            </thead>
            <tbody>{htmlRows}</tbody>
          </Table>
        );
      }
    }

    return (
      <Collapse in={toggleNumbersView}>
        <div>
          <div className="flex-between">
            <h3>{formattedMessages.poolNumbers}</h3>
            <Button
              className="maxHeight"
              onClick={() => this.setState({ managePoolNumbers: true })}
            >
              {formattedMessages.managePoolNumbers}
            </Button>
          </div>
          <div>{poolNumbersContent}</div>
        </div>
      </Collapse>
    );
  };

  generateDedicatedNumbersTable = () => {
    const { dedicatedNumbers, toggleNumbersView } = this.state;
    const { customer } = this.props;

    let dedicatedNumbersContent = <div>{formattedMessages.loading}</div>;

    if (dedicatedNumbers) {
      dedicatedNumbersContent = [];
      if (dedicatedNumbers.length === 0)
        dedicatedNumbersContent = (
          <div>
            No dedicated numbers found for this customer
            <br />
            <br />
          </div>
        );
      else {
        dedicatedNumbers.forEach((user, i) => {
          if (!user.numbers) return;

          const htmlRows = [];

          user.numbers.forEach((device, j) => {
            if (!device.vendor && device.route_name) {
              const splitString = device.route_name.split(' ');
              device.vendor = splitString[0];
            }

            htmlRows.push(
              <tr key={j} style={{ textAlign: 'center' }}>
                <td className="medium textLeft">{j + 1}</td>
                <td
                  className="medium textLeft blue"
                  onClick={() =>
                    this.props.navigate('/admin/service-config', {
                      state: {
                        devicesRedirect: device.device_id,
                      },
                    })
                  }
                >
                  {device.device_id}
                </td>
                <td className="medium textLeft">{device.device_name}</td>
                <td className="medium textLeft">{device.device_status}</td>
                <td className="medium textLeft">{device.reply_tn}</td>
                <td className="medium textLeft">{device.messaging_service}</td>
                <td className="medium textLeft">{device.campaign_code}</td>
                <td className="medium textLeft">{device.vendor}</td>
                <td className="medium textLeft"></td>
              </tr>
            );
          });

          const thClass = 'tableTop medium column-right';

          dedicatedNumbersContent.push(
            <div>
              <div className="flex-between flex-end">
                <h5>{user.user_email}</h5>
                <Button
                  className="maxHeight"
                  onClick={() =>
                    this.setState({
                      manageDedicatedNumbers: true,
                      selectedUser: {
                        customer_user_id: user.customer_user_id,
                        customer_id: customer.customer_id,
                      },
                    })
                  }
                >
                  {formattedMessages.manageDedicatedNumbers}
                </Button>
              </div>

              <Table key={i} className="table-new">
                <thead>
                  <tr>
                    <th className={thClass} style={{ width: '3%' }}>
                      Row
                    </th>
                    <th className={thClass} style={{ width: '5%' }}>
                      Device ID
                    </th>
                    <th className={thClass} style={{ width: '31%' }}>
                      Device Name
                    </th>
                    <th className={thClass} style={{ width: '8%' }}>
                      Device Status
                    </th>
                    <th className={thClass} style={{ width: '10%' }}>
                      Reply TN
                    </th>
                    <th className={thClass} style={{ width: '20%' }}>
                      Messaging Service
                    </th>
                    <th className={thClass} style={{ width: '8%' }}>
                      Campaign ID
                    </th>
                    <th className={thClass} style={{ width: '7%' }}>
                      Gateway
                    </th>
                    <th className={thClass} style={{ width: '8%' }}>
                      Forward Calls to
                    </th>
                  </tr>
                </thead>
                <tbody>{htmlRows}</tbody>
              </Table>
            </div>
          );
        });
      }
    }

    return (
      <Collapse in={toggleNumbersView}>
        <div>
          <div className="flex-between">
            <h3>{formattedMessages.dedicatedNumbers}</h3>
          </div>
          <div>{dedicatedNumbersContent}</div>
        </div>
      </Collapse>
    );
  };

  generateCampaignEdit() {
    let { campaigns, edits } = this.state;
    let items = [];

    // If campaigns is null, it is still loading.
    if (!campaigns) items = formattedMessages.loading;
    // Else if no campaigns found.
    else if (campaigns.length === 0 && edits.addCampaign?.length === 0)
      items = formattedMessages.noCampaignFound;
    // Else, map each campaign to a ListGroup Item.
    else {
      let list = edits.addCampaign
        ? campaigns.concat(edits.addCampaign)
        : campaigns;
      list.map((campaign, i) => {
        if (
          (edits.removeCampaign &&
            !edits.removeCampaign.includes(campaign.campaign_code)) ||
          !edits.removeCampaign
        ) {
          items.push(
            <ListGroup.Item key={i} className="campaign-text-edit">
              <div className="padded">{campaign.campaign_code}</div>
              <div className="flex-div">
                <div className="italic-grey padded">
                  {campaign.campaign_type}
                </div>
                <div
                  role="button"
                  className="x-button padded"
                  onClick={() => this.removeCampaign(i, campaign.campaign_code)}
                >
                  {this.generateCloseIcon()}
                </div>
              </div>
            </ListGroup.Item>
          );
        }
      });
    }

    return (
      <Form.Group as={Row} controlId="contract_id">
        <Form.Label column sm={2} className="info-label">
          {'Campaigns:'}
        </Form.Label>
        <Col sm={8}>
          <div className="add-campaign">
            <div>
              <Form.Control
                as="select"
                name="campaignType"
                value={this.state.campaignType}
                className="campaign-type-select"
                onChange={this.handleFormChange}
              >
                <option key={'twilio'} value={'Twilio'}>
                  Twilio
                </option>
                <option key={'bandwidth'} value={'Bandwidth'}>
                  Bandwidth
                </option>
                <option key={'telnyx'} value={'Telnyx'}>
                  Telnyx
                </option>
              </Form.Control>
            </div>
            <Form.Control
              type="text"
              value={this.state.newCampaign}
              name="newCampaign"
              onChange={this.handleFormChange}
            />
            <div
              role="button"
              className="add-button"
              onClick={this.addCampaign}
            >
              <i className="material-icons middle">add</i>
            </div>
          </div>

          <ListGroup>{items}</ListGroup>
        </Col>
      </Form.Group>
    );
  }

  toggleChevronClick = async (field, fetchCallback) => {
    this.setState({ [field]: !this.state[field] });
    fetchCallback();
  };

  getCampaigns = async () => {
    if (!this.state.campaigns && this.props.customer) {
      let results = await customerRequest.getCustomerCampaignData(
        this.props.customer.customer_id,
        this.props.errorHandler
      );
      return this.setState({
        campaigns: results,
      });
    }
  };

  getAllNumbers = async () => {
    const { customer, errorHandler } = this.props;

    if (!this.state.poolNumbers && customer) {
      const { poolNumbers, dedicatedNumbers } =
        await customerRequest.getAllNumbers(customer.customer_id, errorHandler);

      console.log('pool numbers:', poolNumbers);
      console.log('dedicated numbers:', dedicatedNumbers);

      return this.setState({
        poolNumbers: poolNumbers,
        dedicatedNumbers: dedicatedNumbers,
      });
    }
  };

  /*
    Convenience function to generate Form Groups
  */
  generateFormGroup = (label, displayValue, controlId, onClick) => {
    // If a campaign form group, generate different component.
    if (label === 'Campaigns:') {
      let { campaigns, toggleCampaign } = this.state;
      let items = [];

      // If campaigns is null, it is still loading.
      if (!campaigns) items = formattedMessages.loading;
      // Else if no campaigns found.
      else if (campaigns.length === 0)
        items = formattedMessages.noCampaignFound;
      // Else, map each campaign to a ListGroup Item.
      else {
        campaigns.map((campaign, i) => {
          items.push(
            <ListGroup.Item key={i} className="flex-between">
              <div>{campaign.campaign_code}</div>
              <div className="italic-grey"> {campaign.campaign_type}</div>
            </ListGroup.Item>
          );
        });
      }

      return (
        <Form.Group as={Row} controlId={controlId} className="campaign-text">
          <Form.Label column sm={2} className="info-label">
            {label}
          </Form.Label>
          <div className="campaign-list">
            <i
              className="material-icons display-interactable"
              onClick={() =>
                this.toggleChevronClick('toggleCampaign', this.getCampaigns)
              }
            >
              {toggleCampaign ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
            </i>
            <Collapse in={toggleCampaign}>
              <Col sm={8}>
                <ListGroup>{items}</ListGroup>
              </Col>
            </Collapse>
          </div>
        </Form.Group>
      );
    }

    // Else not a campaign form group.
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <p onClick={onClick} className={onClick ? 'blue' : null}>
            {displayValue}
          </p>
        </Col>
      </Form.Group>
    );
  };

  /*
    Generates the Add Pool Number button within the Pool Numbers table.
  */
  generateAllNumbersSection = () => {
    return (
      <Fragment>
        <div>
          <h2>
            <i
              className="material-icons display-interactable"
              onClick={() =>
                this.toggleChevronClick('toggleNumbersView', this.getAllNumbers)
              }
            >
              {this.state.togglePoolNumber
                ? 'keyboard_arrow_down'
                : 'keyboard_arrow_right'}
            </i>
            {formattedMessages.manageAllNumbers}
          </h2>
        </div>
      </Fragment>
    );
  };

  generateCloseIcon = () => {
    return (
      <svg
        height="18"
        width="19"
        viewBox="0 0 20 20"
        aria-hidden="true"
        focusable="false"
        className="css-8mmkcg"
      >
        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
      </svg>
    );
  };

  generateCustomerAttachmentsTable = () => {
    let { attachments, isSavingAttachment } = this.state;
    let list = this.formatAttachmentData(attachments);
    if (isSavingAttachment)
      return (
        <div>
          {this.addNewAttachment()}
          <br />
          <p>Saving...</p>
        </div>
      );

    if (attachments.length === 0) {
      return (
        <div>
          {this.addNewAttachment()}
          <br />
          <span>{formattedMessages.noResultsFound}</span>
        </div>
      );
    } else {
      return (
        <div>
          {this.addNewAttachment()}
          <AttachmentListComponent list={list} />
        </div>
      );
    }
  };

  formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  openAttachment = async (customerId, attachmentId, attachmentName) => {
    await adminRequest.downloadCustomerAttachment(
      customerId,
      attachmentId,
      attachmentName,
      this.props.errorHandler
    );
  };

  /*
      Formats the data in a manner required for the Table
      to properly display the data
    */
  formatCommentData(comments) {
    return comments.map((item) => {
      if (item)
        // Check if first letter of name is in array
        return (
          <CommentListItem
            key={'key_' + item.customer_comments_id}
            commentId={item.customer_comments_id}
            customerId={item.customer_id}
            createdDate={item.created_at}
            contactName={item.customer_contact_name}
            comment={item.customer_comments}
            onClickEdit={this.getEditComment.bind(this)}
            onClickDelete={this.removeComment.bind(this)}
            formatDate={this.formatDate}
            editableComments={this.state.editableComments}
            updateComment={this.updateComment.bind(this)}
            item={item}
          />
        );
      return null;
    });
  }

  /*
    Formats the data in a manner required for the Table
    to properly display the data
  */
  formatAttachmentData(attachments) {
    return attachments.map((item) => {
      if (item)
        return (
          <AttachmentListItem
            key={'key_' + item.customer_attachment_id}
            customer_id={item.customer_id}
            customer_attachment_id={item.customer_attachment_id}
            created_at={item.created_at}
            customer_contact_name={item.customer_contact_name}
            attachment_name={item.attachment_name + item.attachment_file_type}
            status_flag={item.status_flag === 'A' ? 'Active' : 'Inactive'}
            updated_at={item.updated_at}
            onClickDelete={this.showConfirmationModal}
            onClickOpen={this.openAttachment}
            formatDate={this.formatDate}
          />
        );
      return null;
    });
  }

  selectAddNewButtons(target) {
    if (target === 'comment') {
      this.setState({
        addNewCommentSelected: true,
      });
    } else if (target === 'attachment') {
      this.setState({
        addNewAttachmentSelected: true,
      });
    }
  }

  deselectAddNewButtons(target) {
    if (target === 'comment') {
      this.setState({
        addNewCommentSelected: false,
      });
    } else if (target === 'attachment') {
      this.setState({
        addNewAttachmentSelected: false,
        file: null,
        errorText: null,
      });
    }
  }

  submitNewAttachment() {
    this.uploadAttachment();
  }

  uploadAttachment = async () => {
    try {
      this.setState({ isSavingAttachment: true });
      let type = this.state.uploadType;
      let file = this.state.file;

      if (!file || typeof file === 'undefined') {
        // Handle no file error
        return this.setState({
          errorText: 'Must select a valid file to upload',
          isSavingAttachment: false,
        });
      } else {
        this.deselectAddNewButtons('attachment');
        // Import Contacts request
        await adminRequest.addCustomerAttachments(
          this.props.customer.customer_id,
          type,
          file,
          this.props.errorHandler
        );

        let attachments = await this.fetchAttachments();
        this.setState({
          attachments: attachments,
          file: null,
        });
      }
      this.setState({ isSavingAttachment: false });
    } catch (e) {
      // Handle Upload Error
      this.setState({
        errorText: e.message,
        isSavingAttachment: false,
      });
    }
  };

  deleteAttachmentRequest = async (attachmentId) => {
    try {
      this.handleCloseConfirmation();
      // Delete Customer Attachment
      await adminRequest.deleteCustomerAttachment(
        this.props.customer.customer_id,
        attachmentId,
        this.props.errorHandler
      );
      let attachments = await this.fetchAttachments();

      return this.setState({
        attachments: attachments,
        errorText: null,
        showConfirmation: false,
      });
    } catch (e) {
      // Handle Delete Error
      return this.setState({
        errorText: e.message,
        showConfirmation: false,
      });
    }
  };

  addNewComment() {
    if (this.state.addNewCommentSelected) {
      return (
        <div>
          <h2>{formattedMessages.listOfComments} </h2>
          <form onSubmit={() => this.submitNewComment()}>
            <FormGroup controlId="newComment">
              <FormControl
                type="text"
                value={this.state.newComment}
                onChange={this.handleChange}
              />
            </FormGroup>
          </form>
          <button
            className="maxHeight btn btn-primary"
            onClick={() => this.submitNewComment()}
          >
            {formattedMessages.save}
          </button>
          <button
            className="maxHeight btn btn-primary"
            onClick={() => this.deselectAddNewButtons('comment')}
          >
            {formattedMessages.cancel}
          </button>
          <br />
        </div>
      );
    } else {
      return (
        <div>
          <h2>
            {formattedMessages.listOfComments}
            <button
              className="btn add-new-template float-right"
              onClick={() => this.selectAddNewButtons('comment')}
            >
              + {formattedMessages.addComment}
            </button>
          </h2>
        </div>
      );
    }
  }

  generateFileSelect = () => {
    return (
      <div className="marginTopMedium">
        <p>{formattedMessages.uploadType}</p>
        <FormGroup controlId="uploadType">
          <Form.Label className="info-label-radio">
            <FormControl
              type="radio"
              name="uploadType"
              value="T"
              onChange={this.handleChange}
            />
            {formattedMessages.tariffConfirmation}
          </Form.Label>
          <Form.Label className="info-label-radio">
            <FormControl
              type="radio"
              name="uploadType"
              value="P"
              onChange={this.handleChange}
            />
            {formattedMessages.primaryContactDetails}
          </Form.Label>
          <Form.Label className="info-label-radio">
            <FormControl
              type="radio"
              name="uploadType"
              value="M"
              onChange={this.handleChange}
            />
            {formattedMessages.meetingMinutes}
          </Form.Label>
          <Form.Label className="info-label-radio">
            <FormControl
              type="radio"
              name="uploadType"
              value="O"
              onChange={this.handleChange}
            />
            {formattedMessages.other}
          </Form.Label>
        </FormGroup>
        <p>{formattedMessages.selectTheFileToImport}</p>
        <FilePicker
          className="marginBottomMedium"
          onChange={(fileObject) => this.captureFile(fileObject)}
          onError={(errMsg) => this.setState({ errorText: errMsg })}
        >
          <button className="btn add-new-template">
            {formattedMessages.chooseFile}
          </button>
        </FilePicker>
        {this.state.file ? `Selected File: ${this.state.file.name}` : ''}
      </div>
    );
  };

  generateFileSelectError = () => {
    if (!this.state.errorText) return null;

    return <p className="text-danger error">{this.state.errorText}</p>;
  };

  captureFile = async (file) => {
    this.setState({
      file: file,
      errorText: '',
    });
  };

  addNewAttachment() {
    if (this.state.addNewAttachmentSelected) {
      return (
        <div>
          <h2>{formattedMessages.attachments}</h2>
          {this.generateFileSelect()}
          <br />
          <button
            className="maxHeight btn btn-primary"
            onClick={() => this.submitNewAttachment()}
          >
            {formattedMessages.save}
          </button>
          <button
            className="maxHeight btn btn-primary"
            onClick={() => this.deselectAddNewButtons('attachment')}
          >
            {formattedMessages.cancel}
          </button>
          <br />
          {this.generateFileSelectError()}
        </div>
      );
    } else {
      return (
        <div>
          <h2>
            {formattedMessages.attachments}
            <button
              className="btn add-new-template float-right"
              onClick={() => this.selectAddNewButtons('attachment')}
            >
              + {formattedMessages.addAttachment}
            </button>
          </h2>
        </div>
      );
    }
  }

  generateCustomerCommentsTable = () => {
    let { comments, isSavingComment } = this.state;
    let list = this.formatCommentData(comments);
    if (isSavingComment)
      return (
        <div>
          {this.addNewComment()}
          <br />
          <p>Saving...</p>
        </div>
      );

    if (comments.length === 0) {
      return (
        <div>
          {this.addNewComment()}
          <hr />
          <span>{formattedMessages.noResultsFound}</span>
        </div>
      );
    } else {
      return (
        <div>
          {this.addNewComment()}
          <CommentListComponent list={list} />
        </div>
      );
    }
  };

  updateComment(selectedItem, newComment) {
    this.editCommentRequest(selectedItem.customer_comments_id, newComment);
  }

  removeComment(selectedItem) {
    this.deleteComment(selectedItem.customer_comments_id);
  }

  submitNewComment() {
    this.deselectAddNewButtons('comment');
    this.uploadNewComment();
  }

  uploadNewComment = async () => {
    try {
      this.setState({ isSavingComment: true }, async () => {
        await adminRequest.addCustomerComments(
          this.props.customer.customer_id,
          this.state.newComment,
          this.props.errorHandler
        );
        let comments = await this.fetchComments();

        this.setState({
          comments: comments,
          newComment: '',
          errorText: null,
          isSavingComment: false,
        });
      });
    } catch (e) {
      // Handle Upload Error
      return this.setState({
        errorText: e,
        isSavingComment: false,
      });
    }
  };

  deleteComment = async (commentId) => {
    try {
      await adminRequest.deleteCustomerComments(
        this.props.customer.customer_id,
        commentId,
        this.props.errorHandler
      );
      let comments = await this.fetchComments();
      return this.setState({
        comments: comments,
        newComment: '',
        errorText: null,
      });
    } catch (e) {
      // Handle Upload Error
      return this.setState({
        errorText: e,
      });
    }
  };

  editCommentRequest = async (commentId, newComment) => {
    try {
      await adminRequest.editCustomerComments(
        this.props.customer.customer_id,
        commentId,
        newComment,
        this.props.errorHandler
      );
      let comments = await this.fetchComments();

      this.setState({
        comments: comments,
        newComment: '',
        errorText: null,
        editableComments: [],
      });

      return true;
    } catch (e) {
      // Handle Upload Error
      return this.setState({
        errorText: e,
      });
    }
  };

  getEditComment(targetComment) {
    let selectedComment = this.state.editableComments;
    if (selectedComment.includes(targetComment.customer_comments_id)) {
      //if selected comment exists in array remove it
      selectedComment.splice(
        selectedComment.indexOf(targetComment.customer_comments_id),
        1
      );
      this.setState({
        editableComments: selectedComment,
      });
    } else {
      // else add the comment to the array
      selectedComment.push(targetComment.customer_comments_id);
      this.setState({
        editableComments: selectedComment,
      });
    }
  }

  generateBody = () => {
    let { customer } = this.props;
    let { isEditing, edits } = this.state;
    let buttonText = isEditing
      ? formattedMessages.cancel
      : formattedMessages.edit;

    // Not editing and have Provider data?  Display it
    if (customer && !this.state.isEditing) {
      return (
        <Form>
          {this.generateFormGroup(
            "Customer's Name:",
            customer.customer_name,
            'customer_name'
          )}
          {this.generateFormGroup(
            'Account Id:',
            customer.customer_account_no || '',
            'customer_account_no'
          )}
          {this.generateFormGroup(
            'Customer No (ABN):',
            customer.customer_abn || '',
            'customer_abn'
          )}
          {this.generateFormGroup(
            'Customer Reference No:',
            customer.customer_ref_no || '',
            'customer_ref_no'
          )}
          {this.generateFormGroup(
            'Default Originator:',
            customer.default_originator || '',
            'default_originator'
          )}
          {this.generateFormGroup(
            'Type - Class:',
            `${customer.customer_type_name} - ${customer.customer_class_name}`,
            'type_class'
          )}
          {this.generateFormGroup(
            'Status:',
            customer.customer_status_name,
            'customer_status'
          )}
          {this.generateFormGroup(
            'Sales Agent:',
            customer.sales_agent_name || '',
            'sales_agent_id'
          )}
          {this.generateFormGroup(
            'Tariff Plan:',
            customer.tariff_plan_name || '',
            'tariff_plan_id',
            () => {
              this.props.navigate('/admin/tariffs', {
                state: {
                  tariffPlanId: customer.tariff_plan_id,
                  tariffSchemeId: customer.tariff_scheme_id,
                },
              });
            }
          )}
          {this.generateFormGroup(
            'Country:',
            customer.country_name || 'Unknown',
            'country_name'
          )}
          {this.generateFormGroup(
            'Campaigns:',
            customer.campaigns || [],
            'campaigns'
          )}
          {this.generateFormGroup(
            'Contract Form Id:',
            customer.contract_id || '',
            'contract_id'
          )}
          {this.generateFormGroup(
            'Prepaid Credits:',
            customer.sms_in_excess,
            'sms_in_excess'
          )}
          {this.generateFormGroup(
            'HTTP Reply Template:',
            customer.reply_template || 'None',
            'reply_template'
          )}
          {this.generateFormGroup(
            'HTTP Reply URL:',
            customer.reply_url || '',
            'reply_url'
          )}
          {this.generateFormGroup(
            'Last Billed:',
            customer.last_billed || '',
            'last_billed'
          )}
          {/* TODO - For below, get from auth_codes table on customer_id */}
          {/* {this.generateFormGroup('Authorisation Code:', customer.auth_number || '', 'auth_number')}*/}
          <Button className="btn add-new-template" onClick={this.toggleEdit}>
            {buttonText}
          </Button>
        </Form>
      );
    } else if (customer && this.state.isEditing) {
      return (
        <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
          <Form.Group as={Row} controlId="customer_name">
            <Form.Label column sm={2} className="info-label">
              * {"Customer's Name"}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                name="customer_name"
                type="text"
                defaultValue={customer.customer_name}
              />
            </Col>
          </Form.Group>
          {this.generateFormGroup(
            'Account Id:',
            customer.customer_account_no || '',
            'customer_account_no'
          )}
          {this.generateFormGroup(
            'Customer No (ABN):',
            customer.customer_abn || '',
            'customer_abn'
          )}
          <Form.Group as={Row} controlId="customer_ref_no">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.customerReferenceNo}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                name="customer_ref_no"
                type="text"
                defaultValue={customer.customer_ref_no}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="default_originator">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.defaultOriginator}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                defaultValue={customer.default_originator}
                name="default_originator"
              />
              <div className="text-danger">{formattedMessages.dangerText}</div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="type_class">
            <Form.Label column sm={2} className="info-label">
              Type - Class:
            </Form.Label>
            <Col sm={8}>{this.generateClassTypeSelect()}</Col>
          </Form.Group>
          <Form.Group as={Row} controlId="customer_status">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.status}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                name="customer_status"
                defaultValue={edits.customer_status || customer.customer_status}
              >
                <option value="A">{formattedMessages.active}</option>
                <option value="C">{formattedMessages.cancelled}</option>
                <option value="P">{formattedMessages.pendingCancelled}</option>
                <option value="S">{formattedMessages.suspended}</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="sales_agents_id">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.salesAgent}:
            </Form.Label>
            <Col sm={8}>{this.generateSalesAgentSelect()}</Col>
          </Form.Group>
          <Form.Group as={Row} controlId="tariff_plan_id">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.tariffPlan}:
            </Form.Label>
            <Col sm={8}>{this.generateTariffPlanSelect()}</Col>
          </Form.Group>
          <Form.Group as={Row} controlId="country_code_id">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.country}:
            </Form.Label>
            <Col sm={8}>
              <CountryPicker
                className="form-control"
                defaultValue={edits.country_code_id || customer.country_code_id}
                onChange={this.handleFormChange}
              ></CountryPicker>
            </Col>
          </Form.Group>
          {this.generateCampaignEdit()}
          <Form.Group as={Row} controlId="contract_id">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.contractFormId}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                defaultValue={customer.contract_id}
                name="contract_id"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formLang">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.languageOnWebPages}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control as="select" name="formLang">
                <option value="English">{formattedMessages.english}</option>
                <option value="Polish">{formattedMessages.polish}</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="active_creation">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.activeCreation}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                defaultChecked={customer.active_creation === 'Y'}
                name="active_creation"
              ></Form.Check>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="red_alert_domain_flag">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.redAlertEnabled}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                defaultChecked={customer.red_alert_domain_flag === 'Y'}
                name="red_alert_domain_flag"
              ></Form.Check>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="sales_tax_flag">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.gSTApplicable}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                defaultChecked={customer.sales_tax_flag === 'Y'}
                name="sales_tax_flag"
              ></Form.Check>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="oms_flag">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.oMSFlag}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                defaultChecked={customer.oms_flag === 'Y'}
                name="oms_flag"
              ></Form.Check>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="receipt_flag">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.receiptFlag}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                defaultChecked={customer.receipt_flag === 'Y'}
                name="receipt_flag"
              ></Form.Check>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="employee_trust_flag">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.employeeTrustFlag}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                defaultChecked={customer.employee_trust_flag === 'Y'}
                name="employee_trust_flag"
              ></Form.Check>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="message_priority">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.messagePriority}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                name="message_priority"
                defaultValue={customer.message_priority}
              >
                <option value="0">
                  {formattedMessages.low.props.defaultMessage.toString()}
                </option>
                <option value="1">
                  {formattedMessages.medium.props.defaultMessage.toString()}
                </option>
                <option value="2">
                  {formattedMessages.high.props.defaultMessage.toString()}
                </option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="sms_in_excess">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.prepaidCredits}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                defaultValue={customer.sms_in_excess}
                name="sms_in_excess"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="reply_template">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.hTTPReplyTemplate}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                name="reply_template"
                defaultValue={customer.reply_template || ''}
              >
                <option value="">{formattedMessages.none}</option>
                <option value="RO4HttpReply.txt">
                  {formattedMessages.rO4HttpReplyTxt}
                </option>
                <option value="RO4HttpReplyFormData.txt">
                  {formattedMessages.rO4HttpReplyFormDataTxt}
                </option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="reply_url">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.hTTPReplyURL}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                defaultValue={customer.reply_url}
                name="reply_url"
              />
            </Col>
          </Form.Group>
          {this.generateError()}
          {this.generateFormButton()}
        </Form>
      );
    }

    return <span> {formattedMessages.noData}</span>;
  };

  render() {
    console.log('edits:', this.state.edits);

    // Manage Pool Numbers
    if (this.state.managePoolNumbers) {
      return (
        <ManagePoolNumber
          callBack={() => {
            this.setState({ managePoolNumbers: false });
          }}
          customer={this.props.customer}
          errorHandler={this.props.errorHandler}
        />
      );
    }

    // Add User
    if (this.state.manageDedicatedNumbers) {
      return (
        <ManageDedicatedNumbers
          callBack={() => {
            this.setState({ manageDedicatedNumbers: false });
          }}
          user={this.state.selectedUser}
          errorHandler={this.props.errorHandler}
          page="customer"
        />
      );
    }

    return (
      <div className="Home provider">
        {this.generateLander()}
        <h2>{formattedMessages.accountInformation}</h2>
        {this.generateBody()}
        <br />
        <br />
        {this.generateAllNumbersSection()}
        {this.generatePoolNumbersTable()}
        {this.generateDedicatedNumbersTable()}
        <br />
        <br />
        {this.generateCustomerCommentsTable()}
        <br />
        <br />
        {this.generateCustomerAttachmentsTable()}
        {this.showConfirmationDialog()}
      </div>
    );
  }
}
