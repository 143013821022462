import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner, Table } from 'react-bootstrap';
import { DateTimePicker } from 'react-widgets';
import Paginator from '../../../../components/Paginator';

const Batches = (props) => {
  const {
    data,
    count,
    loading,
    filter,
    onBatchSelect,
    onFilterChange,
    onRefresh,
  } = props;

  const [onDate, setOnDate] = useState(filter.onDate || new Date());
  const [customer, setCustomer] = useState(filter.customer || '');
  const [user, setUser] = useState(filter.user || '');
  const [withErrors, setWithErrors] = useState(filter.withErrors || false);
  const [page, setPage] = useState(filter.page || 1);

  let timer;
  const filterUpdate = (_then) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      console.log('filterUpdate');
      _then();
    }, 350);
  }

  useEffect(() => {
    console.log('useEffect', onDate, customer, user, withErrors, page);
    onFilterChange({
      onDate,
      customer,
      user,
      withErrors,
      page,
    });
  }, [onDate, customer, user, withErrors, page]);

  return (
    <>
      <div className="lander flex">
        <h3>Message Log</h3>
        <div className="flex flexRight">
          {loading && (
            <div className="loading-spinner">
              <Spinner animation="border" variant="primary" size="sm"/>
              &nbsp;Loading...
            </div>
          )}
          <Button className="btn add-new-template" onClick={() => onRefresh()}>
            Refresh
          </Button>
        </div>
      </div>

      <div id="batches-filters">
        <DateTimePicker 
          type="date" 
          format="MMM DD, YYYY"
          time={false}
          placeholder="Since" 
          value={filter.onDate}
          onChange={(value) => { filterUpdate(() => setOnDate(new Date(value))); }} 
        />
        <Form.Control 
          type="text" 
          placeholder="Customer"
          onChange={(e) => { filterUpdate(() => setCustomer(e.target.value)); }} 
        />
        <Form.Control 
          type="text" 
          placeholder="User"
          onChange={(e) => { filterUpdate(() => setUser(e.target.value)); }} 
        />
        <div className="flex flexRight">
          <Form.Check 
            type="checkbox"
            onChange={(e) => { filterUpdate(() => { console.log(e.target, e.target.checked); setWithErrors(e.target.checked) }) }} 
          />
          <Form.Label>With&nbsp;Errors</Form.Label>
        </div>
      </div>

      <Table>
        <thead>
          <tr>
            <th>Last Updated</th>
            <th>Customer</th>
            <th>User</th>
            <th>Counts</th>
          </tr>
        </thead>
        <tbody>
          {!loading && data.length === 0 && (
            <tr>
              <td colSpan="5" className="text-center">No data found</td>
            </tr>
          )}
          {data.map((item, index) => ( 
            <tr key={index} onClick={() => onBatchSelect(item)} className="clickable">
              <td>{
                new Intl.DateTimeFormat('en-us', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric'
                }).format(new Date(item.last_updated))
              }</td>
              <td>{item.customer_name}</td>
              <td>{item.customer_user_name}</td>
              <td>
                <span className="count-success" title="Success">
                  { item.success_count }
                </span>
                &nbsp;-&nbsp;
                <span className="count-failed" title="Failed">
                  {item.failure_count}
                </span>
                &nbsp;/&nbsp;
                <span title="Total">
                  {item.message_count}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {count > 10 && (
        <Paginator
          itemCount={count}
          changeEvent={(page) => { filterUpdate(() => setPage(page + 1)); }}
        />
      )}
    </>
  );
};

export default Batches;