import React, { useState, useEffect } from "react";
import AdminRequest from "../../AdminRequest";
import { Spinner, Table } from "react-bootstrap";

const adminRequest = new AdminRequest();

function Details({ item, errorHandler }) {
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    console.log(item);
    refresh();
  }, [item]);

  const refresh = async () => {
    setLoading(true);
    const data = await adminRequest.getTraceEvents(item.sms_id, item.email_id, errorHandler);
    setEvents(data);
    setLoading(false);
  }

  const eventClass = (event) => {
    if (event.error) return 'error';
    if (event.log_message.toLowerCase().includes('failed')) return 'error';
    if (event.log_message.toLowerCase().includes('success')) return 'success';
    if (event.log_message.toLowerCase().includes('error')) return 'error';
    return '';
  }

  return (
    <>
      <div>
        <p>
          <strong>Sent By:</strong> {item.customer_user_name} of {item.customer_name}
          {item.ip_address && <><br /><strong>From:</strong> {item.ip_address}</>}
          <br />
          <strong>Last Updated:</strong> {new Intl.DateTimeFormat('en-us', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
          }).format(new Date(item.last_updated))}
        </p>
      </div>

      <hr />

      <p>
        <span onClick={() => setShowMessage(!showMessage)} className="clickable">
          <strong>To:</strong> {item.destination}
        </span>
        {showMessage && <><br />
          <strong>Message:</strong> {item.message}
        </>}
      </p>

      <Table size="sm">
        <thead>
          <tr>
            <th>Date</th>
            <th>Tag</th>
            <th>Log Message</th>
          </tr>
        </thead>
        <tbody>
          {loading && <tr><td colSpan="2">
            <div className="loading-spinner">
              <Spinner animation="border" variant="primary" size="sm"/>
              &nbsp;Loading...
            </div>
          </td></tr>}
        {
          events.map((event, index) => (
            <tr key={index} className={eventClass(event)}>
              <td>{new Intl.DateTimeFormat('en-us', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
              }).format(new Date(event.created_at))}</td>
              <td>{event.tag}</td>
              <td>{event.log_message}</td>
            </tr>
          ))
        }
        </tbody>
      </Table>
    </>
  );
}

export default Details;
