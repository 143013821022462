/*
  DateRangeSelector.js - General Component for searching table data via Select component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Button, Form } from 'react-bootstrap';
import moment from 'moment';
// import { FormattedMessage } from 'react-intl';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';

// STYLING
import './TableSearcher.css';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { DateTime, Settings } from 'luxon';

export default class DateRangeSelector extends Component {
  constructor(props) {
    super(props);
    /*
    Props:
      - rangeOnly: (optional) A boolean that is passed, determining the type of DateRangeSelector
      - handleDateSearch: (required if rangeOnly is falsy) A function that is passed { startDate, endDate } upon
      the Search Button being clicked
      - rangeFunction: (required if rangeOnly is truthy) A function that is passed that simply sets the range of the 
      dates in the parent component.
      - handleButtonSearch: A clone of handleDateSearch for use in manual resets
    */

    moment.locale('en-us');
    momentLocalizer();

    // Retrieve range start and end from local storage.
    const start = localStorage.getItem('timeRangeStart');
    const end = localStorage.getItem('timeRangeEnd');

    // Local storage stores null as a string.
    if (start !== 'null') this.startDate = DateTime.fromJSDate(new Date(start));
    else this.startDate = null;

    if (end !== 'null') this.endDate = DateTime.fromJSDate(new Date(end));
    else this.endDate = null;

    this.timezoneString = `Fetching User's Timezone...`;

    this.state = {
      focusedInput: null,
      searchValue: localStorage.getItem('timeRange') || 7,
      displayStart: this.startDate?.toJSDate(),
      displayEnd: this.endDate?.toJSDate(),
    };
  }

  async componentDidMount() {
    if (!this.props.rangeOnly) this.initialiseDates();
  }

  /**
   * Used to do initial setup for the component, including setting up the component to search for 7 days initially.
   */
  initialiseDates = () => {
    // 'system' or 'local' sets the timezone to the browser's timezone.
    Settings.defaultZone = 'system';

    // Finds the system/browser's timezone offset.
    this.systemZoneOffset = new Date().getTimezoneOffset() * -1;
    this.timezoneString = `(User's Timezone is ${localStorage.getItem(
      'timeString'
    )})`;
    this.setDates(this.state.searchValue);
    this.searchBetweenDates();
  };

  /**
   * Handles a selection change on the <select> element
   * @param {Object} event from the <select> element
   */
  handleSelect = (event) => {
    const target = event.target;
    const value = target.value;
    this.setDates(value);
  };

  /**
   * Sets the date ranges when the component mounts and whenever the dropdown is used.
   * @param {String | Number} value used to subtract the start date from the end date unless its 24 hrs in which case the entire date calculation is done differently.
   */
  setDates = (value) => {
    // Sets the timeRange in local storage
    localStorage.setItem('timeRange', value);

    // Sets luxon's timezone to the customer's (from the backend and stored in localstorage).
    Settings.defaultZone = localStorage.getItem('timeShort');

    // Because the DateTimePicker displays using the system's timezone, we need to subtract (for the display dates alone) the difference between the customer's
    // timezone and their system timezone. Most of the time, they should be the same so people would rarely notice the difference.
    const displayOffset = this.systemZoneOffset - Settings.defaultZone.fixed;

    let current = DateTime.now();
    if (value === '24 hrs') {
      this.endDate = current;
      this.startDate = this.endDate.minus({ hours: 24 });
    } else if (value === 'custom') {
      // Else sets default values in the case of null custom dates.

      if (this.endDate) {
        this.endDate = this.endDate.plus({ minutes: displayOffset });
      } else this.endDate = current.endOf('day');

      if (this.startDate) {
        this.startDate = this.startDate.plus({ minutes: displayOffset });
      } else this.startDate = current.startOf('day').minus({ days: 6 });
    } else {
      this.endDate = current.endOf('day');
      this.startDate = current.startOf('day').minus({ days: value - 1 });
    }

    let displayStart = this.startDate
      .minus({ minutes: displayOffset })
      .toJSDate();
    let displayEnd = this.endDate.minus({ minutes: displayOffset }).toJSDate();

    this.setState({
      displayStart: displayStart,
      displayEnd: displayEnd,
      searchValue: value,
    });
  };

  /**
   * Gets the selected dates and passes to the parent component's props function for searching using the selected dates.
   */
  searchBetweenDates = async () => {
    if (!this.startDate || !this.endDate) return;

    // Calls the passed in function (in props) for handling search with the selected dates.
    console.log(
      'Component searching between (in UTC)',
      this.startDate.toJSDate().toUTCString(),
      'and',
      this.endDate.toJSDate().toUTCString()
    );
    await this.props.handleDateSearch(
      this.startDate.valueOf(),
      this.endDate.valueOf(),
      this.state.searchValue
    );
  };

  /**
   * A clone of the function above so that parents can choose 1 of 2 methods of refreshing list data
   */
  searchWithButton = async () => {
    if (!this.startDate || !this.endDate) return;
    console.log('searchWithButton');

    await this.props.handleButtonSearch(
      this.startDate.valueOf(),
      this.endDate.valueOf(),
      this.state.searchValue
    );
  };

  /**
   * Function called for custom date edits (through the calendar)
   * @param {Date} value date in system timezone.
   * @param {Boolean} isStartDate boolean for whether this function is affecting the start date or the end date.
   */
  onChangeDate(value, isStartDate) {
    // Handles resetting the minutes after using the 24 hour search.
    if (value.getMinutes() !== 0 && value.getMinutes() !== 59) {
      if (isStartDate) value.setHours(0, 0, 0, 0);
      else value.setHours(23, 59, 59, 999);
    }

    // Because the DateTimePicker's input comes in using the system time, in this case we add the difference to
    // get the customer's timezone time.
    Settings.defaultZone = localStorage.getItem('timeShort');
    let displayOffset = this.systemZoneOffset - Settings.defaultZone.fixed;
    let displayDate = DateTime.fromJSDate(value);
    let updateState = { searchValue: 'custom' };
    // Sets the timeRange in local storage
    localStorage.setItem('timeRange', 'custom');

    if (isStartDate) {
      this.startDate = displayDate.plus({ minutes: displayOffset });
      updateState.displayStart = displayDate.toJSDate();
      localStorage.setItem('timeRangeStart', displayDate.toJSDate());
    } else {
      this.endDate = displayDate.plus({ minutes: displayOffset });
      updateState.displayEnd = displayDate.toJSDate();
      localStorage.setItem('timeRangeEnd', displayDate.toJSDate());
    }

    this.setState(updateState);
  }

  /**
   * An alternative to onChangeDate that only triggers when the props rangeOnly and rangeFunction is truthy,
   * essentially meaning that the DateRangeSelector is required to perform easier logic.
   * @param {Date} value date in system timezone.
   * @param {Boolean} isStartDate boolean for whether this function is affecting the start date or the end date.
   */
  onChangeSimple(value, isStartDate) {
    let displayDate = DateTime.fromJSDate(value);
    let updateState = { searchValue: 'custom' };
    if (isStartDate) updateState.displayStart = displayDate.toJSDate();
    else updateState.displayEnd = displayDate.toJSDate();

    this.setState(updateState, () => {
      this.props.rangeFunction(this.state.displayStart, this.state.displayEnd);
    });
  }

  render() {
    let { displayStart, displayEnd, searchValue } = this.state;

    // Simpler DateRangeSelector component (currently just used in billing invoices filter and system monitor search)
    if (this.props.rangeOnly && this.props.rangeFunction) {
      return (
        <Fragment>
          <div className="searchRange">
            <DateTimePicker
              name="startDateTimePicker"
              format="DD/MMM/YYYY"
              initialValue={displayStart}
              value={displayStart}
              onChange={(value) => this.onChangeSimple(value, true)}
              disabled={false}
              time={false}
            />
            <DateTimePicker
              name="endDateTimePicker"
              format="DD/MMM/YYYY"
              initialValue={displayEnd}
              value={displayEnd}
              onChange={(value) => this.onChangeSimple(value, false)}
              disabled={false}
              time={false}
            />
          </div>
        </Fragment>
      );
    }

    // Regular DateRangeSelector which has to do timezone offsetting, display the ranges correctly to the minute etc.
    return (
      <Fragment>
        <span className="quick-report searchRange">
          {formattedMessages.quickReport}
        </span>
        <div className="searchRange">
          <Form.Control
            onChange={this.handleSelect}
            as="select"
            value={searchValue}
            className="dateInput searchRange no-position"
            ref={this.myRef}
          >
            {formattedMessages.todayText}
            {formattedMessages.hoursText}
            {formattedMessages.yesterdayText}
            {formattedMessages.weekText}
            {formattedMessages.fortnightText}
            {formattedMessages.monthText}
            {formattedMessages.yearText}
            {formattedMessages.customText}
          </Form.Control>
        </div>
        <span className="searchRange">{formattedMessages.activityBetween}</span>
        <span className="searchRange">{this.timezoneString}</span>
        <div className="searchRange">
          <DateTimePicker
            name="startDateTimePicker"
            format="DD/MMM/YYYY h:mm A"
            initialValue={displayStart}
            value={displayStart}
            onChange={(value) => this.onChangeDate(value, true)}
            disabled={false}
            time={false}
          />
          <DateTimePicker
            name="endDateTimePicker"
            format="DD/MMM/YYYY h:mm A"
            initialValue={displayEnd}
            value={displayEnd}
            onChange={(value) => this.onChangeDate(value, false)}
            disabled={false}
            time={false}
          />
          <Button
            className="searchRange"
            onClick={
              typeof this.props.handleButtonSearch === 'undefined'
                ? this.searchBetweenDates
                : this.searchWithButton
            }
          >
            {formattedMessages.searchButton}
          </Button>
        </div>
      </Fragment>
    );
  }
}
