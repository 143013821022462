/*
  Sent.js - Component to handle Sent SMS content

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';

// COMPONENTS
import ListComponent from './List';
import WebSMSTabs from '../index';
import Single from './Single';

import Paginator from '../../../components/Paginator';
import DateRangeSelector from '../../../components/DateRangeSelector';

// CONFIG
import config from '../../../Config';

// I18N
import formattedMessages from '../FormattedMessages';

// NETWORK
import SentRequest from './SentRequest';
const sentRequest = new SentRequest();

export default class Sent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: [],
      paginatedSent: [],
      selected: null,
      error: null,
      searchParams: null,
      fromDate: new Date(),
      toDate: new Date(),
      fromFilter: null,
      toFilter: null,
      timeRange: null,
      nameFilter: null,
      messageFilter: null,
      statusFilter: null,
      type: 'TXT',
      isSearching: false,
      pageNumber: 1,
      pageLength: config.defaultPL,
      rowCount: 0,
      currentlySearching: {},
      open: false,
      selectorKeyRefresh: false,
      cancelList: new Set(),
    };
  }

  /*
    Back button
  */
  backButtonClick = () => {
    return this.setState({
      selected: null,
      cancelList: new Set(),
    });
  };

  handleManualDataSearch = async (fromDate, toDate, timeRange) => {
    await this.setState({ pageNumber: 1 });
    this.handleUserDataSearch(fromDate, toDate, timeRange);
  };

  /*
    Handles searching User Data between two dates
  */
  handleUserDataSearch = async (fromDate, toDate, timeRange) => {
    try {
      this.setState({ isSearching: true, sent: [] });
      let searchParams = {};
      searchParams.fromDate = fromDate;
      searchParams.toDate = toDate;
      searchParams.timeRangeStart = fromDate;
      searchParams.timeRangeEnd = toDate;
      searchParams.fromFilter = this.state.fromFilter;
      searchParams.toFilter = this.state.toFilter;
      searchParams.nameFilter = this.state.nameFilter;
      searchParams.messageFilter = this.state.messageFilter;
      searchParams.statusFilter = this.state.statusFilter;
      searchParams.type = 'TXT';
      searchParams.timeRange = timeRange;
      searchParams.pageNumber = this.state.pageNumber;
      searchParams.pageLength = this.state.pageLength;

      let sent = await sentRequest.searchSentItems(
        searchParams,
        this.props.errorHandler
      );
      this.setState(
        {
          sent: sent.items,
          fromDate: searchParams.fromDate,
          toDate: searchParams.toDate,
          timeRange: timeRange,
          currentlySearching: searchParams,
          type: 'TXT',
          isSearching: false,
          cancelList: new Set(),
          rowCount: sent.rowCount,
        },
        this.setPaginatedResults
      );
    } catch (e) {
      console.error(e);
      return this.setState({
        error: e.message,
        isSearching: false,
        cancelList: new Set(),
      });
    }
  };

  /*
    Handles the selection and display of a single Sent SMS
  */
  showSingleSentSms = (sms) => {
    return this.setState({
      selected: sms,
    });
  };

  /*
    Generates the Lander for the Sent SMS Container
  */
  generateLander = () => {
    if (this.state.selected) return null;

    let message = 'Cancel All Pending Messages';
    if (this.state.cancelList?.size > 0)
      message = 'Cancel Selected Pending Messages';
    return (
      <div className="lander">
        <Button
          className="maxHeight float-right"
          variant="primary"
          type="submit"
          onClick={this.showConfirmationModal}
        >
          {message}
        </Button>
        <h3>{formattedMessages.sentTitle}</h3>
      </div>
    );
  };

  /*
    Handles the click for "Cancel All Pending Messages" button.
  */
  handleCancelAllPendingMessages = () => {
    this.setState(
      {
        isSaving: true,
        showConfirmation: false,
      },
      async () => {
        await sentRequest.cancelAllPendingMessages(
          Array.from(this.state.cancelList),
          this.props.errorHandler
        );

        // Refreshes search
        this.setState({
          selectorKeyRefresh: !this.state.selectorKeyRefresh,
          cancelList: new Set(),
        });
      }
    );
  };

  handleCloseConfirmation = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  showConfirmationModal = () => {
    this.setState({
      showConfirmation: true,
    });
  };

  showConfirmationDialog = () => {
    const { cancelList } = this.state;

    let modalMessage =
      'Are you sure you would like to cancel all of your pending messages?';
    if (cancelList && cancelList.size > 0)
      modalMessage =
        'Are you sure you would like to cancel ' +
        cancelList.size +
        ' message(s)';
    return (
      <Modal
        show={this.state.showConfirmation}
        onHide={this.handleCloseConfirmation}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancellation Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="maxHeight btn btn-primary"
            onClick={this.handleCancelAllPendingMessages}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="maxHeight btn btn-primary"
            onClick={this.handleCloseConfirmation}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  handleCancelList = (smsId, remove) => {
    const cancelList = new Set(this.state.cancelList);

    if (remove) cancelList.delete(smsId);
    else cancelList.add(smsId);

    this.setState({
      cancelList: cancelList,
    });
  };

  statusDropdown = () => {
    let list = [];
    let sendStatus = [
      'Blacklisted',
      'Cancelled',
      'Delivered',
      'Failed Delivery',
      'Invalid Number',
      'Pending',
      'Presumed Delivered',
      'Processing',
      'Too Many Retries',
    ];

    if (!this.state.statusFilter)
      list.push(
        <option disabled selected hidden key={null} value>
          Select Status
        </option>
      );
    for (let i = 0; i < sendStatus.length; i++) {
      list.push(
        <option key={sendStatus[i]} value={sendStatus[i]}>
          {sendStatus[i]}
        </option>
      );
    }
    return (
      <Form>
        <Form.Control
          as="select"
          name="statusFilter"
          className="filter-control"
          onChange={(e) => this.handleInputChange(e)}
        >
          {list}
        </Form.Control>
      </Form>
    );
  };

  toggleMenu = () => this.setState(({ open }) => ({ open: !open }));

  /* Generates the Search Tools for querying data */
  generateSearchTools = () => {
    // Have Sent SMS selected, hide search
    if (this.state.selected) return null;

    const { open, currentlySearching, selectorKeyRefresh } = this.state;

    // Destructures which filters the table is currently displaying fields on.
    let { fromFilter, toFilter, nameFilter, messageFilter, statusFilter } =
      currentlySearching || {};

    let filterText = [];
    let values = ['From:', 'To:', 'Name:', 'Message:', 'Status:'];

    // Pushes spans to an array for rendering. Iterates to avoid code duplication.
    [fromFilter, toFilter, nameFilter, messageFilter, statusFilter].map(
      (filter, i) => {
        if (filter)
          filterText.push(
            <span>
              {filterText.length === 0 ? '' : '; '} <b>{values[i]}</b> {filter}
            </span>
          );
      }
    );
    return (
      <Fragment>
        <DateRangeSelector
          key={selectorKeyRefresh ? 1 : 0} // Changing the key remounts it
          handleDateSearch={(fromDate, toDate, timeRange) =>
            this.handleUserDataSearch(fromDate, toDate, timeRange, true)
          }
          handleButtonSearch={(fromDate, toDate, timeRange) =>
            this.handleManualDataSearch(fromDate, toDate, timeRange)
          }
        ></DateRangeSelector>

        <div className="Home">
          <div className="lander">
            <br />
            <span className="showHideLink-left">
              <span className="advanced-filters" onClick={this.toggleMenu}>
                {open ? (
                  <i className="material-icons">keyboard_arrow_down</i>
                ) : (
                  <i className="material-icons">keyboard_arrow_right</i>
                )}
                Advanced Filters
              </span>

              <div className="clear-filters" onClick={this.clearFilters}>
                {formattedMessages.clear}
              </div>
            </span>

            {open && (
              <div>
                <Form id="filter-form">
                  <table className="no-table-row-background">
                    <tbody className="filter-table">
                      <div className="filter">
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              From:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="fromFilter"
                                id="fromFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Number"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              To:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="toFilter"
                                id="toFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Number"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              Name:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="nameFilter"
                                id="nameFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Name"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              Message:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="messageFilter"
                                id="messageFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Message"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              Status:
                            </Form.Label>
                          </td>
                          <td>
                            <div>{this.statusDropdown()}</div>
                          </td>
                        </div>
                      </div>
                    </tbody>
                  </table>
                </Form>

                <br></br>
              </div>
            )}
            <div className="flex-between">
              {filterText.length > 0 && <div>{filterText}</div>}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  /* Changes the amount of entries per pagination page. */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      let { fromDate, toDate, timeRange } = this.state;
      this.setState({ pageLength: value }, () =>
        this.handleUserDataSearch(fromDate, toDate, timeRange)
      );
    }
  };

  /* redos call to API backend to get refreshed results */
  reloadPage = () => {
    if (this.props.customer && this.props.customer !== null) {
      let { fromDate, toDate, timeRange } = this.state;
      this.handleUserDataSearch(fromDate, toDate, timeRange);
    }
  };

  setPaginatedResults = () => {
    let { sent } = this.state;
    this.setState(
      {
        paginatedSent: sent,
      },
      () => this.changeFunction(this.state.pageNumber - 1)
    );
  };

  changeFunction(page) {
    this.setState({
      activePage: page,
      pageNumber: page,
    });
  }

  generateBody = () => {
    if (this.state.selected) {
      return (
        <Single
          sms={this.state.selected}
          backButtonClick={this.backButtonClick}
          errorHandler={this.props.errorHandler}
        />
      );
    } else if (this.state.isSearching) {
      return <span>Searching...</span>;
    } else if (
      this.state.sent.length === 0 ||
      this.state.paginatedSent.length === 0
    ) {
      return <span>No results found.</span>;
    }

    // display list of results.
    let { paginatedSent, pageLength, pageNumber } = this.state;

    return (
      <div>
        <ListComponent
          dateFormats={this.props.dateFormats}
          list={paginatedSent}
          showSingleSentSms={this.showSingleSentSms}
          handleCancelList={this.handleCancelList}
          cancelList={this.state.cancelList}
        />
        <div>
          <Paginator
            itemCount={this.state.rowCount}
            changeEvent={this.changeEvent}
            pageLength={pageLength}
            pageNumber={pageNumber}
            editFunction={this.changePageLength}
            showTotal={'Total Sent Messages Found'}
            changePageFunction={(ref) => (this.changeFunction = ref)}
          />
        </div>
      </div>
    );
  };

  /**
   * Handles the change event when clicking a pagination number
   */
  changeEvent = async (i) => {
    let { fromDate, toDate, timeRange } = this.state;
    this.setState(
      {
        pageNumber: i + 1,
      },
      () => this.handleUserDataSearch(fromDate, toDate, timeRange)
    );
  };

  /*
     Resets the page's info when the tab is clicked.
   */
  handlePageReset = () => {
    this.setState({
      selected: null,
      sent: [],
      selectorKeyRefresh: !this.state.selectorKeyRefresh,
    });
  };

  /*
    Convenience function to handle the updating of a form element
  */
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  }

  clearFilters = () => {
    this.setState({
      fromFilter: null,
      toFilter: null,
      nameFilter: null,
      messageFilter: null,
      statusFilter: null,
    });
    document.getElementById('filter-form').reset();
  };

  render() {
    return (
      <WebSMSTabs
        tab="sent"
        mobileMenuOpen={this.props.mobileMenuOpen}
        navigate={this.props.navigate}
        toggleMobileMenu={this.props.toggleMobileMenu}
        handlePageReset={this.handlePageReset}
      >
        <div className="Home">
          {this.generateLander()}
          <div className="inboxWrapper">
            {this.generateSearchTools()}
            {this.showConfirmationDialog()}
            {this.generateBody()}
          </div>
        </div>
      </WebSMSTabs>
    );
  }
}
